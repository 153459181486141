// src/auth.js

// Function to verify the token with the API
export const verifyToken = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Auth/accessTokenVerification`, {
            method: 'POST',
            headers: {
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });

        const data = await response.json();
        return data.valid; // Assuming the API returns a boolean 'valid'
    } catch (error) {
        console.error('Error verifying token:', error);
        return false;
    }
};

// Function to check if user has a token stored (basic check, doesn't verify token validity)
export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token; // Returns true if a token exists, false otherwise
};

// Function to get the stored token from localStorage
export const getToken = () => {
    return localStorage.getItem('token');
};

// Function to log the user out by clearing the token
export const logout = () => {
    localStorage.removeItem('token');
};

// Function to register a new WebAuthn credential (Windows Hello)
export const registerWebAuthn = async (email) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Auth/webauthn/registerChallenge`, {
            method: 'POST',
            headers: {
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        const challengeData = await response.json();
        if (!challengeData.challenge) {
            throw new Error("Invalid challenge response from server.");
        }

        const publicKeyCredential = {
            challenge: new Uint8Array(challengeData.challenge),
            rp: { name: "Your Website" },
            user: {
                id: new Uint8Array(challengeData.userId),
                name: email,
                displayName: email,
            },
            pubKeyCredParams: [{ type: "public-key", alg: -7 }],
            authenticatorSelection: { authenticatorAttachment: "platform" }, // Ensures Windows Hello is used
            timeout: 60000,
            attestation: "direct"
        };

        const credential = await navigator.credentials.create({ publicKey: publicKeyCredential });

        const verificationResponse = await fetch(`${process.env.REACT_APP_API_URL}/Auth/webauthn/registerVerify`, {
            method: 'POST',
            headers: {
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                credential: credential,
            }),
        });

        const verificationData = await verificationResponse.json();
        return verificationData.success;
    } catch (error) {
        console.error("Error registering WebAuthn:", error);
        return false;
    }
};

// Function to authenticate user using WebAuthn (Windows Hello)
export const authenticateWebAuthn = async (email) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Auth/webauthn/authenticateChallenge`, {
            method: 'POST',
            headers: {
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        const challengeData = await response.json();
        if (!challengeData.challenge) {
            throw new Error("Invalid challenge response from server.");
        }

        const publicKeyCredential = {
            challenge: new Uint8Array(challengeData.challenge),
            allowCredentials: [{
                id: new Uint8Array(challengeData.credentialId),
                type: "public-key",
                transports: ["internal"]
            }],
            timeout: 60000
        };

        const assertion = await navigator.credentials.get({ publicKey: publicKeyCredential });

        const verificationResponse = await fetch(`${process.env.REACT_APP_API_URL}/Auth/webauthn/authenticateVerify`, {
            method: 'POST',
            headers: {
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                assertion: assertion,
            }),
        });

        const verificationData = await verificationResponse.json();
        if (verificationData.success && verificationData.token) {
            localStorage.setItem('token', verificationData.token);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error authenticating with WebAuthn:", error);
        return false;
    }
};
