import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const InvestorBox = ({ data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!data) return <p className="text-gray-400">{t('Loading ownership data...')}</p>;

    return (
        <div>
            <h2 className="text-xl font-bold mb-2">{t('Your Ownership')}</h2>
            <p>{t('Shares Owned')}: <span className="font-semibold">{data.shares} ({data.ownershipPercentage}%)</span></p>
            <p>{t('Total Shares')}: <span className="font-semibold">{data.totalShares}</span></p>
            <p>{t('Current Share Price')}: <span className="font-semibold">{data.sharePrice} SEK</span></p>

            {/* Navigate to Investor Page */}
            <button
                onClick={() => navigate('/investor')}
                className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition"
            >
                {t('View More')}
            </button>
        </div>
    );
};

export default InvestorBox;
