import React, { useEffect, useState } from "react";
import { apiRequest } from "../utils/Api";

const DigitalOceanServices = () => {
    const [services, setServices] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {
        fetchServices();
        const interval = setInterval(fetchServices, 1800000); // Auto-refresh every 30 minutes
        return () => clearInterval(interval);
    }, []);

    const fetchServices = async () => {
        setRefreshing(true);
        try {
            const response = await apiRequest("/services/serviceStatusAll", "POST");
            if (response.status === "success") {
                setServices(response.data);
                setError(null);
            } else {
                setError("Failed to load services");
            }
        } catch (err) {
            setError("Failed to fetch data from the API.");
        }
        setLoading(false);
        setRefreshing(false);
    };

    const getFreeSpaceBorder = (freeSpace, totalSpace) => {
        const freePercentage = (freeSpace / totalSpace) * 100;
        if (freePercentage > 50) return "border-green-500"; // Safe
        if (freePercentage > 20) return "border-yellow-500"; // Warning
        return "border-red-500"; // Critical
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg mt-6">
            <h2 className="text-lg font-semibold">DigitalOcean Services</h2>

            {/* Refresh Button */}
            <div className="flex justify-between items-center mt-2">
                <button
                    className="px-3 py-1 bg-blue-500 text-white rounded-md"
                    onClick={fetchServices}
                    disabled={refreshing}
                >
                    {refreshing ? "Refreshing..." : "Refresh"}
                </button>
            </div>

            {loading ? (
                <p className="text-gray-400 mt-2">Loading services...</p>
            ) : error ? (
                <p className="text-red-400 mt-2">{error}</p>
            ) : (
                <div className="mt-4">
                    {Object.entries(services).map(([groupId, groupData]) => (
                        <div key={groupId} className="bg-gray-700 p-4 rounded-lg mb-4">
                            <h3 className="text-md font-bold text-white">{groupData.companyName}</h3>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                                {groupData.children.map((service) => {
                                    const freeSpaceGiB = (service.disk_usage / 1024 / 1024 / 1024).toFixed(1);
                                    const totalSpaceGiB = service.diskspace;
                                    const freeSpaceBorder = getFreeSpaceBorder(freeSpaceGiB, totalSpaceGiB);

                                    return (
                                        <div key={service.service_name} className="bg-gray-900 p-4 rounded-lg shadow-lg">
                                            {/* Service Name & Status */}
                                            <div className="flex justify-between items-center">
                                                <h4 className="text-lg font-semibold">{service.service_name}</h4>
                                                <span
                                                    className={`px-3 py-1 text-sm font-bold rounded-md ${
                                                        service.status === "active"
                                                            ? "bg-green-500 text-white"
                                                            : "bg-red-500 text-white"
                                                    }`}
                                                >
                                                    <i className={`fa fa-solid fa-${
                                                        service.status === "active" ? "check" : "times"
                                                    }`}></i>{" "}
                                                </span>
                                            </div>

                                            {/* Resource Info */}
                                            <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                                                <div className={`bg-gray-800 p-2 rounded-md border-2 ${freeSpaceBorder}`}>
                                                    <span className="font-bold">Free Space:</span>
                                                    <p>{freeSpaceGiB} GiB</p>
                                                </div>
                                                <div className="bg-gray-800 p-2 rounded-md">
                                                    <span className="font-bold">Total Space:</span>
                                                    <p>{totalSpaceGiB} GiB</p>
                                                </div>
                                                <div className="bg-gray-800 p-2 rounded-md">
                                                    <span className="font-bold">vCPU:</span>
                                                    <p>{service.vcpus}</p>
                                                </div>
                                                <div className="bg-gray-800 p-2 rounded-md">
                                                    <span className="font-bold">Memory:</span>
                                                    <p>{service.memory} MB</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DigitalOceanServices;
