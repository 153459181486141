import { Link } from "react-router-dom";

const EmployeeDashboard = () => {
    return (
        <div className="max-w-lg mx-auto p-6 bg-gray-800 text-white rounded-lg shadow-md mt-16">
            <h2 className="text-xl font-bold mb-4">Employee Dashboard</h2>
            <div className="space-y-4">
                <Link
                    to="/employee/salary"
                    className="block p-4 bg-blue-700 text-white text-center rounded-lg shadow-md hover:bg-blue-500 transition duration-300"
                >
                    Employee Salary Tracker
                </Link>
                <Link
                    to="/employee/timelogging"
                    className="block p-4 bg-green-700 text-white text-center rounded-lg shadow-md hover:bg-green-500 transition duration-300"
                >
                    Time Logging
                </Link>
            </div>
        </div>
    );
};

export default EmployeeDashboard;
