import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../utils/Api";

// Import existing dashboard components
import NewsBox from "../components/NewsBox";
import UsageBox from "../components/UsageBox";
import SavingsBox from "../components/SavingsBox";
import CurrencyRatesBox from "../components/CurrencyRatesBox";
import ToolsBox from "../components/ToolsBox";
import ToolsHintBox from "../components/ToolsHintBox";
import InvestorBox from "../components/InvestorBox";
import ReferralBox from "../components/ReferralBox"; // ✅ New ReferralBox

const Dashboard = () => {
    const { t } = useTranslation();

    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const data = await apiRequest("/dashboard/full", "POST");
                setDashboardData(data);
            } catch (err) {
                setError(t("Failed to load dashboard data"));
            }
            setLoading(false);
        };

        fetchDashboardData();
    }, [t]);

    if (loading) return <div className="text-white">Loading...</div>;
    if (error) return <div className="text-red-400 font-bold">{error}</div>;

    return (
        <div className="p-4 bg-gray-900 min-h-screen text-white grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <ToolsHintBox />

            {/* Referral Box - Always Visible */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <ReferralBox />
            </div>

            {/* Investor Box - Only show if user is an investor */}
            {dashboardData?.investor === 1 && (
                <div className="bg-gray-800 p-4 rounded-lg">
                    <InvestorBox data={dashboardData?.ownership} />
                </div>
            )}

            {/* Site News */}
            <div className="md:col-span-1 bg-gray-800 p-4 rounded-lg">
                <NewsBox data={dashboardData?.news} />
            </div>

            {/* Usage and Currency Rates */}
            <div className="md:col-span-1 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-gray-800 p-4 rounded-lg">
                    <SavingsBox data={dashboardData?.savings} />
                </div>
                <div className="bg-gray-800 p-4 rounded-lg">
                    <UsageBox data={dashboardData?.usage} />
                </div>
                <div className="bg-gray-800 p-4 rounded-lg">
                    <CurrencyRatesBox data={dashboardData?.currencies} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
