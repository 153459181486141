import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Registration = () => {
    const { t } = useTranslation();
    const { referralCode } = useParams();

    const [formData, setFormData] = useState({
        orgType: '1',
        invoiceInterval: '1',
        orgNum: '',
        companyName: '',
        address: '',
        givenName: '',
        surName: '',
        emailContact: '',
        emailInvoice: '',
        conditions: false
    });

    const [price, setPrice] = useState(295);
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [error, setError] = useState(null);

    const PRICE_COMPANY = 295;
    const PRICE_BOOKKEEPING = 1995;
    const PRICE_YEAR_MONTHS = 10;

    useEffect(() => {
        const { orgType, invoiceInterval } = formData;
        let basePrice = orgType === '1' ? PRICE_COMPANY : PRICE_BOOKKEEPING;

        if (invoiceInterval === '2') {
            const yearlyPrice = basePrice * PRICE_YEAR_MONTHS;
            const yearlyDiscountPrice = referralCode ? Math.floor(basePrice * 9) : yearlyPrice;
            setDiscountedPrice(referralCode ? yearlyDiscountPrice : null);
            setPrice(yearlyPrice);
        } else {
            const discountedMonthPrice = referralCode ? Math.floor(basePrice / 2) : basePrice;
            setDiscountedPrice(referralCode ? discountedMonthPrice : null);
            setPrice(basePrice);
        }
    }, [formData.orgType, formData.invoiceInterval, referralCode]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.conditions) {
            setError(t('You must accept the terms and conditions.'));
            return;
        }

        const submissionData = {
            ...formData,
            referralCode,
        };
        console.log('Form Submitted:', submissionData);
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white flex justify-center items-center">
            <div className="container mx-auto p-8 bg-gray-800 shadow-lg rounded-lg w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <h1 className="text-3xl font-bold mb-6 text-center">{t('RegisterAccount')}</h1>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Referral Code (Hidden) */}
                    {referralCode && <input type="hidden" name="referralCode" value={referralCode} />}

                    {/* Step 1: Account Type & Billing */}
                    <h2 className="text-xl font-semibold">{t('Step 1: Choose Account Type')}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm">{t('AccountType')}</label>
                            <select
                                name="orgType"
                                value={formData.orgType}
                                onChange={handleInputChange}
                                className="block w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            >
                                <option value="1">{t('Company / Individual')}</option>
                                <option value="2">{t('AccountingFirm')}</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm">{t('InvoiceInterval')}</label>
                            <select
                                name="invoiceInterval"
                                value={formData.invoiceInterval}
                                onChange={handleInputChange}
                                className="block w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            >
                                <option value="1">{t('Monthly')}</option>
                                <option value="2">{t('Yearly')}</option>
                            </select>
                        </div>
                    </div>

                    {/* Step 2: Company Information */}
                    <h2 className="text-xl font-semibold">{t('Step 2: Company Information')}</h2>
                    <div className="space-y-4">
                        <input
                            type="text"
                            name="orgNum"
                            value={formData.orgNum}
                            onChange={handleInputChange}
                            placeholder={t('organizationNumber')}
                            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            required
                        />
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleInputChange}
                            placeholder={t('companyName')}
                            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            required
                        />
                        <textarea
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            placeholder={t('billingAddress')}
                            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            required
                        />
                    </div>

                    {/* Step 3: Contact Details */}
                    <h2 className="text-xl font-semibold">{t('Step 3: Contact Details')}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <input
                            type="text"
                            name="givenName"
                            value={formData.givenName}
                            onChange={handleInputChange}
                            placeholder={t('firstName')}
                            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            required
                        />
                        <input
                            type="text"
                            name="surName"
                            value={formData.surName}
                            onChange={handleInputChange}
                            placeholder={t('lastName')}
                            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                            required
                        />
                    </div>
                    <input
                        type="email"
                        name="emailContact"
                        value={formData.emailContact}
                        onChange={handleInputChange}
                        placeholder={t('contactEmail')}
                        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                        required
                    />
                    <input
                        type="email"
                        name="emailInvoice"
                        value={formData.emailInvoice}
                        onChange={handleInputChange}
                        placeholder={t('invoiceEmail')}
                        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
                        required
                    />

                    {/* Terms & Conditions */}
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            name="conditions"
                            checked={formData.conditions}
                            onChange={handleInputChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                            required
                        />
                        <span className="ml-2 text-sm">
                            {t('termsConditions')} <a href="/terms" target="_blank" className="text-blue-400">{t('termsOfUse')}</a> {t('and')} <a href="/privacy" target="_blank" className="text-blue-400">{t('privacyPolicy')}</a>.
                        </span>
                    </div>

                    {/* Pricing information */}
                    <div className="bg-gray-800 p-4 rounded-lg text-center text-white">
                        <h2 className="text-lg font-semibold">{t('Price Breakdown')}</h2>

                        {/* Original Price */}
                        <p className="text-gray-300">
                            <span className="font-bold">{discountedPrice !== null ? t('OriginalPrice') : t('Price')}:&nbsp;</span>
                            {price} {t('currency_SEK')} {formData.invoiceInterval === '1' ? t('perMonth') : t('perYear')} <span>{t('exVat')}</span>
                        </p>

                        {/* Deduction (Discount) */}
                        {discountedPrice !== null && (
                            <p className="text-red-500">
                                <span className="font-bold">{t('Discount')}:</span> -{price - discountedPrice} {t('currency_SEK')}
                            </p>
                        )}

                        {/* New Total with explanation */}
                        {discountedPrice !== null && (
                            <>
                                <p className="text-green-400">
                                    <span className="font-bold">{t('NewTotal')}:</span> {discountedPrice} {t('currency_SEK')} <span>{t('exVat')}</span>
                                </p>
                                <p className="text-sm text-gray-500">
                                    {formData.invoiceInterval === '2'
                                        ? t('ThisAmountCoversFirstInvoiceYearly')
                                        : t('ThisAmountCoversFirstInvoiceMonthly')}
                                </p>
                            </>
                        )}
                    </div>

                    {/* Display Error */}
                    {error && <div className="text-red-400">{error}</div>}

                    {/* Register Button */}
                    <div className="flex justify-center">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg">
                            {t('Register')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Registration;
