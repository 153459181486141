import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../utils/Api';

const QueueTask = () => {
    const { t } = useTranslation();
    const [queueTask, setQueueTask] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);

    useEffect(() => {
        fetchQueueTask();
        const interval = setInterval(fetchQueueTask, 10000); // Auto-update queue every 10 sec
        return () => clearInterval(interval);
    }, []);

    // Fetch queue tasks (both active and last 25 executed)
    const fetchQueueTask = async () => {
        setQueueTask([]);
        try {
            const response = await apiRequest('/services/queueList', 'GET');
            if (response.status === 'success') {
                setQueueTask(response.data.queue);
                // setCompletedTasks(response.completed);
            }
        } catch (err) {
            console.error('Failed to fetch queue tasks', err);
        }
    };

    return (
        <>
            {/* Active Copy Queue */}
            <div className="bg-gray-800 p-4 rounded-lg mt-6">
                <h2 className="text-lg font-semibold">{t('Active Queue')}</h2>
                {queueTask.length ? (
                    <ul className="mt-2">
                        {queueTask.map((task, index) => (
                            <li key={index} className="border-b border-gray-600 p-2">
                                {t('Copying')} {task.siteName} ({task.domain}) - {t('Started At')}: {task.startedAt}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-400">{t('No active tasks')}</p>
                )}
            </div>

            {/* Last 25 Executed Tasks */}
            <div className="bg-gray-800 p-4 rounded-lg mt-6">
                <h2 className="text-lg font-semibold">{t('Last 25 Tasks')}</h2>
                {completedTasks.length > 0 ? (
                    <ul className="mt-2">
                        {completedTasks.map((task, index) => (
                            <li key={index} className="border-b border-gray-600 p-2">
                                {t('Copied')} {task.siteName} ({task.domain}) - {t('Completed At')}: {task.completedAt}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-400">{t('No completed tasks yet')}</p>
                )}
            </div>
        </>
    );
};

export default QueueTask;
