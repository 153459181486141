import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api';
import { useTranslation } from 'react-i18next';
import ToolFooter from '../../components/ToolFooter';
import FormatSelector from '../../components/FormatSelector';
import FileDownloadButton from '../../components/FileDownloadButton';
import CustomFileUpload from "../../components/CustomFileUpload"; // Importing FileDownloadButton component

const Avanza = () => {
    const [file, setFile] = useState(null);
    const [convertedFile, setConvertedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFormat, setSelectedFormat] = useState('csv'); // Default format
    const { t } = useTranslation();

    useEffect(() => {
        const savedFormat = localStorage.getItem('selectedFormat');
        if (savedFormat) {
            setSelectedFormat(savedFormat);
        }
    }, []);

    // API Documentation for Avanza tool
    const apiDocumentation = {
        description: 'Convert an Avanza CSV file to the format required by your bookkeeping software.',
        calls: {
            '/tools/avanza': 'POST',
        },
        post: {
            file: 'base64-encoded file -- The file to convert',
            format: 'string -- Desired output format',
        },
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!file) {
            setError(t('PleaseSelectFile'));
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/tools/avanza', 'POST', {
                    file: base64File,
                    format: selectedFormat, // Pass the selected format to the API
                });

                if (response.error) {
                    setError(response.error);
                } else {
                    setConvertedFile(response.convertedFile);
                }
            } catch (err) {
                setError(t('FailedToProcessFile'));
            }

            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-gray-800 shadow-lg rounded-lg text-white">
            <h1 className="text-2xl font-bold mb-4">{t('AvanzaTool')}</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <ol className="list-decimal">
                    <li className="mb-3">
                        {/* Format Selection Component */}
                        <FormatSelector selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} />
                    </li>
                    <li className="mb-3">
                        <CustomFileUpload onFileSelect={handleFileChange} />
                    </li>
                </ol>

                <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition duration-200 ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={loading}
                >
                    {loading ? t('Processing') : t('ConvertFile')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-400 text-sm">{error}</div>}

            {convertedFile && (
                <div className="mt-4">
                    {/* File Download Button Component */}
                    <FileDownloadButton convertedFile={convertedFile} selectedFormat={selectedFormat} fileName="converted_avanza" />
                </div>
            )}

            <ToolFooter apiDocumentation={apiDocumentation} />
        </div>
    );
};

export default Avanza;
