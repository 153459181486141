// src/components/ToolsBox.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import { apiRequest } from '../utils/Api';

const ToolsBox = () => {
    const navigate = useNavigate();
    const placeholderIcon = "/images/tools/placeholder.webp";

    const [toolsByCategory, setToolsByCategory] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchToolsList = async () => {
            try {
                const data = await apiRequest('/tools/toolsList', 'POST');
                if (!data.error) {
                    const newMenuHash = md5(JSON.stringify(data.tools));
                    const savedMenuHash = localStorage.getItem('menuHash');

                    if (savedMenuHash !== newMenuHash) {
                        localStorage.setItem('toolsByCategory', JSON.stringify(data.tools));
                        localStorage.setItem('menuHash', newMenuHash);
                        setToolsByCategory(data.tools);
                    } else {
                        setToolsByCategory(JSON.parse(localStorage.getItem('toolsByCategory')));
                    }
                }
            } catch (err) {
                setError("Failed to load tools list");
            }
            setLoading(false);
        };

        fetchToolsList();
    }, []);

    // Convert tools into a flat list
    const flatToolsList = [];
    Object.values(toolsByCategory).forEach((tools) => {
        tools.forEach((tool) => {
            if (!tool.category) {
                flatToolsList.push(tool);
            }
        });
    });

    // Filter tools based on search query
    const filteredTools = flatToolsList.filter(tool =>
        tool.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Bottom bar color based on payment level
    const getBottomBarColor = (paymentLevel) => {
        switch (paymentLevel) {
            case 1:
                return "bg-blue-500";
            case 2:
                return "bg-purple-500";
            default:
                return "bg-gray-600";
        }
    };

    if (loading) return <div className="text-white text-center">Loading tools...</div>;
    if (error) return <div className="text-red-400 font-bold text-center">{error}</div>;

    return (
        <div className="bg-gray-800 p-4 shadow-lg rounded text-white">
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search tools..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-3 border border-gray-600 rounded bg-gray-700 text-white placeholder-gray-400 mb-4"
            />

            {/* Horizontal Scrollable Tools List */}
            <div className="flex space-x-4 overflow-x-auto scrollbar-hide p-2">
                {/* Dashboard Button */}
                <button
                    onClick={() => navigate('/dashboard')}
                    className="relative flex flex-col items-center justify-center p-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition duration-200 h-24 min-w-[100px]"
                >
                    <i className="fas fa-tachometer-alt text-2xl w-10 h-10 mb-2"></i>
                    <span className="text-sm text-center">Dashboard</span>
                </button>
                {/* Button to navigate to the full Tools page */}
                <button
                    onClick={() => navigate('/tools')}
                    className="relative flex flex-col items-center justify-center p-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition duration-200 h-24 min-w-[100px]"
                >
                    <i className="fas fa-th-large text-2xl w-10 h-10 mb-2"></i>
                    <span className="text-sm text-center">All Tools</span>
                </button>
                {/* Tool Buttons */}
                {filteredTools.length > 0 ? (
                    filteredTools.map((tool) => (
                        <button
                            key={tool.id}
                            onClick={() => navigate(tool.url)}
                            className="relative flex flex-col items-center justify-center p-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition duration-200 h-24 min-w-[100px]"
                        >
                            <img
                                src={tool.logo ? `/images/tools/${tool.logo}` : placeholderIcon}
                                alt={tool.name}
                                className="w-10 h-10 object-contain mb-2"
                            />
                            <span className="text-sm text-center">{tool.name}</span>
                            <div className={`absolute bottom-0 left-0 w-full h-1 ${getBottomBarColor(tool.paymentLevel)}`}></div>
                        </button>
                    ))
                ) : (
                    <p className="text-gray-400 text-center w-full">No tools found.</p>
                )}
            </div>
        </div>
    );
};

export default ToolsBox;
