import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../utils/Api';

const HostedSites = () => {
    const { t } = useTranslation();
    const [hostedSites, setHostedSites] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchHostedSites();
    }, []);

    // Fetch hosted sites
    const fetchHostedSites = async () => {
        try {
            const response = await apiRequest('/services/sitesList', 'GET');
            if (response.status === 'success') {
                setHostedSites(response.sites);
            } else {
                setError(t('Failed to load hosted sites'));
            }
        } catch (err) {
            setError(t('Failed to load hosted sites'));
        }
    };

    // Request a site copy (adds to queue)
    const handleCopySite = async (siteId) => {
        try {
            const response = await apiRequest('/services/copySite', 'POST', { siteId });
            if (response.status === 'success') {
                alert(t('Copy request added to queue'));
            } else {
                alert(t('Failed to add copy request to queue'));
            }
        } catch (err) {
            alert(t('Error sending copy request'));
        }
    };

    if (error) return <div className="text-red-400 font-bold">{error}</div>;

    return (
        <div className="bg-gray-800 p-4 rounded-lg mt-6">
            <h2 className="text-lg font-semibold">{t('Hosted Sites')}</h2>
            <table className="w-full border-collapse mt-4">
                <thead>
                <tr className="bg-gray-700">
                    <th className="p-2 text-left">{t('Site Name')}</th>
                    <th className="p-2 text-left">{t('Domain')}</th>
                    <th className="p-2 text-left">{t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {hostedSites.map((site) => (
                    <tr key={site.id} className="border-b border-gray-600">
                        <td className="p-2">{site.name}</td>
                        <td className="p-2">{site.domain}</td>
                        <td className="p-2 space-x-3">
                            <a
                                href={`https://${site.domain}/wp-admin`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            >
                                {t('Open Admin')}
                            </a>
                            <button
                                onClick={() => handleCopySite(site.id)}
                                className="px-3 py-1 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                            >
                                {t('Copy Site')}
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default HostedSites;
