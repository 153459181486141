import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../../utils/Api';

// Import components
import OwnershipDetails from '../../components/OwnershipDetails';
import ProfitBox from '../../components/ProfitBox';
import BuyShares from '../../components/BuyShares';
import InvestorSales from "../../components/InvestorSales";
import InvestorPayments from "../../components/InvestorPayments";

const InvestorPanel = () => {
    const { t } = useTranslation();
    const [investorData, setInvestorData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInvestorData = async () => {
            try {
                const data = await apiRequest('/investor/dashboard', 'POST');
                setInvestorData(data);
            } catch (err) {
                setError(t('Failed to load investor data'));
            }
            setLoading(false);
        };

        fetchInvestorData();
    }, [t]);

    if (loading) return <div className="text-white">Loading...</div>;
    if (error) return <div className="text-red-400 font-bold">{error}</div>;

    return (
        <div className="p-4 bg-gray-900 min-h-screen text-white grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Ownership Details */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <OwnershipDetails data={investorData?.ownership} />
            </div>

            {/* Profit Box */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <ProfitBox data={investorData?.profit} />
            </div>

            {/* Buy Shares */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <BuyShares />
            </div>
            <InvestorPayments monthlyProfit={investorData?.profit.userProfit} />

            <InvestorSales profit={investorData?.profit.netProfit} pricePerShare={investorData?.ownership.sharePrice} totalShares={investorData?.ownership.totalShares} myShares={investorData?.ownership.shares} />
        </div>
    );
};

export default InvestorPanel;
