import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../utils/Api";

const Login = () => {
    const { type } = useParams(); // Extracts "type" from the URL (e.g., /login/ecommerce -> "ecommerce")
    const urlParams = new URLSearchParams(window.location.search);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("referralCode");
        localStorage.removeItem("apiInfo");

        // Handle redirection logic
        let redirectPath = "/dashboard"; // Default
        if (type) {
            redirectPath = `/${type}`; // Redirect based on login directive (e.g., /login/ecommerce → /ecommerce)
        }
        if (urlParams.has("redir")) {
            redirectPath = urlParams.get("redir"); // Priority to ?redir param
        }

        try {
            const response = await apiRequest("/auth/login", "POST", { email, password });

            if (response.error) {
                setError(response.error);
            } else {
                localStorage.setItem("apiInfo", response.apiInfo);
                localStorage.setItem("token", response.token);
                localStorage.setItem("user", JSON.stringify(response.user));
                localStorage.setItem("referralCode", response.referralCode);
                navigate(redirectPath); // Navigate to the determined path
            }
        } catch (err) {
            setError("Login failed.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
            <div className="w-full max-w-md p-6 bg-gray-800 shadow-lg rounded-lg">
                <h1 className="text-2xl font-bold text-center mb-4">Login</h1>

                <form onSubmit={handleLogin} className="space-y-4">
                    {/* Email Input */}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white"
                            disabled={isLoading}
                        />
                    </div>

                    {/* Password Input */}
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white"
                            disabled={isLoading}
                        />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md font-semibold transition duration-300 ease-in-out disabled:bg-gray-600"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="flex items-center justify-center">
                                <span className="loader mr-2"></span> Logging in...
                            </span>
                        ) : (
                            "Login"
                        )}
                    </button>
                </form>

                {/* Error Message */}
                {error && <div className="mt-4 text-red-400 text-center text-sm">{error}</div>}

                {/* Register Link */}
                <div className="mt-4 text-center">
                    <p className="text-gray-400 text-sm">
                        Don't have an account?{" "}
                        <a href="/register" className="text-blue-400 hover:underline">
                            Register here
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
