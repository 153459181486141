import React, { useState } from 'react';
import { apiRequest } from '../../utils/Api';
import { useTranslation } from 'react-i18next';

const AdminIncarnate = () => {
    const { t } = useTranslation();
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleIncarnateById = async () => {
        if (!userId) return;
        try {
            const response = await apiRequest('/Auth/incarnate', 'POST', { userId: parseInt(userId) });
            if (response.error) {
                setMessage(`❌ ${response.error}`);
            } else {
                setMessage(`✅ ${t('Successfully incarnated as User ID')} ${userId}`);
                localStorage.setItem('isIncarnated', 'true'); // ✅ Set incarnation flag
                localStorage.setItem('incarnatedUserId', userId);
                let originalToken = localStorage.getItem('token');
                localStorage.setItem('originalToken', originalToken);
                localStorage.setItem('token', response.token);
                window.location.reload();
            }
        } catch (err) {
            setMessage(`❌ ${t('Failed to incarnate')}`);
        }
    };

    const handleIncarnateByEmail = async () => {
        if (!email) return;
        try {
            const response = await apiRequest('/Auth/incarnateEmail', 'POST', { email });
            if (response.error) {
                setMessage(`❌ ${response.error}`);
            } else {
                setMessage(`✅ ${t('Successfully incarnated as')} ${email}`);
                localStorage.setItem('isIncarnated', 'true'); // ✅ Set incarnation flag
                localStorage.setItem('incarnatedUserEmail', email);
                let originalToken = localStorage.getItem('token');
                localStorage.setItem('originalToken', originalToken);
                localStorage.setItem('token', response.token);
                window.location.reload();
            }
        } catch (err) {
            setMessage(`❌ ${t('Failed to incarnate')}`);
        }
    };

    const handleRevert = async () => {
        try {
            const response = await apiRequest('/Auth/revertIncarnate', 'GET');
            if (response.error) {
                setMessage(`❌ ${response.error}`);
            } else {
                setMessage(`✅ ${t('Reverted back to admin')}`);
                localStorage.removeItem('isIncarnated'); // ✅ Remove incarnation flag
                localStorage.removeItem('incarnatedUserId');
                localStorage.removeItem('incarnatedUserEmail');
                let originalToken = localStorage.getItem('originalToken');
                localStorage.removeItem('originalToken');
                localStorage.setItem('token', originalToken);
                window.location.reload();
            }
        } catch (err) {
            setMessage(`❌ ${t('Failed to revert incarnate')}`);
        }
    };

    const isIncarnated = localStorage.getItem('isIncarnated') === 'true'; // ✅ Check if incarnated

    return (
        <div className="p-4 bg-gray-800 rounded-lg">
            <h2 className="text-lg font-bold">{t('Incarnate as User By ID')}</h2>

            {/* Form container for better spacing */}
            <div className="space-y-3">
                {/* User ID Input */}
                <div>
                    <input
                        type="number"
                        placeholder={t('User ID')}
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        className="w-full p-2 border border-gray-700 rounded text-black"
                    />
                    <button
                        onClick={handleIncarnateById}
                        className="w-full mt-2 bg-blue-500 p-2 rounded text-white hover:bg-blue-600 transition"
                    >
                        {t('Incarnate by User ID')}
                    </button>
                </div>
                <h2 className="text-lg font-bold">{t('Incarnate as User By Email')}</h2>
                {/* Email Input */}
                <div>
                    <input
                        type="email"
                        placeholder={t('User Email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-700 rounded text-black"
                    />
                    <button
                        onClick={handleIncarnateByEmail}
                        className="w-full mt-2 bg-blue-500 p-2 rounded text-white hover:bg-blue-600 transition"
                    >
                        {t('Incarnate by Email')}
                    </button>
                </div>

                {/* Revert Button */}
                {isIncarnated && (
                <button
                    onClick={handleRevert}
                    className="w-full bg-red-500 p-2 rounded text-white hover:bg-red-600 transition"
                >
                    {t('Revert to Admin')}
                </button>
                )}
            </div>

            {/* Message Output */}
            {message && <div className="mt-4 text-white">{message}</div>}
        </div>
    );
};

export default AdminIncarnate;
