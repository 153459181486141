import React from 'react';
import { useTranslation } from 'react-i18next';

const InvestorSales = ({ profit, pricePerShare, totalShares, myShares }) => {
    const { t } = useTranslation();

    // Safety checks to prevent division by zero
    if (!totalShares || totalShares <= 0) return <p className="text-gray-400">{t('No shares data available')}</p>;

    // Calculate my percentage ownership
    const myOwnershipPercentage = (myShares / totalShares) * 100;

    // Calculate investment cost
    const investmentCost = myShares * pricePerShare;

    // Theoretical monthly profits
    const scenarios = [
        { label: '50,000 SEK', amount: 50000 },
        { label: '500,000 SEK', amount: 500000 },
        { label: '1,000,000 SEK', amount: 1000000 },
        { label: '5,000,000 SEK', amount: 5000000 },
    ];

    return (
        <div className="bg-gray-800 p-4 rounded-lg">
            <h2 className="text-xl font-bold">{t('Investor Sales Projection')}</h2>

            {/* Current Profit */}
            <div className="mt-2 mb-4 p-3 bg-gray-700 rounded">
                <p className="text-lg font-semibold">{t('Current Monthly Profit')}</p>
                <p className="text-2xl font-bold text-green-400">{profit.toFixed(2)} SEK</p>
                <p className="text-sm text-gray-400">{t('Your share')}: {(profit * (myShares / totalShares)).toFixed(2)} SEK</p>
            </div>

            {/* Theoretical Profits Table */}
            <table className="w-full border-collapse border border-gray-700">
                <thead>
                <tr className="bg-gray-700">
                    <th className="border border-gray-600 p-2">{t('Scenario')}</th>
                    <th className="border border-gray-600 p-2 text-right">{t('Total Profit')}</th>
                    <th className="border border-gray-600 p-2 text-right">{t('Your profit per month')}</th>
                    <th className="border border-gray-600 p-2 text-right">{t('Investment Value')}</th>
                    <th className="border border-gray-600 p-2 text-right">{t('Months Until Profit')}</th>
                    <th className="border border-gray-600 p-2 text-right">{t('Months Until 30% ROI')}</th>
                </tr>
                </thead>
                <tbody>
                {scenarios.map(({ label, amount }) => {
                    const projectedProfit = amount / totalShares; // Profit per share
                    const myProjectedProfit = projectedProfit * myShares; // My profit
                    const monthsUntilProfit = myProjectedProfit > 0 ? investmentCost / myProjectedProfit : '∞'; // Avoid division by zero
                    const monthsUntilROI = myProjectedProfit > 0 ? investmentCost / (myProjectedProfit / 0.3) : '∞'; // Avoid division by zero

                    return (
                        <tr key={label}>
                            <td className="border border-gray-600 p-2">{label}</td>
                            <td className="border border-gray-600 p-2 text-right">{amount.toFixed(2)} SEK</td>
                            <td className="border border-gray-600 p-2 text-right text-green-400">
                                {myProjectedProfit.toFixed(2)} SEK
                            </td>
                            <td className="border border-gray-600 p-2 text-right">{investmentCost.toFixed(2)} SEK</td>
                            <td className="border border-gray-600 p-2 text-right">
                                {monthsUntilProfit === '∞' ? '∞' : monthsUntilProfit.toFixed(1)}
                            </td>
                            <td className="border border-gray-600 p-2 text-right">
                                {monthsUntilROI === '∞' ? '∞' : monthsUntilROI.toFixed(1)}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            {/* Share Price Info */}
            <div className="mt-4 text-sm text-gray-400">
                <p>{t('Current Share Price')}: {pricePerShare.toFixed(2)} SEK</p>
                <p>{t('Total Shares Available')}: {totalShares}</p>
                <p>{t('Your Shares')}: {myShares} ({myOwnershipPercentage.toFixed(2)}%)</p>
            </div>
        </div>
    );
};

export default InvestorSales;
