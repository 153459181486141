import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import LoggedOutHeader from './components/LoggedOutHeader'; // New header for logged out users
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import Sales from './pages/Sales';
import Register from "./pages/Register";
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Bokio from './pages/tools/Bokio';
import CollectorCard from "./pages/tools/CollectorCard";
import Referral from "./pages/Referral";
import ToolFooter from './components/ToolFooter';
import KanbanBoard from "./pages/KanbanBoard";
import { isAuthenticated } from './Auth'; // Auth helper function to check if the user is logged in
import './styles/FontAwesome.css';
import Handelsbanken from "./pages/tools/Handelsbanken";
import Lansforsakringar from "./pages/tools/Lansforsakringar";
import Nordea from "./pages/tools/Nordea";
import SveaEkonomi from "./pages/tools/SveaEkonomi";
import Swedbank from "./pages/tools/Swedbank";
import Paypal from "./pages/tools/Paypal";
import MarginalenBank from "./pages/tools/MarginalenBank";
import Avanza from "./pages/tools/Avanza";
import DanskeBank from "./pages/tools/DanskeBank";
import Nets from "./pages/tools/Nets";
import NetsEasy from "./pages/tools/NetsEasy";
import Bambora from "./pages/tools/Bambora";
import Amex from "./pages/tools/Amex";
import CustomCsvBuilder from "./pages/tools/CustomCsvBuilder";
import FileImporter from "./pages/FileImporter";
import NimGame from "./pages/fun/nim";
import HackGame from "./pages/fun/HackGame";
import DrivingCostCalculator from "./pages/DrivingCostCalculator";
import CompanyManagement from "./pages/CompanyManagement";
import AdminPanel from "./pages/admin/AdminPanel";
import InvestorPanel from "./pages/investor/InvestorPanel";
import PersonalDebts from "./pages/PersonalDebts";
import PersonalDebtsIndex from "./pages/PersonalDebtsIndex";
import AddPersonalDebt from "./pages/AddPersonalDebt";
import EmployeeSalaryTracker from "./pages/employeetools/EmployeeSalaryTracker";
import CustomerManagementPanel from "./pages/admin/CustomerManagementPanel";
import ToolsPage from "./pages/ToolsPage";
import AddBudgetEvent from "./pages/budgetplanner/AddBudgetEvent";
import BudgetOverview from "./pages/budgetplanner/BudgetOverview";
import Initialize from "./pages/budgetplanner/Initialize";
import SimpleShoppingList from "./pages/budgetplanner/SimpleShoppingList";
import ProjectManagement from "./pages/ProjectManagement";
import EcommerceDashboard from "./pages/ecommerce/EcommerceDashboard";
import Picklist from "./pages/ecommerce/Picklist";
import TimeLogging from "./pages/employeetools/TimeLogging";
import EmployeeDashboard from "./pages/employeetools/EmployeeDashboard";
import PickOrderPage from "./pages/ecommerce/PickOrderPage";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in on mount
        const checkAuthStatus = () => {
            const loggedIn = isAuthenticated(); // Check if token exists or valid
            setIsLoggedIn(loggedIn);
        };

        checkAuthStatus();
    }, []);

    return (
        <Router>
            <div className="flex">
                {/* Sidebar */}

                <div className="flex flex-col w-full">
                    {/* Conditional Header */}
                    {isLoggedIn ? <Header /> : <LoggedOutHeader />} {/* Show appropriate header */}

                    {/* Main Content */}
                    <main className="flex-grow pt-16">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/login/:type" element={<Login />} />

                            <Route path="/my" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="/home" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="/my/debts" element={<ProtectedRoute><PersonalDebtsIndex /></ProtectedRoute>} />
                            <Route path="/my/debts-add" element={<ProtectedRoute><AddPersonalDebt /></ProtectedRoute>} />
                            <Route path="/my/debts/:debtId" element={<ProtectedRoute><PersonalDebts /></ProtectedRoute>} />
                            <Route path="/companies" element={<ProtectedRoute><CompanyManagement /></ProtectedRoute>} />

                            <Route path="/tool/bokio" element={<ProtectedRoute><Bokio /></ProtectedRoute>} />
                            <Route path="/tool/collectorcard" element={<ProtectedRoute><CollectorCard /></ProtectedRoute>} />
                            <Route path="/tool/handelsbanken" element={<ProtectedRoute><Handelsbanken /></ProtectedRoute>} />
                            <Route path="/tool/lansforsakringar" element={<ProtectedRoute><Lansforsakringar /></ProtectedRoute>} />
                            <Route path="/tool/nordea" element={<ProtectedRoute><Nordea /></ProtectedRoute>} />
                            <Route path="/tool/sveaekonomi" element={<ProtectedRoute><SveaEkonomi /></ProtectedRoute>} />
                            <Route path="/tool/swedbank" element={<ProtectedRoute><Swedbank /></ProtectedRoute>} />
                            <Route path="/tool/paypal" element={<ProtectedRoute><Paypal /></ProtectedRoute>} />
                            <Route path="/tool/marginalen" element={<ProtectedRoute><MarginalenBank /></ProtectedRoute>} />
                            <Route path="/tool/avanza" element={<ProtectedRoute><Avanza /></ProtectedRoute>} />
                            <Route path="/tool/danskebank" element={<ProtectedRoute><DanskeBank /></ProtectedRoute>} />
                            <Route path="/tool/netssie" element={<ProtectedRoute><Nets /></ProtectedRoute>} />
                            <Route path="/tool/netseasy" element={<ProtectedRoute><NetsEasy /></ProtectedRoute>} />
                            <Route path="/tool/bambora" element={<ProtectedRoute><Bambora /></ProtectedRoute>} />
                            <Route path="/tool/amex" element={<ProtectedRoute><Amex /></ProtectedRoute>} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/register/:referralCode" element={<Register />} />
                            <Route path="/csvbuilder" element={<ProtectedRoute><CustomCsvBuilder /></ProtectedRoute>} />

                            <Route path="/referral" element={<Referral />} />
                            <Route path="/referral/:referralCode" element={<Referral />} />

                            <Route path="/planner" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />
                            <Route path="/planner/:id" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />
                            <Route path="/planner/:id/:cardId" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />
                            <Route path="/planner/:id/:cardId/:action" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />

                            <Route path="/blog/import" element={<ProtectedRoute><FileImporter /></ProtectedRoute>} />

                            <Route path="/fun/nim" element={<NimGame />} />
                            <Route path="/fun/hack" element={<HackGame />} />

                            <Route path="/driving" element={<DrivingCostCalculator />} />

                            <Route path="/employee/salary" element={<ProtectedRoute><EmployeeSalaryTracker /></ProtectedRoute>} />
                            <Route path="/employee/timelogging" element={<ProtectedRoute><TimeLogging /></ProtectedRoute>} />
                            <Route path="/employee" element={<ProtectedRoute><EmployeeDashboard /></ProtectedRoute>} />

                            <Route path="/admin/ekonomiapi" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>} />
                            <Route path="/admin/customers" element={<ProtectedRoute><CustomerManagementPanel /></ProtectedRoute>} />

                            <Route path="/investor" element={<ProtectedRoute><InvestorPanel /></ProtectedRoute>} />

                            <Route path="/tools" element={<ProtectedRoute><ToolsPage /></ProtectedRoute>} />

                            <Route path="/budget/add" element={<ProtectedRoute><AddBudgetEvent /></ProtectedRoute>} />
                            <Route path="/budget/init" element={<ProtectedRoute><Initialize /></ProtectedRoute>} />
                            <Route path="/budget" element={<ProtectedRoute><BudgetOverview /></ProtectedRoute>} />
                            <Route path="/budget/shoppingsimple" element={<ProtectedRoute><SimpleShoppingList /></ProtectedRoute>} />

                            <Route path="/project/management" element={<ProtectedRoute><ProjectManagement /></ProtectedRoute>} />

                            <Route path="/ecommerce" element={<ProtectedRoute><EcommerceDashboard /></ProtectedRoute>} />
                            <Route path="/ecommerce/pick" element={<ProtectedRoute><Picklist /></ProtectedRoute>} />
                            <Route path="/ecommerce/pick-order/:orderId" element={<ProtectedRoute><PickOrderPage /></ProtectedRoute>} />

                            <Route path="/logout" element={<Navigate to="/login?redir=/" />} />
                            <Route path="/" element={<Sales />} />

                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </main>
                    {/* Tool Footer */}
                    <ToolFooterWrapper />

                    {/* Footer */}
                    <Footer />

                </div>
            </div>
        </Router>
    );
}

function ToolFooterWrapper() {
    const location = useLocation();

    // Check if the current route matches /tool/*
    const isToolPage = location.pathname.startsWith('/tool/');

    return isToolPage ? <ToolFooter /> : null;
}

export default App;
