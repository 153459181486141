import React, { useState, useEffect } from "react";
import { apiRequest } from "../utils/Api";
import { useTranslation } from "react-i18next";

const CompanyManagement = () => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [aliasStatus, setAliasStatus] = useState(null);
    const [formData, setFormData] = useState({
        companyName: "",
        orgNum: "",
        alias: "",
        emailContact: "",
        firstName: "",
        lastName: "",
        address: "",
    });
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await apiRequest("/business/myCompanies", "GET");
            if (response.data) {
                setCompanies(response.data);
            } else {
                setError("Failed to load companies.");
            }
        } catch (error) {
            setError("Failed to load companies.");
        }
    };

    // Handle input changes
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === "alias") {
            validateAlias(value);
        }
    };

    // Alias validation
    const validateAlias = async (alias) => {
        if (alias.length < 3) {
            setAliasStatus({ valid: false, message: "Alias must be at least 3 characters long." });
            return;
        }

        if (!/^[a-z0-9]+$/i.test(alias)) {
            setAliasStatus({ valid: false, message: "Alias can only contain letters and numbers." });
            return;
        }

        try {
            const response = await apiRequest(`/business/aliasCheck`, "POST", { alias });
            if (response.success) {
                setAliasStatus({ valid: true, message: "Alias is available." });
            } else {
                setAliasStatus({ valid: false, message: "Alias is already taken." });
            }
        } catch (error) {
            setAliasStatus({ valid: false, message: "Error checking alias availability." });
        }
    };

    // Handle Add or Edit Company
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        if (!aliasStatus || !aliasStatus.valid) {
            setError("Please enter a valid alias.");
            setLoading(false);
            return;
        }

        try {
            const url = editMode
                ? `/business/editCompany`
                : "/business/createCompany";
            const method = "POST";

            if (editMode) {
                formData.id = selectedCompany.id;
            }

            const response = await apiRequest(url, method, formData);
            if (response.error) {
                setError(response.details || "Failed to process request.");
            } else {
                setSuccess(editMode ? "Company updated!" : "Company created!");
                setShowForm(false);
                fetchCompanies();
                resetForm();
            }
        } catch (err) {
            setError("An unexpected error occurred.");
        }
        setLoading(false);
    };

    // Reset Form
    const resetForm = () => {
        setEditMode(false);
        setSelectedCompany(null);
        setAliasStatus(null);
        setFormData({
            companyName: "",
            orgNum: "",
            alias: "",
            emailContact: "",
            firstName: "",
            lastName: "",
            address: "",
        });
    };

    // Open Edit Form
    const handleEdit = (company) => {
        setEditMode(true);
        setSelectedCompany(company);
        setShowForm(true);
        setAliasStatus(null);
        setFormData({
            companyName: company.companyName,
            orgNum: company.orgNumber,
            alias: company.alias,
            emailContact: company.invoiceNotification,
            firstName: "",
            lastName: "",
            address: company.invoiceAddress,
        });
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-900 text-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-4">{t("Company Management")}</h1>

            {success && <div className="mb-4 p-2 bg-green-600 text-white rounded">{t(success)}</div>}
            {error && <div className="mb-4 p-2 bg-red-600 text-white rounded">{t(error)}</div>}

            <div className="mb-4">
                <h2 className="text-lg font-semibold mb-2">{t("Your Companies")}</h2>
                <ul className="space-y-2">
                    {companies.length > 0 ? (
                        companies.map((company) => (
                            <li key={company.id} className="p-3 bg-gray-800 rounded">
                                <div className="flex justify-between items-center">
                                    <span className="font-bold">{company.companyName}</span>
                                    <span className={`px-2 py-1 rounded text-sm ${
                                        company.status === 1 ? "bg-green-500" : "bg-yellow-500"
                                    }`}>
                                        {company.status === 1 ? t("Active") : t("Inactive")}
                                    </span>
                                </div>
                                <div className="text-gray-400 text-sm">{t("organizationNumber")}: {company.orgNumber}</div>
                                <div className="text-gray-400 text-sm">{t("Users")}: {company.users}</div>

                                <button
                                    onClick={() => handleEdit(company)}
                                    className="mt-2 w-full p-2 bg-blue-700 text-white rounded-md"
                                >
                                    {t("Edit")}
                                </button>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-400">{t("No companies added yet.")}</p>
                    )}
                </ul>
            </div>

            <button
                onClick={() => { setShowForm(!showForm); resetForm(); }}
                className="w-full p-2 bg-blue-600 text-white rounded"
            >
                {showForm ? "✖" : "➕"} {editMode ? t("Edit Company") : t("Add New Company")}
            </button>

            {showForm && (
                <form onSubmit={handleSubmit} className="mt-4 p-4 bg-gray-800 rounded">
                    <label className="text-sm text-gray-300">{t("Company Name")}</label>
                    <input
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                        className="w-full p-2 bg-gray-700 text-white rounded"
                    />

                    <label className="text-sm text-gray-300">{t("Organization Number")}</label>
                    <input
                        type="text"
                        name="orgNum"
                        value={formData.orgNum}
                        onChange={handleChange}
                        required
                        className="w-full p-2 bg-gray-700 text-white rounded"
                    />

                    <label className="text-sm text-gray-300">{t("Email Contact")}</label>
                    <input
                        type="email"
                        name="emailContact"
                        value={formData.emailContact}
                        onChange={handleChange}
                        required
                        className="w-full p-2 bg-gray-700 text-white rounded"
                    />

                    <label className="text-sm text-gray-300">{t("Alias")}</label>
                    <input
                        type="text"
                        name="alias"
                        value={formData.alias}
                        onChange={handleChange}
                        required
                        className="w-full p-2 bg-gray-700 text-white rounded"
                    />
                    {aliasStatus && (
                        <p className={`text-sm mt-1 ${
                            aliasStatus.valid ? "text-green-400" : "text-red-400"
                        }`}>
                            {t(aliasStatus.message)}
                        </p>
                    )}

                    {/* Status Dropdown */}
                    <label className="text-sm text-gray-300">{t("Status")}</label>
                    <select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-700 text-white rounded"
                    >
                        <option value="1">{t("Active")}</option>
                        <option value="2">{t("Inactive")}</option>
                    </select>

                    <button type="submit" className="mt-4 w-full p-2 bg-green-600 text-white rounded">
                        {loading ? t("Processing...") : editMode ? t("Update Company") : t("Create Company")}
                    </button>
                </form>
            )}
        </div>
    );
};

export default CompanyManagement;
