import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "Bank": "Bank",
            "SIE": "SIE",
            "PDF": "PDF",
            "Mejl": "Mail",
            "Övrigt": "Other",
            "Administration": "Administration",
            "Hours": "Hours",
            "Mon": "Mon",
            "Tue": "Tue",
            "Wed": "Wed",
            "Thu": "Thu",
            "Fri": "Fri",
            "Sat": "Sat",
            "Sun": "Sun",
            "Site News": "EkonomiAPI News",
            "Time Saved Per Day": "Time Saved Per Day",
            "Currency Conversion Rates": "Currency Conversion Rates",
            "to": "to",
            "Tool": "Tool",
            "Install": "Install",
            "BokioTool": "Bokio",
            "UploadCSVFile": "Upload CSV File",
            "ConvertFile": "Convert File",
            "Processing": "Processing...",
            "PleaseSelectFile": "Please select a file to upload.",
            "FailedToProcessFile": "Failed to process the file.",
            "DownloadConvertedFile": "Download Converted File",
            "UserScriptInstructions": "This tool requires installing an additional tool to work properly.",
            "BokioToolDescription": "Converts Bokio exports to a simple format as used in Fortnox as an example",
            "CollectorKortTool": "Collector card",
            "UploadFile": "Upload file",
            "OnlyCardTransactions": "Only process card transactions",
            "welcomeHeader": "Welcome to EkonomiAPI",
            "pitchHeader": "Time is Money – Invest in Efficiency",
            "pitchPara1": "In today’s fast-paced world, time is one of the most valuable resources you have. Managing your financial tasks manually or using outdated processes can take hours out of your day – time that could be spent growing your business or focusing on what really matters. That’s where our tools come in.",
            "pitchPara2": "By automating tedious financial processes, we not only streamline your workflow but also help you reclaim valuable time. Whether you’re handling bank transactions, accounting, or invoicing, our tools are designed to perform these tasks quickly and efficiently – so you don’t have to do them manually.",
            "pitchSubHeader1": "How Time Savings Translate to Money Savings:",
            "pitchList1": "Less Time on Administrative Tasks",
            "pitchList1Description": "Every hour you or your team spends on repetitive manual tasks is an hour not spent on revenue-generating activities. With automation, tasks that used to take hours can now be completed in minutes.",
            "pitchList2": "Avoid Costly Mistakes",
            "pitchList2Description": "Human errors in financial management can lead to costly mistakes. By using our tools to automate calculations and data entry, you reduce the risk of errors, protecting your bottom line. Our tools are made by humans and controlled by AI, so we have the best of both worlds.",
            "pitchList3": "Increased Productivity",
            "pitchList3Description": "Imagine what you could accomplish with the extra hours you save each week. You could focus on strategic planning, customer relationships, or growing your business, directly impacting your revenue.",
            "pitchList4": "Reduce Labor Costs",
            "pitchList4Description": "Instead of hiring extra staff to manage differences in financial records, our tools take care of these tasks automatically, reducing your staffing costs.",
            "pitchSubHeader2": "You can integrate our tools into your existing workflow, saving you time and money.",
            "pitchPara3": "Our tools are also available via our API, allowing you to integrate them into your existing workflow. Do you have your own idea of how to use our tools? Contact us and we will help you get started.",
            "pitchSubHeader3": "Start saving time, money, and resources today",
            "pitchPara4": "Using our wast library of tools, you can automate your financial processes and save time, money, and resources.",
            "pitchList5": "Helpdesk Integration",
            "pitchList5Description": "Our integrated helpdesk system allows you to manage customer inquiries and support requests efficiently. Keep track of every ticket, resolve issues faster, and provide superior customer service.",
            "pitchList6": "Advanced Planner",
            "pitchList6Description": "Our advanced planner lets you manage tasks, set deadlines, and track progress in a Kanban-style board. Organize and prioritize your workflow seamlessly.",
            "registerButton": "Sign Up Now",
            "registerHeader": "Register an Account",
            "accountType": "Account Type",
            "companyIndividual": "Company / Individual",
            "accountingFirm": "Accounting Firm",
            "invoiceInterval": "Invoice Interval",
            "monthly": "Monthly",
            "yearly": "Yearly",
            "organizationNumber": "Organization Number",
            "companyName": "Company Name",
            "billingAddress": "Billing Address",
            "firstName": "First Name",
            "lastName": "Last Name",
            "contactEmail": "Contact Email",
            "invoiceEmail": "Invoice Email",
            "termsConditions": "I accept the",
            "termsOfUse": "Terms and Conditions",
            "privacyPolicy": "Privacy Policy",
            "and": "and",
            "register": "Register",
            "currency_SEK": "SEK",
            "perMonth": "per month",
            "perYear": "per year",
            "exVat": "ex VAT",
            "errorTerms": "You must accept the terms and conditions.",
            "your_referral_code": "Your referral code",
            "share_link_earn_rewards": "Share this link with your friends to earn a free month",
            "share_link_description": "When your friends sign up using this link, you will receive a free month of EkonomiAPI. Your friend will also receive 50% off their first month.",
            "Close": "Close",
            "referralTitle": "You're Invited to Save with EkonomiAPI",
            "referralBy": "You were referred by {{referrerName}}!",
            "referralBonus": "Sign up now and enjoy {{bonusInfo}}.",
            "defaultBonus": "50% off your first month",
            "referralPitchHeader": "How Time Savings Translate to Money Savings:",
            "referralList1": "Less Time on Administrative Tasks",
            "referralList1Description": "Tasks that used to take hours can now be completed in minutes.",
            "referralList2": "Avoid Costly Mistakes",
            "referralList2Description": "Our tools automate processes, reducing the risk of human error.",
            "referralList3": "Increased Productivity",
            "referralList3Description": "Spend your time on strategic activities that generate revenue.",
            "referralList4": "Reduce Labor Costs",
            "referralList4Description": "Our tools handle the heavy lifting, reducing your staffing costs.",
            "referralSubHeader": "Integrate with Your Workflow",
            "referralPara": "Our tools integrate seamlessly into your existing workflow to save you time and money.",
            "referralButton": "Register Now",
            "firstMonthDiscount": "50% off your first month, then ",
            "Price": "Price",
            "loading": "Loading...",
            "error": "Error",
            "exvat": "ex VAT",
            "ThisAmountCoversFirstInvoiceYearly": "This amount covers the first invoice yearly, then the price reverts to the regular price",
            "ThisAmountCoversFirstInvoiceMonthly": "This amount covers the first invoice monthly, then the price reverts to the regular price",
            "dashboard": "Dashboard",
            "SelectOutputFormat": "Select output format",
            "HandelsbankenTool": "Handelsbanken",
            "LansforsakringarTool": "Länsförsäkringar",
            "NordeaTool": "Nordea",
            "SveaEkonomiTool": "Svea Ekonomi",
            "SwedbankTool": "Swedbank and Sparbankerna",
            "PayPalTool": "PayPal",
            "MarginalenBankTool": "Marginalen Bank",
            "AvanzaTool": "Avanza Bank",
            "DanskeBankTool": "Danske Bank",
            "NetsTool": "Nets",
            "NetsEasyTool": "Nets Easy",
            "BamboraTool": "Bambora",
            "InAccount": "In account",
            "Series": "Series",
            "PleaseEnterMessage": "Please enter a message",
            "BugReportedSuccessfully": "Bug reported successfully",
            "ErrorReportingBug": "Error reporting the bug",
            "ReportBug": "Report a Bug",
            "BugMessage": "Bug Message",
            "AttachFile": "Attach a File",
            "SubmitBug": "Submit Bug",
            "AmexTool": "Amex",
            "Serie": "Series",
            "AmexToolDescription": "Convert an Amex file to a format that can be imported into your bookkeeping software.",
            "CustomCsvBuilder": "Custom CSV Format Builder",
            "ChooseYourCsvColumns": "Select the keys for your custom CSV columns. You can also choose 'blank' for an empty column.",
            "SaveFormat": "Save Format",
            "Saving": "Saving...",
            "FormatSavedSuccessfully": "Format saved successfully!",
            "FailedToSaveFormat": "Failed to save format. Please try again.",
            "format_CUSTOM": 'Custom CSV',
            "format_CSV": 'Fortnox CSV',
            "format_SIE": 'SIE',
            "format_PDF": 'PDF',
            "format_XLS": 'Excel (xls)',
            "format_XLSX": 'Excel (xlsx)',
            "format_BGMAX": 'BgMax',
            "CustomFormatEditor": "Custom CSV Format Editor",
            "DragDropOrClick": "Drag and drop or click to upload",
            "Delimiter": "Delimiter",
            "Comma": "Comma",
            "Semicolon": "Semicolon",
            "Tab": "Tab",
            "rowNumber": "Row Number",
            "clearingNumber": "Clearing Number",
            "accountNumber": "Account Number",
            "product": "Product",
            "currency": "Currency",
            "bookingDate": "Booking Date",
            "transactionDate": "Transaction Date",
            "valueDate": "Value Date",
            "reference": "Reference",
            "description": "Description",
            "amount": "Amount",
            "balance": "Balance",
            "BrowserNotSupported": "Your browser is not supported. Please use a modern browser like Chrome, Firefox, or Brave.",
            "Total Groups": "Total Companies",
            "ImportTool": "Import Tool",
            "whatIsEkonomiAPI": "What is EkonomiAPI?",
            "ekonomiAPIDescription": "EkonomiAPI is a comprehensive suite of tools designed to simplify bookkeeping, automate financial tasks, and optimize efficiency for small businesses and accounting professionals. With seamless integrations, automated data handling, and real-time insights, you save time and reduce errors. Whether you're managing invoices, tracking expenses, or handling payroll, EkonomiAPI empowers you to streamline financial workflows effortlessly.",
            "referAFriend": "Refer a Friend & Get Rewards!",
            "referralDescription": "Share your referral code and your friend gets 20% off their first invoice up to 300 SEK. You get a free month added to your account!",
            "copy": "Copy",
            "referralCopied": "Referral code copied to clipboard!"
        }
    },
    sv: {
        translation: {
            "Bank": "Bank",
            "SIE": "SIE",
            "PDF": "PDF",
            "Mejl": "Mejl",
            "Administration": "Administration",
            "Hours": "Timmar",
            "Mon": "Mån",
            "Tue": "Tis",
            "Wed": "Ons",
            "Thu": "Tor",
            "Fri": "Fre",
            "Sat": "Lör",
            "Sun": "Sön",
            "Site News": "EkonomiAPI nytt",
            "Time Saved Per Day": "Tidsbesparning per dag",
            "Currency Conversion Rates": "Valutakurser",
            "Install": "Installera",
            "to": "till",
            "Tool": "Verktyg",
            "BokioTool": "Bokio",
            "UploadCSVFile": "Ladda upp CSV-fil",
            "ConvertFile": "Konvertera fil",
            "Processing": "Bearbetar...",
            "PleaseSelectFile": "Vänligen välj en fil att ladda upp.",
            "FailedToProcessFile": "Det gick inte att bearbeta filen.",
            "DownloadConvertedFile": "Ladda ner konverterad fil",
            "UserScriptInstructions": "Detta verktyg behöver installera ett extra verktyg för att fungera korrekt.",
            "BokioToolDescription": "Konverterar Bokio-exporter till ett enkelt format som används i Fortnox t.ex.",
            "CollectorKortTool": "Collector kort",
            "UploadFile": "Ladda upp fil",
            "OnlyCardTransactions": "Bearbeta endast korttransaktioner",
            "welcomeHeader": "Välkommen till EkonomiAPI",
            "pitchHeader": "Tid är pengar – Investera i effektivitet",
            "pitchPara1": "I dagens snabba värld är tid en av de mest värdefulla resurserna du har. Att hantera dina ekonomiska uppgifter manuellt eller med föråldrade processer kan ta timmar av din dag – tid som skulle kunna användas till att utveckla ditt företag eller fokusera på vad som verkligen är viktigt. Det är här våra verktyg kommer in i bilden.",
            "pitchPara2": "Genom att automatisera tråkiga ekonomiska processer effektiviserar vi inte bara ditt arbetsflöde, utan hjälper dig också att återfå värdefull tid. Oavsett om du hanterar banktransaktioner, bokföring eller fakturering, är våra verktyg utformade för att sköta dessa uppgifter snabbt och effektivt – så att du slipper göra det manuellt.",
            "pitchSubHeader1": "Hur tidsbesparingar blir ekonomiska besparingar:",
            "pitchList1": "Mindre tid på administrativa uppgifter",
            "pitchList1Description": "Varje timme du eller ditt team spenderar på repetitiva manuella uppgifter är en timme som inte läggs på inkomstbringande aktiviteter. Med automatisering kan uppgifter som tidigare tog timmar nu utföras på minuter.",
            "pitchList2": "Undvik kostsamma misstag",
            "pitchList2Description": "Mänskliga fel i ekonomihanteringen kan leda till kostsamma misstag. Genom att använda våra verktyg för att automatisera beräkningar och datainmatning minskar du risken för fel, vilket skyddar ditt resultat. Våra verktyg är utvecklade av människor och kontrollerade av AI så vi har det bästa från båda världarna.",
            "pitchList3": "Ökad produktivitet",
            "pitchList3Description": "Föreställ dig vad du skulle kunna åstadkomma med de extra timmar du sparar varje vecka. Du kan fokusera på strategisk planering, kundrelationer eller att utveckla ditt företag, vilket direkt kan påverka dina intäkter.",
            "pitchList4": "Minska arbetskostnader",
            "pitchList4Description": "Istället för att anställa extra personal för att hantera bokföring eller ekonomiska register, tar våra verktyg hand om dessa uppgifter automatiskt, vilket minskar dina personalkostnader.",
            "pitchList5": "Integrerat supportsystem",
            "pitchList5Description": "Vårt integrerade supportsystem låter dig hantera kundförfrågningar och supportärenden effektivt. Håll koll på varje ärende, lös problem snabbare och leverera överlägsen kundservice.",
            "pitchList6": "Avancerad Planner",
            "pitchList6Description": "Vår avancerade planner låter dig hantera uppgifter, sätta deadlines och följa framsteg i en Kanban-liknande tavla. Organisera och prioritera ditt arbetsflöde smidigt.",
            "pitchSubHeader2": "Du kan integrera våra verktyg i ditt befintliga arbetsflöde, vilket sparar tid och pengar.",
            "pitchPara3": "Våra verktyg är också tillgängliga via vårt API, vilket gör att du kan integrera dem i ditt befintliga arbetsflöde. Har du en egen idé om hur du vill använda våra verktyg? Kontakta oss så hjälper vi dig att komma igång.",
            "pitchSubHeader3": "Spara tid, pengar och resurser redan idag.",
            "pitchPara4": "Med vår stora verktygslåda kan du automatisera dina ekonomiska processer och spara tid, pengar och resurser.",
            "registerButton": "Registrera dig nu",
            "registerHeader": "Registrera ett konto",
            "accountType": "Kontotyp",
            "companyIndividual": "Företag / Privatperson",
            "accountingFirm": "Redovisningsbyrå",
            "invoiceInterval": "Faktureringsintervall",
            "monthly": "Månadsvis",
            "yearly": "Årsvis",
            "organizationNumber": "Organisationsnummer",
            "companyName": "Företag/Organisation",
            "billingAddress": "Fakturaadress",
            "firstName": "Förnamn",
            "lastName": "Efternamn",
            "contactEmail": "Kontakt e-post",
            "invoiceEmail": "Faktura e-post",
            "termsConditions": "Jag godkänner",
            "termsOfUse": "Villkoren",
            "privacyPolicy": "Integritetspolicyn",
            "and": "och",
            "register": "Registrera",
            "currency_SEK": "kr",
            "perMonth": "per månad",
            "perYear": "per år",
            "exVat": "exkl. moms",
            "errorTerms": "Du måste godkänna villkoren och integritetspolicyn.",
            "your_referral_code": "Din referenskod",
            "share_link_earn_rewards": "Dela denna länk med dina vänner för att få en fri månad",
            "share_link_description": "När dina vänner registrerar sig med denna länk får du en fri månad av EkonomiAPI. Din vän får också 50% rabatt på sin första månad.",
            "Close": "Stäng",
            "referralTitle": "Du är inbjuden att spara med EkonomiAPI",
            "referralBy": "Du blev rekommenderad av {{referrerName}}!",
            "referralBonus": "Registrera dig nu och få {{bonusInfo}}.",
            "defaultBonus": "50% rabatt på första månaden",
            "referralPitchHeader": "Så här sparar du både tid och pengar:",
            "referralList1": "Mindre tid på administrativa uppgifter",
            "referralList1Description": "Uppgifter som brukade ta timmar kan nu slutföras på några minuter.",
            "referralList2": "Undvik kostsamma misstag",
            "referralList2Description": "Våra verktyg automatiserar processer och minskar risken för mänskliga fel.",
            "referralList3": "Ökad produktivitet",
            "referralList3Description": "Använd den tid du sparar till strategiska aktiviteter som genererar intäkter.",
            "referralList4": "Minska personalkostnader",
            "referralList4Description": "Våra verktyg tar hand om det tunga arbetet, vilket minskar dina personalutgifter.",
            "referralSubHeader": "Integrera med ditt arbetsflöde",
            "referralPara": "Våra verktyg integreras smidigt i ditt befintliga arbetsflöde för att spara tid och pengar.",
            "referralButton": "Registrera dig nu",
            "firstMonthDiscount": "50% rabatt på första månaden, därefter ",
            "Price": "Pris",
            "loading": "Laddar...",
            "error": "Fel",
            "exvat": "exkl. moms",
            "OriginalPrice": "Ordinarie pris",
            "Discount": "Rabatt",
            "NewTotal": "Nytt totalpris",
            "ThisAmountCoversFirstInvoiceYearly": "Detta belopp gäller första fakturan årsvis, därefter återgår priset till ordinarie pris",
            "ThisAmountCoversFirstInvoiceMonthly": "Detta belopp gäller första fakturan månadsvis, därefter återgår priset till ordinarie pris",
            "dashboard": "Panel",
            "SelectOutputFormat": "Välj utdataformat",
            "HandelsbankenTool": "Handelsbanken",
            "LansforsakringarTool": "Länsförsäkringar",
            "NordeaTool": "Nordea",
            "SveaEkonomiTool": "Svea Ekonomi",
            "SwedbankTool": "Swedbank och Sparbankerna",
            "PayPalTool": "PayPal",
            "MarginalenBankTool": "Marginalen Bank",
            "AvanzaTool": "Avanza Bank",
            "DanskeBankTool": "Danske Bank",
            "NetsTool": "Nets",
            "NetsEasyTool": "Nets Easy",
            "BamboraTool": "Bambora",
            "InAccount": "In-konto",
            "Series": "Bokföringsserie",
            "PleaseEnterMessage": "Vänligen ange ett meddelande",
            "BugReportedSuccessfully": "Buggen har rapporterats framgångsrikt",
            "ErrorReportingBug": "Fel vid rapportering av bugg",
            "ReportBug": "Rapportera en bugg",
            "BugMessage": "Buggrapport",
            "AttachFile": "Bifoga en fil",
            "SubmitBug": "Skicka rapport",
            "AmexTool": "Amex",
            "Serie": "Serie",
            "AmexToolDescription": "Konvertera en Amex-fil till ett format som kan importeras till ditt bokföringsprogram.",
            "CustomCsvBuilder": "Anpassad CSV-formatbyggare",
            "ChooseYourCsvColumns": "Välj nycklarna för dina anpassade CSV-kolumner. Du kan också välja 'Tom' för en tom kolumn.",
            "Column": "Kolumn",
            "Blank": "Tom",
            "SaveFormat": "Spara format",
            "Saving": "Sparar...",
            "FormatSavedSuccessfully": "Format sparades framgångsrikt!",
            "FailedToSaveFormat": "Misslyckades med att spara formatet. Försök igen.",
            "format_CUSTOM": 'Anpassad CSV-fil',
            "format_CSV": 'Fortnox CSV',
            "format_SIE": 'SIE-fil',
            "format_PDF": 'PDF-fil',
            "format_XLS": 'Excel-fil (xls)',
            "format_XLSX": 'Excel-fil (xlsx)',
            "format_BGMAX": 'BgMax-fil',
            "CustomFormatEditor": "Anpassa CSV-format",
            "Delimiter": "Avgränsare",
            "Comma": "Komma",
            "Semicolon": "Semikolon",
            "Tab": "Tabb",
            "DragDropOrClick": "Dra och släpp eller klicka här för att ladda upp",
            "rowNumber": "Radnummer",
            "clearingNumber": "Clearingnummer",
            "accountNumber": "Kontonummer",
            "product": "Produkt",
            "currency": "Valuta",
            "bookingDate": "Bokningsdatum",
            "transactionDate": "Transaktionsdatum",
            "valueDate": "Värdesdatum",
            "reference": "Referens",
            "description": "Beskrivning",
            "amount": "Belopp",
            "balance": "Saldo",
            "Active": "Aktiv",
            "Inactive": "Inaktiv",
            "Pending": "Väntande",
            "Company Management": "Företagshantering",
            "Your Companies": "Era företag",
            "Users": "Användare",
            "Edit": "Redigera",
            "Processing...": "Bearbetar...",
            "Update Company": "Uppdatera företag",
            "Edit Company": "Redigera företag",
            "Company Name": "Företagsnamn",
            "Organization Number": "Organisationsnummer",
            "Alias": "Alias",
            "Email Contact": "Kontakt e-post",
            "Alias is available.": "Aliaset är tillgängligt.",
            "Alias is not available.": "Aliaset är inte tillgängligt.",
            "Alias is already taken.": "Aliaset är redan taget.",
            "Error checking alias availability.": "Fel vid kontroll av alias tillgänglighet.",
            "Alias can only contain letters and numbers.": "Alias kan endast innehålla bokstäver och siffror.",
            "Alias must be at least 3 characters long.": "Alias måste vara minst 3 tecken långt.",
            "No companies added yet.": "Inga företag har lagts till ännu.",
            "Company updated!": "Företaget uppdaterades!",
            "Company created!": "Företaget skapades!",
            "BrowserNotSupported": "Din webbläsare stöds inte. Vänligen använd en modern webbläsare som Firefox eller Chrome kompatibel webbläsare.",
            "Buy Shares": "Köp andelar",
            "Enter number of shares": "Ange antal andelar",
            "Last Month Profit": "Förra månadens vinst",
            "Total Profit": "Total vinst",
            "Your Share": "Din andel",
            "Your Ownership": "Ditt ägande",
            "Loading profit data...": "Laddar vinstdata...",
            "Please enter a valid number of shares.": "Ange ett giltigt antal andelar.",
            "Purchase": "Köp",
            "Failed to purchase shares.": "Misslyckades med att köpa andelar.",
            "Shares Owned": "Ägda andelar",
            "Shares": "Andelar",
            "Share Price": "Andelspris",
            "Current Share Price": "Aktuellt andelspris",
            "Total Shares": "Totalt antal andelar",
            "Total Expenses": "Totala kostnader",
            "Scenario": "Scenario",
            "Your profit per month": "Din intäkt per månad",
            "Investment Value": "Investeringsvärde",
            "Months Until Profit": "Månader till vinst",
            "Payment History": "Betalningshistorik",
            "Date": "Datum",
            "Amount": "Belopp",
            "Status": "Status",
            "No payment history available.": "Ingen betalningshistorik tillgänglig.",
            "Monthly Profit": "Månadsvinst",
            "Month": "Månad",
            "Total Accumulated": "Totalt ackumulerat",
            "Your Share of Profit": "Din andel av vinsten",
            "You can withdraw funds once your accumulated balance reaches 1000 SEK.": "Du kan ta ut pengar när ditt ackumulerade saldo når 1000 SEK.",
            "Insufficient balance for withdrawal.": "Otillräckligt saldo för uttag.",
            "Successfully withdrawn": "Lyckades ta ut",
            "Failed to process withdrawal": "Misslyckades med att behandla uttag",
            "Withdrawal fee": "Uttagavgift",
            "Amount you will receive": "Belopp du kommer att få",
            "Withdraw Funds": "Ta ut pengar",
            "A reversal invoice will be created an a payment is issued within 30 days": "En revers faktura skapas och en betalning utfärdas inom 30 dagar",
            "The payment will be made to the bank account you have registered with us": "Betalningen kommer att göras till det bankkonto du har registrerat hos oss",
            "Processed": "Behandlade",
            "Processing Cost": "Behandlingskostnad",
            "SIE Processed": "Behandlade SIE mejl",
            "Body to PDF": "Brödtext till PDF",
            "Image to PDF": "Bild till PDF",
            "Spreadsheet to PDF": "Excel till PDF",
            "Usage charges": "Användningsavgifter",
            "Subscriptions": "Prenumerationer",
            "Usage this month": "Användning denna månad",
            "Admin Dashboard": "Adminpanel",
            "Total Users": "Totalt antal användare",
            "Total Groups": "Totalt antal företag",
            "Total Revenue": "Total intäkt",
            "Actions": "Åtgärder",
            "Total": "Totalt",
            "Revenue": "Intäkt",
            "Profit": "Vinst",
            "Admin Actions": "Adminåtgärder",
            "Add Expense": "Lägg till kostnad",
            "Description": "Beskrivning",
            "Category": "Kategori",
            "Incarnate as User By ID": "Inkarna som användare med ID",
            "Incarnate as User By Email": "Inkarna som användare med e-post",
            "Incarnate": "Inkarna",
            "Incarnate by User ID": "Inkarna med användar-ID",
            "Incarnate by Email": "Inkarna med e-post",
            "All rights reserved": "Alla rättigheter förbehållna",
            "New here? Click the tools button in the header to open navigation!": "Ny här? Klicka på verktygsknappen i sidhuvudet för att öppna navigeringen!",
            "Open Navigation": "Öppna navigering",
            "RegisterAccount": "Registrera Konto",
            "Step 1: Choose Account Type": "Steg 1: Välj Kontotyp",
            "Step 2: Company Information": "Steg 2: Företagsinformation",
            "Step 3: Contact Details": "Steg 3: Kontaktuppgifter",
            "AccountType": "Kontotyp",
            "InvoiceInterval": "Faktureringsintervall",
            "Company / Individual": "Företag / Privatperson",
            "AccountingFirm": "Redovisningsbyrå",
            "Monthly": "Månadsvis",
            "Yearly": "Årsvis",
            "Register": "Registrera",
            "You must accept the terms and conditions.": "Du måste acceptera villkoren för att fortsätta.",
            "ImportTool": "Importera",
            "This feature is currently disabled.": "Denna funktion är för närvarande inaktiverad.",
            "Price Breakdown": "Prisöversikt",
            "whatIsEkonomiAPI": "Vad är EkonomiAPI?",
            "ekonomiAPIDescription": "EkonomiAPI är en omfattande uppsättning verktyg som är utformade för att förenkla bokföring, automatisera finansiella uppgifter och optimera effektiviteten för småföretag och redovisningsproffs. Med sömlösa integrationer, automatiserad datahantering och realtidsinsikter sparar du tid och minskar fel. Oavsett om du hanterar fakturor, spårar utgifter eller sköter löner, hjälper EkonomiAPI dig att effektivisera dina ekonomiska processer utan krångel.",
            "referAFriend": "Bjud in en vän och få belöningar!",
            "referralDescription": "Dela din kod och din vän får 20% rabatt på sin första faktura upp till 300kr. Du får en gratis månad på ditt konto!",
            "copy": "Kopiera",
            "referralCopied": "Rekommendationskoden kopierad till urklipp!",
            "Free tools": "Fria verktyg",
            "Level 1 tools": "Nivå 1 Verktyg",
            "Level 2 tools": "Nivå 2 Verktyg",
            "Back to Ecommerce": "Tillbaka till e-handel",
            "Back to the main dashboard": "Tillbaka till huvudpanelen",
            "Back to ": "Tillbaka till ",
            "Picklist": "Plocklista",
            "Search orders...": "Sök beställningar...",
            "Reload Orders": "Ladda om beställningar",
            "Order": "Beställning",
            "Accepted": "Godkänd",
            "Packed": "Packad",
            "Shipped": "Skickad",
            "Delivered": "Levererad",
            "Products": "Produkter",

        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "sv", // Default language
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
