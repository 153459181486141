import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomFileUpload = ({ onFileSelect }) => {
    const [fileName, setFileName] = useState(null);
    const { t } = useTranslation(); // Assuming you're using i18n for translations

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            onFileSelect(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setFileName(file.name);
            onFileSelect(file);
        }
    };

    return (
        <div className="flex flex-col items-center w-full">
            {/* Hidden input field */}
            <input
                type="file"
                id="fileUpload"
                onChange={handleFileChange}
                className="hidden"
            />

            {/* Drag-and-Drop Area + Clickable Upload Button */}
            <label
                htmlFor="fileUpload"
                className="w-full max-w-md cursor-pointer flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-500 rounded-lg bg-gray-800 text-gray-300 hover:bg-gray-700 transition duration-200"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div className="flex flex-col items-center">
                    <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16V8a4 4 0 014-4h10a4 4 0 014 4v8m-5-6l-3 3m0 0l-3-3m3 3V4"></path>
                    </svg>
                    <p className="text-sm mt-2">{fileName ? `${t('SelectedFile')}: ${fileName}` : t('DragDropOrClick')}</p>
                </div>
            </label>
        </div>
    );
};

export default CustomFileUpload;
