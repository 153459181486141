import React, { useState } from "react";

const DrivingCostCalculator = () => {
    const [kilometers, setKilometers] = useState("");
    const [toll, setToll] = useState("");
    const [totalCost, setTotalCost] = useState(null);

    const shortDistanceReduction = 0.8; // 20% reduction for short distances
    const shortDistanceThreshold = 4; // Kilometers
    const fuelCostPerLiter = 55; // Pesos per liter
    const fuelCostAdditional = 35; // Pesos per liter
    const fuelEfficiency = 5; // Km per liter
    const driverMinCost = 100; // Pesos
    const driverCostPercentage = 0.8; // 75%
    const maintenanceCostPerKm = 5; // Pesos

    const calculateCost = () => {
        const kmValue = parseFloat(kilometers) || 0;
        const tollValue = parseFloat(toll) || 0;

        if (kmValue <= 0) {
            setTotalCost(null);
            return;
        }

        const fuelCost = (kmValue / fuelEfficiency) * (fuelCostPerLiter + fuelCostAdditional);
        const fuelCostAdditionalDisplay = (kmValue / fuelEfficiency) * fuelCostAdditional;
        const driverCost = Math.max(driverMinCost, fuelCost * driverCostPercentage);
        const maintenanceCost = kmValue * maintenanceCostPerKm;
        const total = fuelCost + driverCost + maintenanceCost + tollValue;

        setTotalCost({
            fuelCost: fuelCost.toFixed(2),
            fuelCostAdditional: fuelCostAdditionalDisplay.toFixed(2),
            driverCost: driverCost.toFixed(2),
            maintenanceCost: maintenanceCost.toFixed(2),
            toll: tollValue.toFixed(2),
            total: total.toFixed(2),
        });
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-900 text-white p-6">
            <div className="w-full max-w-lg bg-gray-800 p-6 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-4 text-center">🚗 Driving Cost Calculator</h1>

                {/* Input Fields */}
                <div className="mb-4">
                    <label className="block text-sm font-semibold mb-1">Enter Kilometers</label>
                    <input
                        type="number"
                        value={kilometers}
                        onChange={(e) => setKilometers(e.target.value)}
                        placeholder="Distance in km"
                        className="w-full p-2 border border-gray-600 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-semibold mb-1">Enter Toll Charges</label>
                    <input
                        type="number"
                        value={toll}
                        onChange={(e) => setToll(e.target.value)}
                        placeholder="Toll in pesos"
                        className="w-full p-2 border border-gray-600 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>

                {/* Calculate Button */}
                <button
                    onClick={calculateCost}
                    className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-white font-bold rounded transition duration-200"
                >
                    Calculate Cost
                </button>

                {/* Cost Breakdown Display */}
                {totalCost && (
                    <div className="mt-6 p-4 bg-gray-700 rounded-lg">
                        <h2 className="text-xl font-bold mb-2">💰 Cost Breakdown</h2>
                        <div className="text-sm text-gray-300">
                            <p>⛽ Fuel Cost: <span className="font-bold">{totalCost.fuelCost} pesos</span></p>
                            <p className="text-gray-400">(🔹 Includes: {totalCost.fuelCostAdditional} pesos in additional costs)</p>
                            <p>👨‍✈️ Driver Cost: <span className="font-bold">{totalCost.driverCost} pesos</span></p>
                            <p>🛠️ Maintenance Cost: <span className="font-bold">{totalCost.maintenanceCost} pesos</span></p>
                            <p>🚧 Toll Charges: <span className="font-bold">{totalCost.toll} pesos</span></p>
                            <h3 className="mt-4 text-lg font-bold text-green-400">🔹 Total: {totalCost.total} pesos</h3>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DrivingCostCalculator;
