import React from "react";
import { Link } from "react-router-dom";
import BackToDashboard from "../../components/BackToDashboard";

const EcommerceDashboard = () => {
    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">E-commerce Dashboard</h1>
            <nav className="mb-6">
                <ul className="flex space-x-4">
                    <li>
                        <Link to="/ecommerce/pick" className="text-blue-500 hover:underline">Picklist</Link>
                    </li>
                    <li>
                        <Link to="/ecommerce/orders" className="text-blue-500 hover:underline">Order Management</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default EcommerceDashboard;
