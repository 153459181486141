import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api';
import { useTranslation } from 'react-i18next';
import ToolFooter from '../../components/ToolFooter';
import FormatSelector from '../../components/FormatSelector';
import FileDownloadButton from '../../components/FileDownloadButton';
import CustomFileUpload from '../../components/CustomFileUpload'; // Custom File Upload Component

const Lansforsakringar = () => {
    const [file, setFile] = useState(null);
    const [convertedFile, setConvertedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFormat, setSelectedFormat] = useState('csv'); // Default format
    const { t } = useTranslation();

    useEffect(() => {
        const savedFormat = localStorage.getItem('selectedFormat');
        if (savedFormat) {
            setSelectedFormat(savedFormat);
        }

        // Apply dark mode if enabled
        const darkMode = localStorage.getItem('darkMode') === 'true';
        if (darkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, []);

    const apiDocumentation = {
        description: 'Convert a file from Länsförsäkringar to a format that can be imported into your bookkeeping software.',
        calls: {
            '/tools/lansforsakringar': 'POST'
        },
        post: {
            file: 'base64-encoded -- CSV file',
            format: 'string -- Desired output format'
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!file) {
            setError(t('PleaseSelectFile'));
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/tools/lansforsakringar', 'POST', {
                    file: base64File,
                    format: selectedFormat, // Include selected format
                });

                if (response.error) {
                    setError(response.error);
                } else {
                    setConvertedFile(response.convertedFile);
                }
            } catch (err) {
                setError(t('FailedToProcessFile'));
            }

            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-gray-900 text-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-4">{t('LansforsakringarTool')}</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <ol className="list-decimal">
                    <li className="mb-3">
                        {/* Format Selection Component */}
                        <FormatSelector selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} />
                    </li>
                    <li>
                        <label className="block text-sm font-medium text-gray-300">
                            {t('UploadFile')}
                        </label>
                        {/* Custom File Upload Component */}
                        <CustomFileUpload onFileSelect={setFile} />
                    </li>
                </ol>

                <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-indigo-600 text-white rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? t('Processing') : t('ConvertFile')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-500 text-sm">{error}</div>}

            {convertedFile && (
                <div className="mt-4">
                    <FileDownloadButton
                        convertedFile={convertedFile}
                        selectedFormat={selectedFormat}
                        buttonText={t('DownloadConvertedFile')}
                    />
                </div>
            )}

            {/* Footer with API documentation */}
            <ToolFooter apiDocumentation={apiDocumentation} />
        </div>
    );
};

export default Lansforsakringar;
