import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../utils/Api';

const InvestorPayments = ({ monthlyProfit }) => {
    const { t } = useTranslation();
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [accumulatedBalance, setAccumulatedBalance] = useState(0);
    const [message, setMessage] = useState('');
    const withdrawalFee = 50;
    const minWithdrawalAmount = 1000;

    // Simulate previous months' data (since no actual payments have been made yet)
    useEffect(() => {
        if (monthlyProfit > 0) {
            const simulatedData = [
                { month: 'Jan 2024', amount: monthlyProfit },
                { month: 'Feb 2024', amount: monthlyProfit },
                { month: 'Mar 2024', amount: 4000 },
            ];

            setPaymentHistory(simulatedData);

            // Calculate accumulated balance
            const totalAccumulated = simulatedData.reduce((acc, payment) => acc + payment.amount, 0);
            setAccumulatedBalance(totalAccumulated);
        }
    }, [monthlyProfit]);

    const handleWithdraw = async () => {
        if (accumulatedBalance < minWithdrawalAmount) {
            setMessage(t('Insufficient balance for withdrawal.'));
            return;
        }

        try {
            const amountToWithdraw = accumulatedBalance - withdrawalFee;
            const response = await apiRequest('/investor/withdraw', 'POST', { amount: amountToWithdraw });

            if (response.error) {
                setMessage(`❌ ${response.error}`);
            } else {
                setMessage(`✅ ${t('Successfully withdrawn')} ${amountToWithdraw.toFixed(2)} SEK`);
                setAccumulatedBalance(0); // Reset balance after withdrawal
                setPaymentHistory([]); // Reset history as all funds were withdrawn
            }
        } catch (err) {
            setMessage(`❌ ${t('Failed to process withdrawal')}`);
        }
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg">
            <h2 className="text-xl font-bold">{t('Payment History')}</h2>

            {/* Payment Table */}
            <table className="w-full border-collapse border border-gray-700 mt-2">
                <thead>
                <tr className="bg-gray-700">
                    <th className="border border-gray-600 p-2">{t('Month')}</th>
                    <th className="border border-gray-600 p-2 text-right">{t('Amount')}</th>
                </tr>
                </thead>
                <tbody>
                {paymentHistory.map((payment, index) => (
                    <tr key={index}>
                        <td className="border border-gray-600 p-2">{payment.month}</td>
                        <td className="border border-gray-600 p-2 text-right">{payment.amount.toFixed(2)} SEK</td>
                    </tr>
                ))}
                <tr className="font-bold">
                    <td className="border border-gray-600 p-2">{t('Total Accumulated')}</td>
                    <td className="border border-gray-600 p-2 text-right text-green-400">{accumulatedBalance.toFixed(2)} SEK</td>
                </tr>
                </tbody>
            </table>

            {/* Withdrawal Section */}
            <div className="mt-4 p-3 bg-gray-700 rounded">
                <p>{t('You can withdraw funds once your accumulated balance reaches 1000 SEK.')}</p>
                <p>{t('Withdrawal fee')}: 50 SEK</p>
                <p className="font-bold">
                    {t('Amount you will receive')}: {(accumulatedBalance >= minWithdrawalAmount ? accumulatedBalance - withdrawalFee : 0).toFixed(2)} SEK
                </p>
                <button
                    onClick={handleWithdraw}
                    disabled={accumulatedBalance < minWithdrawalAmount}
                    className={`mt-2 px-4 py-2 rounded ${
                        accumulatedBalance >= minWithdrawalAmount ? 'bg-green-500 text-white' : 'bg-gray-500 text-gray-300 cursor-not-allowed'
                    }`}
                >
                    {t('Withdraw Funds')}
                </button>
            </div>

            {message && <div className="mt-4 text-white">{message}</div>}

            <div className="mt-4 p-3 bg-gray-700 rounded">
                <p>
                    {t('A reversal invoice will be created an a payment is issued within 30 days')}.
                    &nbsp;
                    {t('The payment will be made to the bank account you have registered with us')}.
                </p>
            </div>
        </div>
    );
};

export default InvestorPayments;
