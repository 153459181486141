import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faCheckSquare,
    faSquare,
    faLock,
    faUnlock,
    faPlus,
    faCaretUp,
    faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import {apiRequest} from "../../utils/Api";

const categories = [
    "Dairy", "Fruit", "Vegetables", "Canned", "Meat", "Bakery", "Beverages", "Frozen", "Snacks", "Other"
];

const UI_SIZES = ["text-xs", "text-sm", "text-base", "text-lg", "text-xl"];

const SimpleShoppingList = () => {
    const { t } = useTranslation();
    const [shoppingList, setShoppingList] = useState({});
    const [uiSizeIndex, setUiSizeIndex] = useState(2);
    const [checkboxPosition, setCheckboxPosition] = useState("left");
    const [inputValues, setInputValues] = useState({});
    const [isLocked, setIsLocked] = useState(false);
    const [isLockedCategories, setIsLockedCategories] = useState(false);
    const [categories, setCategories] = useState([
        "Dairy", "Fruit", "Vegetables", "Canned", "Meat", "Seafood", "Bakery", "Beverages", "Frozen", "Snacks", "Other"
    ]);

    useEffect(() => {
        const fetchShoppingList = async () => {
            const response = await apiRequest("/user/shoppingList", "POST");
            if (response.status === "success") {
                setShoppingList(response.data);
                localStorage.setItem("shoppingList", JSON.stringify(response.data));
            }
        };
        fetchShoppingList();
    }, []);

    useEffect(() => {
        localStorage.setItem("shoppingList", JSON.stringify(shoppingList));
        apiRequest("/user/shoppingList", "POST", { shoppingList });
    }, [shoppingList]);

    const handleToggleCheck = (category, index, event, setMode) => {
        console.log("handleToggleCheck", category, index);
        setShoppingList(prevList => {
            const updatedList = { ...prevList };
            if (updatedList[category] && updatedList[category][index]) {
                console.log("Toggling checked status to ", !setMode);
                updatedList[category][index] = { ...updatedList[category][index], checked: !setMode };
            }
            return { ...updatedList };
        });
    };

    const handleAddItem = (category) => {
        if (!inputValues[category]) return;
        setShoppingList(prevList => {
            const updatedList = { ...prevList };
            if (!updatedList[category]) updatedList[category] = [];
            if (updatedList[category].find(item => item.name === inputValues[category])) return updatedList;
            updatedList[category].push({ name: inputValues[category], checked: false });
            return updatedList;
        });
        setInputValues(prev => ({ ...prev, [category]: "" }));
    };

    const handleDeleteItem = (category, index, event) => {
        event.stopPropagation();
        if (window.confirm(t("Are you sure you want to delete this item?"))) {
            setShoppingList(prevList => {
                const updatedList = { ...prevList };
                if (updatedList[category]) {
                    updatedList[category] = updatedList[category].filter((_, i) => i !== index);
                }
                return { ...updatedList };
            });
        }
    };

    const handleDeleteCategory = (category) => {
        if (window.confirm(t("Are you sure you want to delete this category?"))) {
            setShoppingList(prevList => {
                const updatedList = { ...prevList };
                delete updatedList[category];
                return { ...updatedList };
            });
            setCategories(categories.filter(cat => cat !== category));
        }
    }

    const handleLockCategory = () => {
        setIsLockedCategories(!isLockedCategories);
    }

    const handleAddCategory = () => {
        const newCategory = prompt(t("Enter new category name:"));
        if (newCategory && !categories.includes(newCategory)) {
            setCategories([...categories, newCategory]);
        }
    };

    const handleMoveCategory = (index, direction) => {
        const newCategories = [...categories];
        const swapIndex = index + direction;
        if (swapIndex >= 0 && swapIndex < newCategories.length) {
            [newCategories[index], newCategories[swapIndex]] = [newCategories[swapIndex], newCategories[index]];
            setCategories(newCategories);
        }
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <div className="flex space-x-2 mt-4 items-center justify-between">
                <h2 className="text-2xl font-bold">{t("Shopping List")}</h2>
                <button onClick={() => setIsLocked(!isLocked)} className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 rounded">
                    <FontAwesomeIcon icon={isLocked ? faUnlock : faLock} /> {t(isLocked ? "Unlock List" : "Lock List")}
                </button>
            </div>

            {categories.map((category, index) => (
                (!isLocked || (shoppingList[category] && shoppingList[category].length > 0)) && (
                    <div key={category} className="mt-4">
                        <div className="flex items-center justify-between">
                            <h3 className="text-lg font-semibold">{t(category)}</h3>
                            <div className="flex space-x-2">
                                {!isLockedCategories && (
                                    <>
                                    <button
                                        onClick={() => handleDeleteCategory(category)}
                                        className="px-4 py-2 bg-red-500 hover:bg-red-600 rounded"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button
                                        onClick={() => handleMoveCategory(index, -1)}
                                        className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
                                    >
                                        <FontAwesomeIcon icon={faCaretUp} className="cursor-pointer"  />
                                    </button>
                                    <button
                                        onClick={() => handleMoveCategory(index, 1)}
                                        className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
                                    >
                                        <FontAwesomeIcon icon={faCaretDown} className="cursor-pointer" />
                                    </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <ul className="mt-2">
                            {shoppingList[category]?.map((item, index) => (
                                <li key={index} className={`flex items-center justify-between p-2 border-b border-gray-700 cursor-pointer ${UI_SIZES[uiSizeIndex]}`}
                                    style={{ opacity: item.checked ? 0.5 : 1 }}
                                >
                                    {checkboxPosition === "left" ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon={item.checked ? faCheckSquare : faSquare}
                                                className="mr-2 cursor-pointer"
                                                onClick={(e) => handleToggleCheck(category, index, e, item.checked)}
                                            />
                                            <span className="flex-1 text-left cursor-pointer" onClick={(e) => handleToggleCheck(category, index, e, item.checked)}>{item.name}</span>
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="ml-4 text-red-500 cursor-pointer hover:text-red-700"
                                                onClick={(e) => handleDeleteItem(category, index, e)}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="mr-4 text-red-500 cursor-pointer hover:text-red-700"
                                                onClick={(e) => handleDeleteItem(category, index, e, item.checked)}
                                            />
                                            <span className="flex-1 text-left cursor-pointer" onClick={(e) => handleToggleCheck(category, index, e, item.checked)}>{item.name}</span>
                                            <FontAwesomeIcon
                                                icon={item.checked ? faCheckSquare : faSquare}
                                                className="ml-2 cursor-pointer"
                                                onClick={(e) => handleToggleCheck(category, index, e)}
                                            />
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                        {!isLocked && (
                            <div className="flex mt-2">
                                <input type="text" placeholder={t("Add item")} value={inputValues[category] || ""} onChange={(e) => setInputValues(prev => ({ ...prev, [category]: e.target.value }))} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white" />
                                <button onClick={() => handleAddItem(category)} className="ml-2 px-4 py-2 bg-green-500 hover:bg-green-600 rounded">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                        )}
                    </div>
                )
            ))}
            {!isLocked && (
                <div className="flex space-x-2 mt-4">
                    {isLockedCategories && (
                        <>
                            <button onClick={() => handleLockCategory()} className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 rounded w-full">
                                <FontAwesomeIcon icon={faLock} /> {t('Edit Categories')}
                            </button>
                        </>
                )}
                    {!isLockedCategories && (
                        <>
                            <button onClick={() => handleAddCategory()} className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 rounded w-1/2">
                                <FontAwesomeIcon icon={faPlus} /> {t('Add Category')}
                            </button>
                            <button onClick={() => handleLockCategory()} className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 rounded w-1/2">
                                <FontAwesomeIcon icon={faUnlock} /> {t('Exit Edit Mode')}
                            </button>
                        </>
                    )}
                </div>
            )}
            <div className="flex space-x-2 mt-4">
                <button onClick={() => setUiSizeIndex((prev) => (prev + 1) % UI_SIZES.length)} className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded w-1/2">
                    {t("Toggle UI Size")}
                </button>
                <button onClick={() => setCheckboxPosition(checkboxPosition === "left" ? "right" : "left")} className="px-4 py-2 bg-green-500 hover:bg-green-600 rounded w-1/2">
                    {t("Swap Checkbox & Trash")}
                </button>
            </div>
        </div>
    );
};

export default SimpleShoppingList;
