import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LoggedOutHeader = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', !darkMode);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <header className="p-4 bg-gray-800 text-white dark:bg-gray-900 flex justify-between items-center transition-all duration-300">
            <div className="logo">
                <a href="/" className="text-xl font-bold">EkonomiAPI</a>
            </div>
            <nav className="flex space-x-4">
                <button
                    onClick={() => navigate('/login')}
                    className="hover:text-gray-300 transition duration-200"
                >
                    Login
                </button>
                <button
                    onClick={() => changeLanguage('sv')}
                    className="hover:text-gray-300 transition duration-200"
                >
                    SV
                </button>
                <button
                    onClick={() => changeLanguage('en')}
                    className="hover:text-gray-300 transition duration-200"
                >
                    EN
                </button>
                <button
                    onClick={toggleDarkMode}
                    className="px-3 py-1 rounded bg-gray-700 hover:bg-gray-600 text-white transition duration-200"
                >
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </button>
            </nav>
        </header>
    );
};

export default LoggedOutHeader;
