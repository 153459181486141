import React from 'react';

const TaskCard = ({ task, onClick }) => (
    <div
        className="task-card p-3 bg-gray-100 rounded-lg shadow-sm cursor-pointer hover:bg-gray-200 transition-all"
        onClick={onClick}
    >
        <h5 className="font-medium text-gray-800">{task.title}</h5>
        <p className="text-sm text-gray-600 truncate">{task.description}</p>
    </div>
);

export default TaskCard;
