import React, { useState, useEffect } from "react";
import {format, addDays, subDays, parseISO, startOfMonth, endOfMonth, isAfter, isEqual, isBefore} from "date-fns";
import { apiRequest } from "../../utils/Api";

const SalaryTracker = () => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [calendar, setCalendar] = useState([]);
    const [amountPaid, setAmountPaid] = useState("");
    const [pendingUpdates, setPendingUpdates] = useState([]);

    useEffect(() => {
        fetchEmployees();
        const interval = setInterval(pushWorkdaysToServer, 10000);
        window.addEventListener("beforeunload", pushWorkdaysToServer); // Run before closing the page
        return () => {
            clearInterval(interval);
            window.removeEventListener("beforeunload", pushWorkdaysToServer);
        };
    }, []);

    // Fetch employees from API
    const fetchEmployees = async () => {
        try {
            const response = await apiRequest("/employees/employees", "GET");
            if (response.status === "success") {
                setEmployees(response.employees);
                if (response.employees.length > 0) {
                    selectEmployee(response.employees[0].id);
                }
            }
        } catch (error) {
            console.error("Failed to fetch employees", error);
        }
    };

    // Fetch workdays & payments for selected employee
    const selectEmployee = async (employeeId) => {
        try {
            const response = await apiRequest(`/employees/employee`, "POST", { employeeId });
            if (response.status === "success") {
                // Process workLog to keep only the latest status per date
                const workLogDict = {};
                response.employee.workLog.forEach((work) => {
                    workLogDict[work.workDate] = work.status; // Always overwrites with the latest status
                });

                setSelectedEmployee({ ...response.employee, workLog: workLogDict });
                generateCalendar({ ...response.employee, workLog: workLogDict });
            }
        } catch (error) {
            console.error("Failed to fetch employee details", error);
        }
    };

    // Generate calendar with workdays & payments
    const generateCalendar = (employee) => {
        const today = new Date();
        const firstDayOfMonth = startOfMonth(today);
        const lastDayOfMonth = endOfMonth(today);
        const start = subDays(firstDayOfMonth, 14);
        const end = addDays(lastDayOfMonth, 14);

        let paidCoverageEnd = null; // The last date covered by payment
        let paymentDates = new Set(); // Payment days with 💰 icon

        // **Sort payments chronologically before processing**
        const sortedPayments = [...(employee.payments || [])].sort((a, b) =>
            parseISO(a.paymentDate) - parseISO(b.paymentDate)
        );

        sortedPayments.forEach((payment) => {
            const payDate = parseISO(payment.paymentDate);
            paymentDates.add(format(payDate, "yyyy-MM-dd")); // Mark the payment date with 💰

            // **Fix: Ensure paid coverage extends forward properly**
            if (!paidCoverageEnd || isBefore(paidCoverageEnd, payDate)) {
                paidCoverageEnd = payDate;
            }

            for (let i = 0; i < payment.daysPaid; i++) {
                if (paidCoverageEnd) {
                    paidCoverageEnd = addDays(paidCoverageEnd, 1);
                }
            }
        });

        const days = [];
        for (let i = 0; i <= (end - start) / (1000 * 60 * 60 * 24); i++) {
            const date = addDays(start, i);
            const formattedDate = format(date, "yyyy-MM-dd");

            let status = employee.workLog?.[formattedDate] || "unmarked";
            let isPaid = paidCoverageEnd && isBefore(date, paidCoverageEnd);
            let isPaidFromTransaction = paymentDates.has(formattedDate);

            if (status === "absent") {
                isPaid = false;
                paidCoverageEnd = addDays(paidCoverageEnd, 1); // Move green line forward
            }

            days.push({
                date: formattedDate,
                dayName: format(date, "EEE"),
                monthYear: format(date, "MMMM yyyy"),
                status,
                isPaid,
                isPaidFromTransaction,
                hasGreenLine: isPaid && status !== "absent"
            });
        }

        setCalendar(days);
    };

    // Log a workday and store in Local Storage
    const cycleWorkStatus = (date) => {
        if (!selectedEmployee) return;

        const currentStatus = selectedEmployee.workLog?.[date] || "unmarked";
        const newStatus = currentStatus === "unmarked" ? "present" : currentStatus === "present" ? "absent" : "unmarked";

        // **Overwrite previous changes for the same date**
        const updatedPending = [
            ...pendingUpdates.filter((entry) => entry.date !== date),
            { date, status: newStatus }
        ];
        setPendingUpdates(updatedPending);
        localStorage.setItem("pendingWorkdays", JSON.stringify(updatedPending));

        // Update state instantly
        setSelectedEmployee((prev) => ({
            ...prev,
            workLog: { ...prev.workLog, [date]: newStatus }
        }));

        generateCalendar({ ...selectedEmployee, workLog: { ...selectedEmployee.workLog, [date]: newStatus } });
    };

    // Push pending workdays to server every 10 seconds
    const pushWorkdaysToServer = async () => {
        if (!selectedEmployee || pendingUpdates.length === 0) return;

        // Reduce updates to only the latest status per date
        const latestUpdates = pendingUpdates.reduce((acc, entry) => {
            acc[entry.date] = entry.status; // Overwrites older status with latest one
            return acc;
        }, {});

        const workdaysArray = Object.keys(latestUpdates).map(date => ({
            date,
            status: latestUpdates[date]
        }));

        try {
            await apiRequest("/employees/logWorkdays", "POST", {
                employeeId: selectedEmployee.id,
                workdays: workdaysArray
            }).then((response) => {
                if (response.status === "success") {
                    console.log("Workdays pushed successfully");
                }
                setPendingUpdates([]);
                localStorage.removeItem("pendingWorkdays");
            });
        } catch (error) {
            console.error("Failed to push workdays", error);
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white p-6">
            <h1 className="text-2xl font-bold text-center">Employee Salary Tracker</h1>

            {/* Employee Selection */}
            <div className="bg-gray-800 p-4 rounded-lg mt-6">
                <h2 className="text-lg font-semibold">Select Employee:</h2>
                <select
                    className="w-full p-2 bg-gray-700 rounded text-white"
                    onChange={(e) => selectEmployee(e.target.value)}
                    value={selectedEmployee?.id || ""}
                >
                    {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                            {employee.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Work Log */}
            {selectedEmployee && (
                <>
                    <div className="bg-gray-800 p-4 rounded-lg mt-6">
                        <h2 className="text-lg font-semibold">Work Log</h2>
                        {Object.entries(
                            calendar.reduce((acc, day) => {
                                acc[day.monthYear] = [...(acc[day.monthYear] || []), day];
                                return acc;
                            }, {})
                        ).map(([monthYear, days]) => (
                            <div key={monthYear} className="mt-4">
                                <h3 className="text-center text-lg font-bold bg-gray-700 p-2 rounded-lg">{monthYear}</h3>
                                <div className="grid grid-cols-7 gap-2 mt-2">
                                    {days.map((day) => (
                                        <div
                                            key={day.date}
                                            className={`p-3 rounded-lg text-center cursor-pointer transition relative ${
                                                day.status === "present" ? "bg-green-500" : day.status === "absent" ? "bg-red-500" : "bg-gray-700"
                                            }`}
                                            onClick={() => cycleWorkStatus(day.date)}
                                            style={{
                                                borderBottom: day.hasGreenLine ? "4px solid #00FF00" : "none"
                                            }}
                                        >
                                            <p className="text-sm">{day.dayName}</p>
                                            <p className="text-lg">{day.date.split("-")[2]}</p>
                                            {day.isPaidFromTransaction && <span className="absolute top-1 right-1 text-yellow-400">💰</span>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default SalaryTracker;
