import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../utils/Api";
import BackToDashboard from "../../components/BackToDashboard";
import { useTranslation } from "react-i18next";

const Picklist = () => {
    const [orders, setOrders] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = async () => {
        try {
            const response = await apiRequest("/ecommerce/orderList", "POST", {
                storeId: 1,
            });
            const groupedOrders = groupOrders(response);
            setOrders(groupedOrders);
        } catch (error) {
            console.error("Error loading orders", error);
        }
    };

    const groupOrders = (orderList) => {
        const orderMap = {};
        orderList.forEach((item) => {
            if (!orderMap[item.orderId]) {
                orderMap[item.orderId] = {
                    orderId: item.orderId,
                    orderNumber: item.orderNumber,
                    state: item.state,
                    stateColor: item.stateColor,
                    dateAdded: item.dateAdded,
                    products: [],
                };
            }
            orderMap[item.orderId].products.push({
                productId: item.productId,
                ean13: item.ean13,
                productName: item.productName,
                weight: item.weight,
                priceUnitIncl: item.priceUnitIncl,
                priceUnitExcl: item.priceUnitExcl,
                vatLevel: item.vatLevel,
                quantity: item.quantity,
                quantityReturned: item.quantityReturned,
            });
        });
        return Object.values(orderMap);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredOrders = orders.filter((order) =>
        (order.orderNumber + " " + order.state)
            .toLowerCase()
            .includes(searchTerm)
    );

    return (
        <div className="container mx-auto p-4 dark:bg-dark-primary dark:text-dark-text">
            <BackToDashboard customLink="/ecommerce" customText="Back to Ecommerce" />
            <h1 className="text-2xl font-bold mb-4">{t('Picklist')}</h1>

            <input
                type="text"
                className="border p-2 w-full mb-4 rounded dark:bg-dark-input dark:border-dark-border dark:text-dark-text"
                placeholder={`${t('Search orders...')}`}
                value={searchTerm}
                onChange={handleSearch}
            />

            <button
                className="w-full p-4 mb-4 rounded bg-dark-buttonPrimary text-dark-text hover:bg-dark-buttonPrimaryHover dark:bg-dark-buttonPrimary dark:hover:bg-dark-buttonPrimaryHover"
                onClick={loadOrders}
            >
                {t('Reload Orders')}
            </button>

            <div className="grid grid-cols-1 gap-4">
                {filteredOrders.map((order) => (
                    <div
                        key={order.orderId}
                        className="p-4 border rounded cursor-pointer bg-white dark:bg-dark-secondary dark:border-dark-border dark:text-dark-text"
                        onClick={() => navigate(`/ecommerce/pick-order/${order.orderId}`, { state: order })}
                    >
                        <p className="text-lg font-semibold">{t('Order')} #{order.orderNumber}</p>
                        <p className="font-medium" style={{ color: order.stateColor }}>
                            {t(order.state)}
                        </p>
                        <p className="text-sm text-gray-500 dark:text-gray-300">
                            {order.dateAdded}
                        </p>
                        <p className="mt-2 font-semibold">{t('Products')}:</p>
                        <ul className="list-disc pl-4 text-sm">
                            {order.products.map((product) => (
                                <li key={product.productId}>
                                    {product.quantity}x {product.productName}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Picklist;
