import React from 'react';

const NewsBox = ({ data }) => {
    return (
        <div className="bg-gray-800 p-4 shadow-lg rounded text-white">
            <h2 className="text-xl font-bold mb-2">{data.title}</h2>
            <p className="text-gray-300">{data.content}</p>
            <small className="text-gray-400">{new Date(data.date).toLocaleDateString()}</small>
        </div>
    );
};

export default NewsBox;
