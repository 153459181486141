import React, { useState } from "react";
import { apiRequest } from "../utils/Api";
import { useTranslation } from "react-i18next";
import BackToDashboard from "../components/BackToDashboard";

const AddPersonalDebt = () => {
    const { t } = useTranslation();
    const [description, setDescription] = useState("");
    const [daysToPay, setDaysToPay] = useState("");
    const [amountPerDay, setAmountPerDay] = useState("");
    const [message, setMessage] = useState("");

    const handleAddDebt = async () => {
        if (!description || !daysToPay || !amountPerDay) {
            setMessage(t("Please fill in all fields"));
            return;
        }

        try {
            const response = await apiRequest("/personalDebts/addDebt", "POST", {
                description,
                daysToPay: parseInt(daysToPay),
                amountPerDay: parseFloat(amountPerDay),
            });

            if (response.status === "success") {
                setMessage(`✅ ${t("Debt added successfully")}`);
                setDescription("");
                setDaysToPay("");
                setAmountPerDay("");
            } else {
                setMessage(`❌ ${response.message || t("Failed to add debt")}`);
            }
        } catch (error) {
            setMessage(`❌ ${t("Failed to connect to server")}`);
        }
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg">
            <BackToDashboard customText="Back to Debts" customLink="/my/debts" />
            <h2 className="text-xl font-bold">{t("Add New Debt")}</h2>

            <input
                type="text"
                placeholder={t("Description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border border-gray-700 rounded text-black mt-2"
            />

            <input
                type="number"
                placeholder={t("Total Days to Pay")}
                value={daysToPay}
                onChange={(e) => setDaysToPay(e.target.value)}
                className="w-full p-2 border border-gray-700 rounded text-black mt-2"
            />

            <input
                type="number"
                placeholder={t("Amount Per Day")}
                value={amountPerDay}
                onChange={(e) => setAmountPerDay(e.target.value)}
                className="w-full p-2 border border-gray-700 rounded text-black mt-2"
            />

            <button
                onClick={handleAddDebt}
                className="mt-4 px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg font-semibold"
            >
                {t("Add Debt")}
            </button>

            {message && <div className="mt-4 text-white">{message}</div>}
        </div>
    );
};

export default AddPersonalDebt;
