import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import BoardColumn from '../components/Kanban/BoardColumn';
import TaskModal from '../components/Kanban/TaskModal';
import { apiRequest } from '../utils/Api';

const KanbanBoard = () => {
    const [lists, setLists] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const boardsResponse = await apiRequest('/planner/boards', 'POST');
                if (boardsResponse.length > 0) {
                    const board = boardsResponse[0];
                    const listsArray = Object.entries(board.taskLists || {}).map(([title, tasks]) => ({
                        id: `${board.id}-${title}`,
                        title,
                        tasks,
                    }));
                    setLists(listsArray);
                }
            } catch (error) {
                console.error('Failed to fetch board data', error);
            }
        }
        fetchData();
    }, []);

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };

    const handleAddList = () => {
        const newList = {
            id: `new-list-${Date.now()}`,
            title: 'New List',
            tasks: [],
        };
        setLists([...lists, newList]);
    };

    const onDragEnd = (result) => {
        const { source, destination, type } = result;
        if (!destination) return;

        if (type === 'list') {
            const newLists = Array.from(lists);
            const [movedList] = newLists.splice(source.index, 1);
            newLists.splice(destination.index, 0, movedList);
            setLists(newLists);
            return;
        }

        if (type === 'card') {
            if (destination.droppableId === 'add-list') {
                const sourceListIndex = lists.findIndex(list => list.id === source.droppableId);
                if (sourceListIndex === -1) return;
                const sourceList = { ...lists[sourceListIndex] };
                const [movedCard] = sourceList.tasks.splice(source.index, 1);
                const newList = {
                    id: `new-list-${Date.now()}`,
                    title: 'New List',
                    tasks: [movedCard],
                };
                const newLists = lists.map(list =>
                    list.id === source.droppableId ? { ...list, tasks: sourceList.tasks } : list
                );
                newLists.push(newList);
                setLists(newLists);
                return;
            } else {
                const sourceListIndex = lists.findIndex(list => list.id === source.droppableId);
                const destinationListIndex = lists.findIndex(list => list.id === destination.droppableId);
                if (sourceListIndex === -1 || destinationListIndex === -1) return;
                const sourceList = { ...lists[sourceListIndex] };
                const destinationList = { ...lists[destinationListIndex] };
                const [movedCard] = sourceList.tasks.splice(source.index, 1);
                destinationList.tasks.splice(destination.index, 0, movedCard);
                const newLists = [...lists];
                newLists[sourceListIndex] = sourceList;
                newLists[destinationListIndex] = destinationList;
                setLists(newLists);
                return;
            }
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex flex-row h-screen">
                <div className="flex-1 p-4 overflow-hidden">
                    <Droppable droppableId="board" type="list" direction="horizontal">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="kanban-board flex space-x-4 h-full overflow-x-auto"
                            >
                                {lists.map((list, index) => (
                                    <Draggable key={list.id} draggableId={list.id} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="board-column-container"
                                            >
                                                <BoardColumn
                                                    list={list}
                                                    dragHandleProps={provided.dragHandleProps}
                                                    onTaskClick={handleTaskClick}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <div className="add-list-column min-w-[300px] bg-gray-100 p-4 rounded-lg shadow-md hidden md:block">
                                    <Droppable droppableId="add-list" type="card">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className="add-list-target flex items-center justify-center h-full"
                                            >
                                                <p className="text-gray-600">Add another list</p>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>

            <div className="fixed bottom-4 right-4 md:hidden">
                <button
                    onClick={handleAddList}
                    className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg"
                >
                    Add List
                </button>
            </div>

            {selectedTask && (
                <TaskModal task={selectedTask} onClose={() => setSelectedTask(null)} />
            )}
        </DragDropContext>
    );
};

export default KanbanBoard;
