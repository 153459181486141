import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BackToDashboard = ({ customText = "Back to dashboard", customLink = "/dashboard", autoSense = false }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBack = () => {
        if (autoSense && !customText && !customLink) {
            navigate(-1); // Go to the previous page in history
        }
    };

    return autoSense && !customText && !customLink ? (
        <div className="mb-4 flex items-center space-x-2 cursor-pointer px-4 py-2 rounded-lg" onClick={handleBack}>
            <i className="fas fa-arrow-left text-customOrange"></i>
            <span className="text-customOrange hover:text-customDarkBrown underline text-lg">
                {t("Back")}
            </span>
        </div>
    ) : (
        <div className="mb-4 flex items-center space-x-2 px-4 py-2 rounded-lg">
            <i className="fas fa-arrow-left text-customOrange"></i>
            <Link
                to={customLink}
                className="text-customOrange hover:text-customDarkBrown underline text-lg"
            >
                {t(customText)}
            </Link>
        </div>
    );
};

export default BackToDashboard;
