import React from 'react';
import { useTranslation } from 'react-i18next';
import DigitalOceanServices from "../../components/DigitalOceanServices";
import HostedSites from "../../components/HostedSites";
import QueueTask from "../../components/QueueTask";

const CustomerManagementPanel = () => {
    const { t } = useTranslation();

    return (
        <div className="p-4 bg-gray-900 min-h-screen text-white">
            <h1 className="text-2xl font-bold text-center">{t('Customer Management')}</h1>

            {/* DigitalOcean Services */}
            <div className="md:col-span-3 bg-gray-800 p-4 rounded-lg">
                <DigitalOceanServices />
            </div>

            {/* Hosted Sites */}
            <HostedSites />

            {/* Queue Tasks */}
            <QueueTask />
        </div>
    );
};

export default CustomerManagementPanel;
