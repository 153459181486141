import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfitBox = ({ data }) => {
    const { t } = useTranslation();

    if (!data) return <p>{t('Loading profit data...')}</p>;

    return (
        <div>
            <h2 className="text-xl font-bold">{t('Last Month Profit')}</h2>
            <p>{t('Total Revenue')}: {data.totalRevenue} SEK</p>
            <p>{t('Total Expenses')}: {data.totalExpenses} SEK</p>
            <p>{t('Total Profit')}: {data.netProfit} SEK</p>
            <p>{t('Your Share')}: {data.userProfit} SEK</p>
        </div>
    );
};

export default ProfitBox;
