import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// Constants for available formats
let AVAILABLE_FORMATS;
if (localStorage.getItem('customFormat')) {
    AVAILABLE_FORMATS = ['custom', 'csv', 'sie', 'bgmax', 'pdf', 'xls', 'xlsx'];
} else {
    AVAILABLE_FORMATS = ['csv', 'sie', 'bgmax', 'pdf', 'xls', 'xlsx'];
}

const FormatSelector = ({ selectedFormat, setSelectedFormat }) => {
    const { t } = useTranslation();

    // On component mount, check for the last selected format in localStorage
    useEffect(() => {
        const savedFormat = localStorage.getItem('selectedFormat');
        if (savedFormat && AVAILABLE_FORMATS.includes(savedFormat)) {
            setSelectedFormat(savedFormat);
        }
    }, [setSelectedFormat]);

    // Handle change in the format selection
    const handleFormatChange = (e) => {
        const newFormat = e.target.value;
        setSelectedFormat(newFormat);
        localStorage.setItem('selectedFormat', newFormat); // Save the selected format in localStorage
    };

    return (
        <div>
            <label htmlFor="format" className="block text-sm font-medium text-gray-300">
                {t('SelectOutputFormat')}
                <Link to="/csvbuilder" className="text-blue-400 hover:text-blue-300 hover:underline ml-1">
                    <small className="text-gray-400"> ({t('CustomFormatEditor')}) <i className="fa-solid fa-arrow-up-right-from-square"></i></small>
                </Link>
            </label>
            <select
                id="format"
                value={selectedFormat}
                onChange={handleFormatChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-600 bg-gray-700 text-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
                {AVAILABLE_FORMATS.map((format) => (
                    <option key={format} value={format}>
                        {t('format_' + format.toUpperCase())}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default FormatSelector;
