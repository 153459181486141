import React, { useState } from 'react';
import { apiRequest } from '../utils/Api';
import { useTranslation } from 'react-i18next';
import CustomFileUpload from '../components/CustomFileUpload';

const FileImporter = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [unmatchedUsers, setUnmatchedUsers] = useState([]);
    const [totalPosts, setTotalPosts] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const { t } = useTranslation();

    const handleFileSelect = (selectedFile) => {
        setFile(selectedFile);
        setError(null);
        setSuccessMessage('');
        setUnmatchedUsers([]);
        setTotalPosts(0);
        setTotalUsers(0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setError(t('PleaseSelectFile'));
            return;
        }

        setLoading(true);
        const reader = new FileReader();

        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/Import/fileUpload', 'POST', {
                    file: base64File,
                });

                if (response.status === 'error') {
                    setError(response.message);
                } else {
                    setSuccessMessage(t('FileUploadedSuccessfully'));
                    setUnmatchedUsers(response.unmatched);
                    setTotalPosts(response.totalPosts);
                    setTotalUsers(response.totalUsers);
                }
            } catch (err) {
                setError(t('FailedToUploadFile'));
            } finally {
                setLoading(false);
            }
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
            <div className="w-full max-w-lg p-6 bg-gray-800 shadow-lg rounded-lg">
                <h1 className="text-2xl font-bold text-center mb-4">{t('ImportTool')}</h1>

                {/* Integrated Custom File Upload Component */}
                <CustomFileUpload onFileSelect={handleFileSelect} />

                <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                    <button
                        type="submit"
                        className={`w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md font-semibold transition duration-300 ease-in-out ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="flex items-center justify-center">
                                <span className="loader mr-2"></span> {t('Uploading')}
                            </span>
                        ) : (
                            t('UploadFile')
                        )}
                    </button>
                </form>

                {/* Error Message */}
                {error && <div className="mt-4 text-red-400 text-center text-sm">{error}</div>}

                {/* Success Message */}
                {successMessage && <div className="mt-4 text-green-400 text-center text-sm">{successMessage}</div>}

                {/* Display Summary */}
                {totalPosts > 0 && totalUsers > 0 && (
                    <div className="mt-4 bg-gray-700 p-4 rounded-md">
                        <p className="text-gray-300">{t('TotalPosts')}: <span className="font-bold">{totalPosts}</span></p>
                        <p className="text-gray-300">{t('TotalUsers')}: <span className="font-bold">{totalUsers}</span></p>
                    </div>
                )}

                {/* Unmatched Users */}
                {unmatchedUsers.length > 0 && (
                    <div className="mt-4 bg-gray-700 p-4 rounded-md">
                        <h2 className="text-lg font-semibold text-gray-300">{t('UnmatchedUsers')}</h2>
                        <ul className="mt-2 space-y-1">
                            {unmatchedUsers.map((user) => (
                                <li key={user.wpId} className="text-gray-400">
                                    {user.displayName} ({user.email})
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileImporter;
