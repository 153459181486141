import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {apiRequest} from '../../utils/Api';

// Import components
import ExpensesTable from '../../components/ExpensesTable';
import AdminActions from '../../components/Admin/AdminActions';
import AdminIncarnate from '../../components/Admin/AdminIncarnate';

const AdminPanel = () => {
    const {t} = useTranslation();
    const [adminData, setAdminData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const data = await apiRequest('/Admin/dashboard', 'POST');
                setAdminData(data);
            } catch (err) {
                setError(t('Failed to load admin data'));
            }
            setLoading(false);
        };

        fetchAdminData();
    }, [t]);

    if (loading) return <div className="text-white">Loading...</div>;
    if (error) return <div className="text-red-400 font-bold">{error}</div>;

    return (
        <div className="p-4 bg-gray-900 min-h-screen text-white grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Admin Summary */}
            <div className="md:col-span-1 bg-gray-800 p-4 rounded-lg">
                <h2 className="text-xl font-bold">{t('Admin Dashboard')}</h2>
                <p>{t('Total Users')}: {adminData.totalUsers}</p>
                <p>{t('Total Groups')}: {adminData.totalGroups}</p>
                <p>{t('Total Revenue')}: {adminData.totalRevenue} SEK</p>
            </div>

            <div className="md:col-span-1 bg-gray-800 p-4 rounded-lg">
                <h2 className="text-xl font-bold">{t('Usage this month')}</h2>
                <table className="w-full border-collapse">
                    <tbody>
                    <tr>
                        <td className="text-left">{t('Usage charges')} (SEK):</td>
                        <td className="text-right">{adminData.usage.totalCost}</td>
                    </tr>
                    <tr>
                        <td className="text-left">{t('Subscriptions')} (SEK):</td>
                        <td className="text-right">{adminData.subscription.revenue}</td>
                    </tr>
                    </tbody>
                </table>
                <br />
                <table className="w-full border-collapse">
                    <thead>
                    <tr>
                        <th className="text-left">{t('Mails')}</th>
                        <th className="text-right">{adminData.mail.periodStart} - {adminData.mail.periodEnd}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="text-left">{t('Processed')}:</td>
                        <td className="text-right">{adminData.mail.mailCount}</td>
                    </tr>
                    <tr>
                        <td className="text-left">{t('Processing Cost')}:</td>
                        <td className="text-right">{adminData.mail.mailCost}</td>
                    </tr>
                    <tr>
                        <td className="text-left">{t('SIE Processed')}:</td>
                        <td className="text-right">{adminData.mail.sieProcessed}</td>
                    </tr>
                    <tr>
                        <td className="text-left">{t('Body to PDF')}:</td>
                        <td className="text-right">{adminData.mail.bodyToPdf}</td>
                    </tr>
                    <tr>
                        <td className="text-left">{t('Image to PDF')}:</td>
                        <td className="text-right">{adminData.mail.imageToPdf}</td>
                    </tr>
                    <tr>
                        <td className="text-left">{t('Spreadsheet to PDF')}:</td>
                        <td className="text-right">{adminData.mail.spreadsheetToPdf}</td>
                    </tr>
                    </tbody>
                </table>
            </div>


            {/* Expenses Table */}
            <div className="md:col-span-2 bg-gray-800 p-4 rounded-lg">
                <ExpensesTable expenses={adminData.expenses} total={adminData.totalExpenses} revenue={adminData.totalRevenue}/>
            </div>

            {/* Admin Actions */
            }
            <div className="md:col-span-3 bg-gray-800 p-4 rounded-lg">
                <AdminActions/>
            </div>

            {/* Incarnate Functionality */
            }
            <div className="md:col-span-3 bg-gray-800 p-4 rounded-lg">
                <AdminIncarnate/>
            </div>
        </div>
    )
        ;
};

export default AdminPanel;
