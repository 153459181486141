import React, { useState, useEffect } from 'react';

const computers = [
    { id: 1, name: 'Intel 8088', cost: 15, modifier: 1.002, mhz: 5, upgrades: 1 },
    { id: 2, name: 'Intel 286', cost: 25, modifier: 1.008, mhz: 10, upgrades: 1 },
    { id: 3, name: 'Amiga 500', cost: 20, modifier: 1.005, mhz: 7, upgrades: 1 },
    { id: 4, name: 'Atari 520ST', cost: 20, modifier: 1.005, mhz: 8, upgrades: 1 },
    { id: 5, name: 'Commodore C-64', cost: 10, modifier: 1.001, mhz: 1, upgrades: 0 },
    { id: 6, name: 'Altair 8800', cost: 0, modifier: 1.0, mhz: 2, upgrades: 0 },
    { id: 7, name: 'Intel 486', cost: 35, modifier: 1.01, mhz: 25, upgrades: 1 },
    { id: 8, name: 'Intel Pentium', cost: 50, modifier: 1.015, mhz: 66, upgrades: 2 },
    { id: 9, name: 'Intel Pentium II', cost: 60, modifier: 1.019, mhz: 333, upgrades: 2 },
    { id: 10, name: 'AMD K6', cost: 60, modifier: 1.02, mhz: 366, upgrades: 1 },
];

// Define console skins based on computer ID
const consoleSkins = {
    5: {
        cpuskintop: {
            background: '#4342e8',
            fontWeight: 'bold',
            color: '#a5a5ff',
            border: '12px solid #a5a5ff',
            borderRadius: '5px 5px 0px 0px',
            fontFamily: "'Courier New', Courier, monospace",
        },
        cpuskinbot: {
            background: '#4342e8',
            fontWeight: 'bold',
            color: '#a5a5ff',
            border: '12px solid #a5a5ff',
            borderRadius: '0px 0px 5px 5px',
            fontFamily: "'Courier New', Courier, monospace",
        },
    },
    6: {
        cpuskintop: {
            background: '#000',
            fontWeight: 'bold',
            color: 'green',
            border: '5px solid gray',
            borderRadius: '5px 5px 0px 0px',
            fontFamily: "'Courier New', Courier, monospace",
        },
        cpuskinbot: {
            background: '#000',
            fontWeight: 'bold',
            color: 'green',
            border: '5px solid gray',
            borderRadius: '0px 0px 5px 5px',
            fontFamily: "'Courier New', Courier, monospace",
        },
    },
    default: {
        cpuskintop: {
            background: '#000',
            fontWeight: 'bold',
            color: '#c0c0c0',
            border: '3px solid #c0c0c0',
            borderRadius: '2px 2px 0px 0px',
            fontFamily: "'Courier New', Courier, monospace",
        },
        cpuskinbot: {
            background: '#000',
            fontWeight: 'bold',
            color: '#c0c0c0',
            border: '3px solid #c0c0c0',
            borderRadius: '0px 0px 2px 2px',
            fontFamily: "'Courier New', Courier, monospace",
        },
    },
};


const HackGame = () => {
    const [currentComputer, setCurrentComputer] = useState(computers.find((comp) => comp.id === 6)); // Default: Altair 8800

    const [session, setSession] = useState(() => JSON.parse(localStorage.getItem('hack_session')) || null);
    const [history, setHistory] = useState(() => JSON.parse(localStorage.getItem('hack_history')) || []);
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const [points, setPoints] = useState(() => JSON.parse(localStorage.getItem('hack_points')) || 0);
    const [successRate, setSuccessRate] = useState(() => JSON.parse(localStorage.getItem('hack_success_rate')) || 50); // Base success rate is 50%

    const currentSkin = consoleSkins[currentComputer.id] || consoleSkins.default;

    useEffect(() => {
        if (!session) {
            // Initialize a new session if none exists
            const newSession = { sessionId: Date.now(), lastActionDate: Date.now(), hacks: 0 };
            localStorage.setItem('hack_session', JSON.stringify(newSession));
            setSession(newSession);
        }
    }, [session]);

    useEffect(() => {
        localStorage.setItem('hack_points', JSON.stringify(points));
        localStorage.setItem('hack_success_rate', JSON.stringify(successRate));
    }, [points, successRate]);

    const addToHistory = (content) => {
        const newHistory = [...history, { content, actionDate: Date.now() }];
        setHistory(newHistory);
        localStorage.setItem('hack_history', JSON.stringify(newHistory));
    };

    const clearHistory = () => {
        setHistory([]); // Clear history in state
        setOutput(''); // Clear output message
        localStorage.removeItem('hack_history'); // Remove history from local storage
        document.getElementById('history').innerHTML = ''; // Clear history in the DOMx
    };

    const grantPoints = (amount) => {
        setPoints(points + amount);
    };

    const attemptHack = () => {
        const roll = Math.random() * 100;
        return roll <= successRate;
    };

    const handleCommand = (command) => {
        let response = '';
        const commands = command.split(' ');
        const mainCommand = commands[0].toUpperCase();
        const argument = commands.slice(1).join(' ');

        switch (mainCommand) {
            case 'START':
                if (session?.hacks > 0) {
                    response = '> START\nYou already have a session in progress!';
                } else {
                    response = '> START\nDo you want to play a game?\nEnter HELP for a list of commands.';
                    setSession({ ...session, hacks: 0 });
                }
                break;

            case 'HELP':
                response = `> HELP\nAvailable commands:\nSTART, HELP, HACK [user/random], TRACE HACKER, STATUS, LIST, SHUTDOWN, COMPINFO, COMPLIST, UPGRADES, CHANGE [id], CLEAR, CLS.`;
                break;

            case 'HACK':
                if (!argument) {
                    response = '> HACK\nYou need to specify a target to hack.';
                } else {
                    const target = argument.toUpperCase() === 'RANDOM' ? 'a random user' : argument;
                    const success = attemptHack();

                    if (success) {
                        response = `> HACK ${target}\nAttempting to hack ${target}...\nSuccess! You gained 10 points!`;
                        grantPoints(10);
                        setSession({ ...session, hacks: (session.hacks || 0) + 1 });
                    } else {
                        response = `> HACK ${target}\nAttempting to hack ${target}...\nHack failed. Try upgrading your system.`;
                    }
                }
                break;

            case 'TRACE':
                if (argument.toUpperCase() === 'HACKER') {
                    response = '> TRACE HACKER\nTracing the hacker...\nSuccess! You identified the hacker!';
                } else {
                    response = '> TRACE\nInvalid TRACE command. Did you mean TRACE HACKER?';
                }
                break;

            case 'STATUS':
                response = `> STATUS\nSession Active.\nHacks completed: ${session.hacks || 0}.\nTotal Points: ${points}\nCurrent Success Rate: ${successRate}%`;
                break;

            case 'LIST':
                response = '> LIST\nAvailable users: Alice, Bob, Charlie, Eve.';
                break;

            case 'SHUTDOWN':
                response = '> SHUTDOWN\nCannot shut down the system. Access denied.';
                break;

            case 'UPGRADES':
                response = '> UPGRADES\nAvailable upgrades:\n  1. Faster CPU (10 points, +10% success rate)\n  2. More RAM (5 points, +5% success rate).\nUse CHANGE [id] to purchase an upgrade.';
                break;

            case 'COMPINFO':
                response = `> COMPINFO\nCurrent Computer: ${currentComputer.name}\nModifier: ${currentComputer.modifier}\nMHz: ${currentComputer.mhz}\nMax Upgrades: ${currentComputer.upgrades}`;
                break;

            case 'CHANGE':
                if (!argument || isNaN(argument)) {
                    response = '> CHANGE\nYou need to specify a valid computer ID.';
                } else {
                    const selectedComputer = computers.find((comp) => comp.id === parseInt(argument, 10));
                    if (!selectedComputer) {
                        response = '> CHANGE\nInvalid computer ID.';
                    } else if (points < selectedComputer.cost) {
                        response = `> CHANGE\nInsufficient points. You need ${selectedComputer.cost} points to buy ${selectedComputer.name}.`;
                    } else {
                        setPoints(points - selectedComputer.cost);
                        setCurrentComputer(selectedComputer);
                        response = `> CHANGE\nYou have successfully switched to ${selectedComputer.name}.`;
                    }
                }
                break;

            case 'CLEAR':
                clearHistory();
                response = '> CLEAR\nConsole cleared.';
                break;

            case 'CLS':
                clearHistory();
                response = '> CLS\nConsole cleared.';
                break;

            default:
                response = `> ${mainCommand}\nUnknown command.`;
                break;
        }

        // Add to history and display response
        addToHistory(response);
        setOutput(response);
    };

    const handleInput = (e) => {
        if (e.key === 'Enter') {
            handleCommand(input.trim());
            setInput('');
        }
    };

    const formatOutput = (text) => {
        if (typeof text !== 'string') {
            return null; // Return nothing for invalid input
        }
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="h-[calc(100vh-64px)] flex flex-col">
            {/* Console Output */}
            <div
                id="console"
                className="flex-1 overflow-y-auto p-4"
                style={{ ...currentSkin.cpuskintop, overflowY: 'auto', textAlign: 'left' }}
            >
                {history.map((entry, index) => (
                    <p key={index}>{formatOutput(entry)}</p>
                ))}
            </div>

            {/* Console Input */}
            <div
                className="border-t border-gray-700"
                style={{ ...currentSkin.cpuskinbot }}
            >
                <input
                    id="input"
                    className="w-full p-4"
                    style={{
                        background: '#333',
                        color: '#fff',
                        border: 'none',
                        outline: 'none',
                    }}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleInput}
                    placeholder="Type a command..."
                />
            </div>
        </div>
    );
};

export default HackGame;
