import React from 'react';

// Component to handle the file download based on the selected format and base64 data
const FileDownloadButton = ({ convertedFile, selectedFormat, buttonText }) => {

    const handleDownload = () => {
        if (!convertedFile) return;

        let mimeType = 'text/csv'; // Default MIME type
        switch (selectedFormat) {
            case 'csv':
                mimeType = 'text/csv';
                break;
            case 'xls':
            case 'xlsx':
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            case 'sie':
            case 'bgmax':
                mimeType = 'text/plain';
                break;
            default:
                mimeType = 'text/plain'; // Fallback to plain text if format is not recognized
        }

        const a = document.createElement('a');
        a.href = `data:${mimeType};base64,${convertedFile}`;
        a.download = `converted_file.${selectedFormat}`; // Dynamic filename with selected format
        a.click();
    };

    return (
        <button
            className={`w-full py-2 px-4 rounded-md transition duration-200 
                ${convertedFile ? 'bg-green-600 hover:bg-green-500 text-white' : 'bg-gray-600 text-gray-400 cursor-not-allowed opacity-50'}`}
            onClick={handleDownload}
            disabled={!convertedFile}
        >
            {buttonText}
        </button>
    );
};

export default FileDownloadButton;
