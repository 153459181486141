import React from 'react';
import { useTranslation } from 'react-i18next';

const ExpensesTable = ({ expenses, total, revenue }) => {
    const { t } = useTranslation();

    if (!expenses || expenses.length === 0) {
        return <p className="text-gray-400">{t('No expenses recorded')}</p>;
    }

    // Format numbers to 2 decimal places
    const formatAmount = (amount) => parseFloat(amount).toFixed(2);

    return (
        <table className="w-full border-collapse border border-gray-700">
            <thead>
            <tr className="bg-gray-700">
                <th className="border border-gray-600 p-2">{t('Description')}</th>
                <th className="border border-gray-600 p-2">{t('Amount')}</th>
                <th className="border border-gray-600 p-2">{t('Actions')}</th>
            </tr>
            </thead>
            <tbody>
            {expenses.map((expense) => (
                <tr key={expense.id}>
                    <td className="border border-gray-600 p-2">
                        {expense.description}
                        <br />
                        <span className="text-gray-400">{expense.category}</span>
                    </td>
                    <td className="border border-gray-600 p-2 text-right">{formatAmount(expense.amount)}</td>
                    <td className="border border-gray-600 p-2 text-center">
                        <button className="bg-blue-500 px-2 py-1 rounded text-white mr-2">
                            <i className="fas fa-edit" />
                        </button>
                        <button className="bg-red-500 px-2 py-1 rounded text-white">
                            <i className="fas fa-trash" />
                        </button>
                    </td>
                </tr>
            ))}
            <tr>
                <td className="border border-gray-600 p-2 font-bold">{t('Total')}</td>
                <td className="border border-gray-600 p-2 text-right font-bold">{formatAmount(total)}</td>
                <td className="border border-gray-600 p-2">SEK</td>
            </tr>
            <tr>
                <td className="border border-gray-600 p-2 font-bold">{t('Revenue')}</td>
                <td className="border border-gray-600 p-2 text-right font-bold">{formatAmount(revenue)}</td>
                <td className="border border-gray-600 p-2">SEK</td>
            </tr>
            <tr>
                <td className="border border-gray-600 p-2 font-bold">{t('Profit')}</td>
                <td className="border border-gray-600 p-2 text-right font-bold">
                    {formatAmount(revenue - total)}
                </td>
                <td className="border border-gray-600 p-2">SEK</td>
            </tr>
            </tbody>
        </table>
    );
};

export default ExpensesTable;
