import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import TaskCard from './TaskCard';

const BoardColumn = ({ list, onTaskClick, dragHandleProps }) => {
    return (
        <div className="board-column bg-white p-4 rounded-lg shadow-md min-w-[300px]">
            <div className="column-header" {...dragHandleProps}>
                <h3 className="text-xl font-semibold mb-4 text-gray-700">{list.title}</h3>
            </div>
            <Droppable droppableId={list.id} type="card">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="tasks-list space-y-2"
                    >
                        {list.tasks.map((task, index) => (
                            <Draggable key={task.id} draggableId={task.id} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <TaskCard task={task} onClick={() => onTaskClick(task)} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default BoardColumn;
