import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheckSquare, faSquare, faTrash, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { apiRequest } from "../utils/Api";

const ProjectManagement = () => {
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const [newProject, setNewProject] = useState("");
    const [taskInputs, setTaskInputs] = useState({});
    const [editMode, setEditMode] = useState({});

    useEffect(() => {
        const fetchProjects = async () => {
            const response = await apiRequest("/projects/loadProjects", "GET");
            if (response.status === "success") {
                setProjects(response.data);
            }
        };
        fetchProjects();
    }, []);

    useEffect(() => {
        apiRequest("/projects/updateProjects", "POST", {
            projects: projects.map(({ id, name, description, tasks, earningsEstimate }) => ({ id, name, description, tasks, earningsEstimate }))
        });
    }, [projects]);

    const handleAddProject = async () => {
        if (!newProject.trim()) return;
        const response = await apiRequest("/projects/createProject", "POST", { projectName: newProject, projectDescription: "", earningsEstimate: 0 });
        if (response.status === "success") {
            setProjects([...projects, { id: response.id, name: newProject, description: "", tasks: [], earningsEstimate: 0 }]);
            setNewProject("");
        }
    };

    const handleEditEarnings = (index, newEarnings) => {
        const updatedProjects = [...projects];
        updatedProjects[index].earningsEstimate = newEarnings;
        setProjects(updatedProjects);
    };

    const handleAddTask = (projectIndex) => {
        if (!taskInputs[projectIndex]) return;
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].tasks.push({ name: taskInputs[projectIndex], description: "", progress: 0, completed: false });
        setProjects(updatedProjects);
        setTaskInputs({ ...taskInputs, [projectIndex]: "" });
    };

    const handleEditTask = (projectIndex, taskIndex, field, value) => {
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].tasks[taskIndex][field] = value;
        setProjects(updatedProjects);
    };

    const handleToggleTask = (projectIndex, taskIndex) => {
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].tasks[taskIndex].completed = !updatedProjects[projectIndex].tasks[taskIndex].completed;
        setProjects(updatedProjects);
    };

    const handleDeleteProject = (index) => {
        if (window.confirm(t("Are you sure you want to delete this project?"))) {
            setProjects(projects.filter((_, i) => i !== index));
        }
    };

    const handleDeleteTask = (projectIndex, taskIndex) => {
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].tasks = updatedProjects[projectIndex].tasks.filter((_, i) => i !== taskIndex);
        setProjects(updatedProjects);
    };

    const toggleEditMode = (projectIndex, taskIndex) => {
        setEditMode((prev) => ({ ...prev, [`${projectIndex}-${taskIndex}`]: !prev[`${projectIndex}-${taskIndex}`] }));
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <h2 className="text-2xl font-bold">{t("Project Management")}</h2>

            <div className="flex mt-4">
                <input type="text" placeholder={t("New Project Name")} value={newProject} onChange={(e) => setNewProject(e.target.value)} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white" />
                <button onClick={handleAddProject} className="ml-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded">
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>

            {projects.map((project, projectIndex) => (
                <div key={project.id} className="mt-6 p-4 border border-gray-700 rounded">
                    <div className="flex justify-between items-center">
                        <h3 className="text-lg font-semibold">{project.name}</h3>
                        <button onClick={() => handleDeleteProject(projectIndex)} className="text-red-500 hover:text-red-700">
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    <div className="flex items-center mt-2">
                        <p className="text-sm text-gray-400 mr-2">{t("Estimated Earnings")}: </p>
                        <input type="number" value={project.earningsEstimate} onChange={(e) => handleEditEarnings(projectIndex, parseFloat(e.target.value) || 0)} className="w-20 p-1 border border-gray-600 bg-gray-700 rounded text-white" />
                    </div>
                    <ul className="mt-2">
                        {project.tasks.map((task, taskIndex) => (
                            <li key={taskIndex} className="p-2 border-b border-gray-700">
                                <div className="flex justify-between items-center">
                                    <FontAwesomeIcon icon={task.completed ? faCheckSquare : faSquare} className="mr-2 cursor-pointer" onClick={() => handleToggleTask(projectIndex, taskIndex)} />
                                    <input type="text" value={task.name} onChange={(e) => handleEditTask(projectIndex, taskIndex, 'name', e.target.value)} disabled={!editMode[`${projectIndex}-${taskIndex}`]} className="bg-gray-700 border border-gray-600 p-1 rounded text-white w-full" />
                                    <button onClick={() => toggleEditMode(projectIndex, taskIndex)} className="ml-2 text-blue-400 hover:text-blue-600">
                                        <FontAwesomeIcon icon={editMode[`${projectIndex}-${taskIndex}`] ? faSave : faEdit} />
                                    </button>
                                    <FontAwesomeIcon icon={faTrash} className="ml-4 text-red-500 cursor-pointer hover:text-red-700" onClick={() => handleDeleteTask(projectIndex, taskIndex)} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            ))}
        </div>
    );
};

export default ProjectManagement;
