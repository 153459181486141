import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../utils/Api";

const Initialize = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState("en");
    const [country, setCountry] = useState("SE");
    const [currency, setCurrency] = useState("SEK");
    const [message, setMessage] = useState("");

    const languages = { en: "English", sv: "Svenska", tl: "Tagalog" };
    const countries = { SE: "Sweden", PH: "Philippines", DK: "Denmark" };
    const currencies = { SEK: "Swedish Krona", PHP: "Philippine Peso", DKK: "Danish Krone", USD: "US Dollar", EUR: "Euro" };

    const handleSubmit = async () => {
        try {
            const response = await apiRequest("/user/initialize", "POST", { language, country, currency });
            if (response.status === "success") {
                i18n.changeLanguage(language);
                navigate("/budget");
            } else {
                setMessage(`❌ ${response.message || t("Initialization failed")}`);
            }
        } catch (error) {
            setMessage(`❌ ${t("Error initializing platform")}`);
        }
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center p-6">
            <h2 className="text-2xl font-bold mb-6">{t("Welcome to Your Financial Dashboard")}</h2>

            <div className="w-full max-w-md bg-gray-800 p-6 rounded-lg shadow-lg">
                <label className="block text-sm mb-2">{t("Select Language")}</label>
                <select value={language} onChange={(e) => setLanguage(e.target.value)} className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded">
                    {Object.entries(languages).map(([key, label]) => (
                        <option key={key} value={key}>{label}</option>
                    ))}
                </select>

                <label className="block text-sm mt-4 mb-2">{t("Select Country")}</label>
                <select value={country} onChange={(e) => setCountry(e.target.value)} className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded">
                    {Object.entries(countries).map(([key, label]) => (
                        <option key={key} value={key}>{label}</option>
                    ))}
                </select>

                <label className="block text-sm mt-4 mb-2">{t("Select Default Currency")}</label>
                <select value={currency} onChange={(e) => setCurrency(e.target.value)} className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded">
                    {Object.entries(currencies).map(([key, label]) => (
                        <option key={key} value={key}>{label}</option>
                    ))}
                </select>

                <button onClick={handleSubmit} className="mt-6 w-full px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg">
                    {t("Initialize Platform")}
                </button>
                {message && <p className="mt-4 text-red-400">{message}</p>}
            </div>
        </div>
    );
};

export default Initialize;
