import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "../../utils/Api";
import BackToDashboard from "../../components/BackToDashboard";

const PickOrderPage = () => {
    const { state: order } = useLocation();
    const [pickedProducts, setPickedProducts] = useState({});
    const navigate = useNavigate();

    const togglePick = (productId) => {
        setPickedProducts((prev) => ({
            ...prev,
            [productId]: prev[productId] === "picked" ? null : "picked",
        }));
    };

    const toggleMissing = (productId) => {
        setPickedProducts((prev) => ({
            ...prev,
            [productId]: prev[productId] === "missing" ? null : "missing",
        }));
    };

    const submitPickedItems = async () => {
        const pickedData = order.products.map((product) => ({
            productId: product.productId,
            quantity: product.quantity,
            status: pickedProducts[product.productId] || "unpicked",
        }));

        try {
            await apiRequest("/ecommerce/updatePickedItems", "POST", {
                orderId: order.orderId,
                pickedItems: pickedData,
            });
            alert("Picking status updated!");
            navigate("/ecommerce"); // Redirect after update
        } catch (error) {
            console.error("Error updating picking status", error);
        }
    };

    return (
        <div className="container mx-auto p-4 dark:bg-dark-primary dark:text-dark-text">
            <BackToDashboard customLink="/ecommerce" customText="Back to Ecommerce" />
            <h1 className="text-2xl font-bold mb-4">Pick Order #{order.orderNumber}</h1>

            <p className="text-lg" style={{ color: order.stateColor }}>
                {order.state}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-300">{order.dateAdded}</p>

            <div className="mt-4">
                <h3 className="font-semibold">Products</h3>
                <ul className="list-none p-0">
                    {order.products.map((product) => (
                        <li
                            key={product.productId}
                            className={`py-2 flex justify-between border-b dark:border-gray-600 ${
                                pickedProducts[product.productId] === "picked"
                                    ? "bg-green-500 text-white"
                                    : pickedProducts[product.productId] === "missing"
                                        ? "bg-orange-500 text-white"
                                        : "bg-white dark:bg-dark-secondary"
                            }`}
                        >
                            <div className="flex-1">
                                <p>{product.quantity}x {product.productName}</p>
                                <p className="text-sm text-gray-500 dark:text-gray-300">
                                    {product.priceUnitIncl}€
                                </p>
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    className="bg-green-600 text-white px-2 py-1 rounded"
                                    onClick={() => togglePick(product.productId)}
                                >
                                    ✓ Picked
                                </button>
                                <button
                                    className="bg-orange-600 text-white px-2 py-1 rounded"
                                    onClick={() => toggleMissing(product.productId)}
                                >
                                    ✗ Missing
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="mt-4 text-right">
                <button
                    className="bg-dark-buttonPrimary text-white px-4 py-2 rounded hover:bg-dark-buttonPrimaryHover dark:bg-dark-buttonPrimary dark:hover:bg-dark-buttonPrimaryHover"
                    onClick={submitPickedItems}
                >
                    Submit Picking
                </button>
            </div>
        </div>
    );
};

export default PickOrderPage;
