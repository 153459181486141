import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Sales = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-900 text-white">

            {/* Introduction Section */}
            <section className="w-full text-center p-6 bg-gray-800">
                <h2 className="text-3xl font-bold text-yellow-400">{t('whatIsEkonomiAPI')}</h2>
                <p className="text-lg text-gray-300 mt-2">
                    {t('ekonomiAPIDescription')}
                </p>
            </section>

            {/* Hero Section */}
            <section className="w-full text-center p-8 bg-blue-600">
                <h1 className="text-5xl font-bold">{t('pitchHeader')}</h1>
                <p className="text-xl mt-4">{t('pitchPara1')}</p>
                <Link to="/register" className="mt-6 inline-block px-6 py-3 bg-yellow-400 hover:bg-yellow-500 text-black font-semibold rounded-lg shadow-md">
                    {t('registerButton')}
                </Link>
            </section>

            {/* Features Section */}
            <section className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
                    <i className="fas fa-clock text-5xl text-yellow-400"></i>
                    <h2 className="text-2xl font-bold mt-4">{t('pitchList1')}</h2>
                    <p className="text-gray-300 text-center">{t('pitchList1Description')}</p>
                </div>
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
                    <i className="fas fa-chart-line text-5xl text-green-400"></i>
                    <h2 className="text-2xl font-bold mt-4">{t('pitchList2')}</h2>
                    <p className="text-gray-300 text-center">{t('pitchList2Description')}</p>
                </div>
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
                    <i className="fas fa-money-bill-wave text-5xl text-blue-400"></i>
                    <h2 className="text-2xl font-bold mt-4">{t('pitchList3')}</h2>
                    <p className="text-gray-300 text-center">{t('pitchList3Description')}</p>
                </div>
            </section>

            {/* Why Choose Us Section */}
            <section className="container mx-auto text-center p-6">
                <h2 className="text-3xl font-bold">{t('pitchSubHeader2')}</h2>
                <p className="text-lg text-gray-300 mt-2">{t('pitchPara3')}</p>
            </section>

            {/* Pricing & CTA Section */}
            <section className="container mx-auto text-center p-6 bg-gray-800 rounded-lg shadow-lg mt-8">
                <h2 className="text-3xl font-bold">{t('pitchSubHeader3')}</h2>
                <p className="text-lg text-gray-300 mt-2">{t('pitchPara4')}</p>
                <Link to="/register" className="mt-6 inline-block px-6 py-3 bg-yellow-400 hover:bg-yellow-500 text-black font-semibold rounded-lg shadow-md">
                    {t('registerButton')}
                </Link>
            </section>
        </div>
    );
};

export default Sales;
