import React, { useState, useEffect } from "react";
import { apiRequest } from "../../utils/Api";
import { useTranslation } from "react-i18next";
import BackToDashboard from "../../components/BackToDashboard";

const BudgetOverview = () => {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState("weekly");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [budgetData, setBudgetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currency, setCurrency] = useState("PHP"); // Default currency

    const viewModes = ["daily", "weekly", "monthly"]; // More can be added later
    const currencies = ["SEK", "PHP", "EUR", "USD"]; // Available currencies

    useEffect(() => {
        fetchBudgetData();
    }, [viewMode, currentDate, currency]);

    const fetchBudgetData = async () => {
        setLoading(true);
        try {
            const response = await apiRequest(`/budget/overview?mode=${viewMode}&date=${currentDate.toISOString().split('T')[0]}&currency=${currency}`, "GET");
            setBudgetData(response.data);
            setLoading(false);
        } catch (err) {
            setError(t("Failed to load budget data"));
            setLoading(false);
        }
    };

    const changePeriod = (direction) => {
        const newDate = new Date(currentDate);
        if (viewMode === "daily") newDate.setDate(newDate.getDate() + direction);
        if (viewMode === "weekly") newDate.setDate(newDate.getDate() + (direction * 7));
        if (viewMode === "monthly") newDate.setMonth(newDate.getMonth() + direction);
        setCurrentDate(newDate);
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg text-white">
            <BackToDashboard customText={t("Back to Budget Dashboard")} customLink="/budget" />
            <h2 className="text-xl font-bold">{t("Budget Overview")}</h2>

            {/* View Mode Selection */}
            <div className="flex space-x-4 mt-4">
                {viewModes.map(mode => (
                    <button
                        key={mode}
                        onClick={() => setViewMode(mode)}
                        className={`px-4 py-2 rounded ${viewMode === mode ? 'bg-blue-500' : 'bg-gray-700'}`}
                    >
                        {t(mode.charAt(0).toUpperCase() + mode.slice(1))}
                    </button>
                ))}
            </div>

            {/* Currency Selection */}
            <div className="mt-4">
                <label className="text-sm text-gray-400">{t("Display Currency")}</label>
                <select onChange={(e) => setCurrency(e.target.value)} value={currency} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2">
                    {currencies.map(curr => <option key={curr} value={curr}>{curr}</option>)}
                </select>
            </div>

            {/* Navigation Arrows */}
            <div className="flex justify-between items-center mt-4">
                <button onClick={() => changePeriod(-1)} className="px-4 py-2 bg-gray-700 rounded">◀</button>
                <span className="text-lg font-semibold">{currentDate.toDateString()}</span>
                <button onClick={() => changePeriod(1)} className="px-4 py-2 bg-gray-700 rounded">▶</button>
            </div>

            {/* Budget Data Table */}
            {loading ? (
                <p className="text-gray-400 mt-4">{t("Loading budget data...")}</p>
            ) : budgetData ? (
                <>
                    {/* Income Details */}
                    <h3 className="mt-6 text-lg font-bold">{t("Income")}</h3>
                    <table className="w-full mt-2 text-white border-collapse">
                        <thead>
                        <tr>
                            <th className="border-b border-gray-600 py-2">{t("Source")}</th>
                            <th className="border-b border-gray-600 py-2">{t("Amount")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {budgetData.incomeDetails.map((income, index) => (
                            <tr key={index} className="bg-gray-700">
                                <td className="py-2 px-4">{income.source}</td>
                                <td className="py-2 px-4 font-bold">{income.amount} {currency}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Expense Details */}
                    <h3 className="mt-6 text-lg font-bold">{t("Expenses")}</h3>
                    <table className="w-full mt-2 text-white border-collapse">
                        <thead>
                        <tr>
                            <th className="border-b border-gray-600 py-2">{t("Category")}</th>
                            <th className="border-b border-gray-600 py-2">{t("Amount")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {budgetData.expenseDetails.map((expense, index) => (
                            <tr key={index} className="bg-gray-700">
                                <td className="py-2 px-4">{expense.category}</td>
                                <td className="py-2 px-4 font-bold">{expense.amount} {currency}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Balance Calculation */}
                    <div className="bg-gray-900 p-4 mt-6 rounded-lg">
                        <h3 className="text-lg font-bold">{t("Balance")}</h3>
                        <p className="text-green-400 text-xl font-bold">{(budgetData.income - budgetData.expenses).toFixed(2)} {currency}</p>
                    </div>
                </>
            ) : (
                <p className="text-gray-400 mt-4">{t("No budget data available for this period.")}</p>
            )}
        </div>
    );
};

export default BudgetOverview;
