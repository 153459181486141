import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackToDashboard from "../components/BackToDashboard";
import { apiRequest } from "../utils/Api";

const PersonalDebtsIndex = () => {
    const [debts, setDebts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch debts from API
        const fetchDebts = async () => {
            try {
                const response = await apiRequest("/PersonalDebts/Debts", "POST");
                setDebts(response.debts || []);
            } catch (error) {
                console.error("Failed to fetch debts", error);
            }
        };
        fetchDebts();
    }, []);

    return (
        <div className="bg-gray-900 text-white min-h-screen px-4 py-6">
            <BackToDashboard customText="Back to Dashboard" customLink="/dashboard" />
            <h1 className="text-3xl font-bold mb-6">Personal Debts</h1>

            {debts.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {debts.map((debt) => {
                        const progressPercentage = (debt.daysPaid / debt.daysToPay) * 100;

                        return (
                            <div
                                key={debt.id}
                                className="bg-gray-800 p-4 rounded-lg cursor-pointer"
                                onClick={() => navigate(`/my/debts/${debt.id}`)}
                            >
                                <h2 className="text-xl font-bold">{debt.description}</h2>
                                <p>Total Debt: {parseFloat(debt.amount).toFixed(0)} {debt.currency}</p>
                                <p>Amount Per Day: {parseFloat(debt.amountPerDay).toFixed(0)} {debt.currency}</p>
                                <p>Remaining Days: {debt.daysToPay - debt.daysPaid}</p>

                                {/* Progress Bar */}
                                <div className="mt-4">
                                    <div className="w-full bg-gray-700 rounded-full h-4">
                                        <div
                                            className="bg-green-500 h-4 rounded-full"
                                            style={{ width: `${progressPercentage}%` }}
                                        ></div>
                                    </div>
                                    <p className="text-sm mt-2 text-gray-400">
                                        {progressPercentage.toFixed(1)}% Paid
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="bg-gray-800 p-4 rounded-lg text-center">
                    <p>No debts found.</p>
                </div>
            )}

            <div className="mt-6 text-center">
                <button
                    onClick={() => navigate("/my/debts-add")}
                    className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg"
                >
                    Add a New Debt
                </button>
            </div>
        </div>
    );
};

export default PersonalDebtsIndex;
