import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api';
import { useTranslation } from 'react-i18next';
import ToolFooter from '../../components/ToolFooter';
import FormatSelector from '../../components/FormatSelector';
import FileDownloadButton from '../../components/FileDownloadButton';
import CustomFileUpload from '../../components/CustomFileUpload'; // Import CustomFileUpload component

const Bambora = () => {
    const [file, setFile] = useState(null);
    const [convertedFile, setConvertedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFormat, setSelectedFormat] = useState('csv');
    const [inaccount, setInaccount] = useState(1581);
    const [serie, setSerie] = useState('GI');
    const { t } = useTranslation();

    // API Documentation for the tool
    const apiDocumentation = {
        description: 'Convert a Bambora file to SIE format via the API.',
        calls: {
            '/tools/bambora': 'POST',
        },
        post: {
            file: 'base64-encoded file -- The file to convert',
            inaccount: 'number -- Bambora account',
            serie: 'string -- Series identifier',
            format: 'string -- Desired output format (csv, sie)',
        },
    };

    const handleFileChange = (file) => {
        setFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!file) {
            setError(t('PleaseSelectFile'));
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/tools/bambora', 'POST', {
                    file: base64File,
                    inaccount: inaccount,
                    serie: serie,
                    format: selectedFormat,
                });

                if (response.error) {
                    setError(response.error);
                } else {
                    setConvertedFile(response.convertedFile);
                }
            } catch (err) {
                setError(t('FailedToProcessFile'));
            }

            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-gray-800 text-white shadow-lg rounded-lg">
            <h1 className="text-2xl font-bold mb-4">{t('Bambora')}</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <ol className="list-decimal space-y-3">
                    <li>
                        <label htmlFor="inaccount" className="block text-sm font-medium text-gray-300">
                            {t('InAccount')}
                        </label>
                        <input
                            type="number"
                            id="inaccount"
                            value={inaccount}
                            onChange={(e) => setInaccount(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 bg-gray-700 text-white rounded-md shadow-sm"
                        />
                    </li>
                    <li>
                        <label htmlFor="serie" className="block text-sm font-medium text-gray-300">
                            {t('Series')}
                        </label>
                        <input
                            type="text"
                            id="serie"
                            value={serie}
                            onChange={(e) => setSerie(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 bg-gray-700 text-white rounded-md shadow-sm"
                        />
                    </li>
                    <li>
                        {/* Format Selection Component */}
                        <FormatSelector selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} />
                    </li>
                    <li>
                        <label className="block text-sm font-medium text-gray-300">
                            {t('UploadFile')}
                        </label>
                        {/* Using CustomFileUpload */}
                        <CustomFileUpload onFileSelect={handleFileChange} />
                    </li>
                </ol>

                <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition duration-200 ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={loading}
                >
                    {loading ? t('Processing') : t('ConvertFile')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-400 text-sm">{error}</div>}

            {convertedFile && (
                <div className="mt-4">
                    {/* File Download Button Component */}
                    <FileDownloadButton convertedFile={convertedFile} selectedFormat={selectedFormat} fileName="converted_bambora" />
                </div>
            )}

            <ToolFooter apiDocumentation={apiDocumentation} />
        </div>
    );
};

export default Bambora;
