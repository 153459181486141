import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api'; // Adjust path if necessary
import { useTranslation } from 'react-i18next';

const CustomCsvBuilder = () => {
    const { t } = useTranslation();

    const availableKeys = [
        'rowNumber', 'clearingNumber', 'accountNumber', 'product', 'currency',
        'bookingDate', 'transactionDate', 'valueDate', 'reference', 'description',
        'amount', 'balance', 'blank' // 'blank' allows users to have empty columns
    ];

    const [customFormat, setCustomFormat] = useState(Array(availableKeys.length).fill('blank'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [delimiter, setDelimiter] = useState(','); // Default to comma

    useEffect(() => {
        const loadSavedFormat = async () => {
            if (localStorage.getItem('customFormat')) {
                setCustomFormat(JSON.parse(localStorage.getItem('customFormat')));
                setDelimiter(localStorage.getItem('delimiter') || ',');
            }
            try {
                const response = await apiRequest('/tools/getCustomCsv', 'POST');
                if (response.customFormat) {
                    setCustomFormat(response.customFormat.format);
                    setDelimiter(response.customFormat.delimiter || ',');
                }
            } catch (err) {
                console.error('Error fetching saved format:', err);
            }
        };
        loadSavedFormat();
    }, []);

    const handleSelectionChange = (index, newValue) => {
        const updatedFormat = [...customFormat];
        updatedFormat[index] = newValue;
        setCustomFormat(updatedFormat);
    };

    const handleSaveFormat = async () => {
        setLoading(true);
        setError(null);
        setSuccess(false);
        localStorage.setItem('customFormat', JSON.stringify(customFormat));
        localStorage.setItem('delimiter', delimiter);

        try {
            const response = await apiRequest('/tools/setCustomCsv', 'POST', {
                customFormat: {
                    format: customFormat,
                    delimiter: delimiter,
                }
            });

            if (response.error) {
                setError(response.error);
            } else {
                setSuccess(true);
            }
        } catch (err) {
            setError(t('FailedToSaveFormat'));
        }

        setLoading(false);
    };

    return (
        <div className="max-w-3xl mx-auto p-6 bg-gray-800 shadow-lg rounded-lg text-white">
            <h1 className="text-2xl font-bold mb-4">{t('CustomCsvBuilder')}</h1>

            <p className="text-gray-300">{t('ChooseYourCsvColumns')}</p>

            <form className="space-y-4">
                {/* Column selection section */}
                {customFormat.map((selectedKey, index) => (
                    <div key={index} className="flex items-center space-x-4">
                        <label className="block text-sm font-medium text-gray-300">{t('Column')} {index + 1}:</label>
                        <select
                            value={selectedKey}
                            onChange={(e) => handleSelectionChange(index, e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        >
                            {availableKeys.map((key) => (
                                <option key={key} value={key}>
                                    {key === 'blank' ? t('Blank') : t(key)}
                                </option>
                            ))}
                        </select>
                    </div>
                ))}

                {/* Delimiter selection section */}
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-300">{t('Delimiter')}</label>
                    <div className="mt-2 flex space-x-4">
                        <button
                            type="button"
                            className={`px-3 py-2 border border-gray-600 rounded-md transition duration-200 ${
                                delimiter === ',' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                            }`}
                            onClick={() => setDelimiter(',')}
                        >
                            {t('Comma')}
                        </button>
                        <button
                            type="button"
                            className={`px-3 py-2 border border-gray-600 rounded-md transition duration-200 ${
                                delimiter === ';' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                            }`}
                            onClick={() => setDelimiter(';')}
                        >
                            {t('Semicolon')}
                        </button>
                        <button
                            type="button"
                            className={`px-3 py-2 border border-gray-600 rounded-md transition duration-200 ${
                                delimiter === '\t' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                            }`}
                            onClick={() => setDelimiter('\t')}
                        >
                            {t('Tab')}
                        </button>
                    </div>
                </div>

                {/* Save button */}
                <button
                    type="button"
                    onClick={handleSaveFormat}
                    className={`w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition duration-200 ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={loading}
                >
                    {loading ? t('Saving') : t('SaveFormat')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-400 text-sm">{error}</div>}
            {success && <div className="mt-4 text-green-400 text-sm">{t('FormatSavedSuccessfully')}</div>}
        </div>
    );
};

export default CustomCsvBuilder;
