import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../utils/Api";

const ReferralBox = () => {
    const { t } = useTranslation();
    const [referralCode, setReferralCode] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReferralCode = async () => {
            try {
                const response = await apiRequest("/user/referralCode", "GET");
                if (response.status === "success") {
                    setReferralCode(response.referralCode);
                } else {
                    setError(t("Failed to load referral code"));
                }
            } catch (err) {
                setError(t("Error fetching referral code"));
            }
        };

        fetchReferralCode();
    }, [t]);

    const copyToClipboard = () => {
        if (referralCode) {
            navigator.clipboard.writeText(referralCode);
            alert(t("Referral code copied to clipboard!"));
        }
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold">{t("Refer a Friend & Get Rewards!")}</h2>
            <p className="text-gray-300 mt-2">
                {t("Share your referral code and your friend gets 20% off their first invoice. You get a free month added to your account!")}
            </p>

            {error ? (
                <p className="text-red-400 mt-2">{error}</p>
            ) : referralCode ? (
                <div className="flex items-center mt-4">
                    <span className="px-4 py-2 bg-gray-700 text-white rounded-md">{referralCode}</span>
                    <button
                        onClick={copyToClipboard}
                        className="ml-3 px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
                    >
                        {t("Copy")}
                    </button>
                </div>
            ) : (
                <p className="text-gray-400 mt-2">{t("Loading referral code...")}</p>
            )}
        </div>
    );
};

export default ReferralBox;
