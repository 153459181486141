import React, { useState } from 'react';

const NimGame = () => {
    const [rows, setRows] = useState([1, 3, 5, 7]); // Initial rows with sticks
    const [currentPlayer, setCurrentPlayer] = useState(1); // Player 1 starts
    const [winner, setWinner] = useState(null); // Track winner
    const [sliderValues, setSliderValues] = useState([0, 0, 0, 0]); // Individual sliders for each row
    const [gameMode, setGameMode] = useState(2); // Default to 2 players (1 = single player with computer)

    // Handle confirming a move
    const confirmMove = () => {
        if (winner) return; // Game over, no further moves allowed

        const rowIndex = sliderValues.findIndex((value) => value > 0); // Find the row with a slider value
        if (rowIndex === -1) {
            alert('Invalid move! You must remove at least one stick.');
            return;
        }

        const sticksToRemove = sliderValues[rowIndex];
        const newRows = [...rows];
        newRows[rowIndex] -= sticksToRemove;

        if (newRows[rowIndex] < 0) {
            alert("Invalid move! You can't remove more sticks than are available.");
            return;
        }

        setRows(newRows);
        checkWinner(newRows);

        // Switch player
        const nextPlayer = currentPlayer === 1 ? 2 : 1;
        setCurrentPlayer(nextPlayer);

        // Reset sliders
        setSliderValues(newRows.map(() => 0));

        // If it's a single-player game and it's the computer's turn
        if (gameMode === 1 && nextPlayer === 2) {
            setTimeout(() => computerMove(newRows), 500); // Delay for computer's move
        }
    };

    // Computer move logic
    const computerMove = (currentRows) => {
        const nimSum = currentRows.reduce((acc, sticks) => acc ^ sticks, 0); // XOR all rows
        let moveMade = false;
        const newRows = [...currentRows];

        if (nimSum !== 0) {
            // Find a move that reduces the Nim-Sum to 0
            for (let i = 0; i < newRows.length; i++) {
                const target = newRows[i] ^ nimSum;
                if (target < newRows[i]) {
                    newRows[i] = target;
                    moveMade = true;
                    break;
                }
            }
        }

        // If no winning move, pick the first non-empty row
        if (!moveMade) {
            for (let i = 0; i < newRows.length; i++) {
                if (newRows[i] > 0) {
                    newRows[i] -= 1;
                    break;
                }
            }
        }

        setRows(newRows);
        checkWinner(newRows);

        // Switch back to player 1
        setCurrentPlayer(1);
    };

    // Check for winner
    const checkWinner = (currentRows) => {
        const remainingSticks = currentRows.reduce((sum, sticks) => sum + sticks, 0);
        if (remainingSticks === 0) {
            setWinner(`Player ${currentPlayer}`);
        }
    };

    // Reset the game
    const resetGame = () => {
        setRows([1, 3, 5, 7]);
        setCurrentPlayer(1);
        setWinner(null);
        setSliderValues([0, 0, 0, 0]);
    };

    // Update the slider value for a specific row and reset others
    const updateSliderValue = (rowIndex, value) => {
        const newSliderValues = sliderValues.map((_, i) => (i === rowIndex ? value : 0));
        setSliderValues(newSliderValues);
    };

    // Binary computation for the rows
    const calculateBinarySums = () => {
        const binaryRows = rows.map((sticks) => sticks.toString(2).padStart(3, '0')); // Convert rows to binary
        const columnSums = [0, 0, 0]; // Three columns for binary values

        binaryRows.forEach((binaryRow) => {
            binaryRow.split('').forEach((bit, index) => {
                columnSums[index] += parseInt(bit, 10);
            });
        });

        const binaryColumnSums = columnSums.map((sum) => sum % 2); // Reduce sums to even/odd (mod 2)
        return { binaryRows, binaryColumnSums };
    };

    const { binaryRows, binaryColumnSums } = calculateBinarySums();

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
            <h1 className="text-3xl font-bold mb-6 text-gray-700">Nim Game</h1>

            {winner ? (
                <div className="bg-green-100 text-green-800 p-4 rounded-lg shadow-md mb-6">
                    🎉 {winner} wins the game!
                </div>
            ) : (
                <div className="text-lg mb-4 text-gray-700">
                    {gameMode === 1 && currentPlayer === 2
                        ? "Computer's turn"
                        : `Player ${currentPlayer}'s turn`}
                </div>
            )}

            <div className="flex space-x-4 mb-6">
                <button
                    className={`px-4 py-2 rounded-lg ${gameMode === 1 ? 'bg-blue-500' : 'bg-gray-200'}`}
                    onClick={() => setGameMode(1)}
                >
                    1 Player
                </button>
                <button
                    className={`px-4 py-2 rounded-lg ${gameMode === 2 ? 'bg-blue-500' : 'bg-gray-200'}`}
                    onClick={() => setGameMode(2)}
                >
                    2 Players
                </button>
            </div>

            <div className="space-y-6 w-full max-w-md">
                {rows.map((sticks, rowIndex) => (
                    <div
                        key={rowIndex}
                        className="flex flex-col items-center space-y-4"
                    >
                        <div className="flex space-x-1">
                            {Array.from({ length: sticks }).map((_, i) => (
                                <div
                                    key={i}
                                    className="w-4 h-16 bg-blue-500 rounded"
                                ></div>
                            ))}
                        </div>
                        {sticks > 0 && (
                            <div className="w-full">
                                <input
                                    type="range"
                                    min="0"
                                    max={sticks}
                                    value={sliderValues[rowIndex]}
                                    onChange={(e) =>
                                        updateSliderValue(
                                            rowIndex,
                                            parseInt(e.target.value)
                                        )
                                    }
                                    className="slider w-full"
                                    disabled={gameMode === 1 && currentPlayer === 2}
                                />
                                <span className="text-gray-600 mt-2 block text-center">
                                    Remove {sliderValues[rowIndex]} stick(s)
                                </span>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <button
                className="mt-6 bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-all"
                onClick={confirmMove}
                disabled={!sliderValues.some((value) => value > 0) || (gameMode === 1 && currentPlayer === 2)}
            >
                Confirm Move
            </button>

            <button
                className="mt-4 bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-900"
                onClick={resetGame}
            >
                Reset Game
            </button>

            <div className="mt-6 bg-gray-200 p-4 rounded-lg shadow-md">
                <h2 className="text-lg font-bold text-gray-700 mb-4">Binary Computation</h2>
                <div className="space-y-2">
                    {binaryRows.map((binaryRow, rowIndex) => (
                        <div key={rowIndex} className="text-gray-700">
                            Row {rowIndex + 1}: {binaryRow}
                        </div>
                    ))}
                    <div className="text-gray-700 font-bold">
                        Column Sums: {binaryColumnSums.join(' ')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NimGame;
