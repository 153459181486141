import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToolsBox from "../components/ToolsBox";
import { useLocation } from "react-router-dom";
import { apiRequest } from "../utils/Api";

const Header = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");
    const [isToolsOpen, setIsToolsOpen] = useState(true);
    const [isIncarnated, setIsIncarnated] = useState(localStorage.getItem("isIncarnated") === "true");
    const location = useLocation();
    const { t, i18n } = useTranslation();

    // Define restricted routes where Tools button should be hidden
    const restrictedRoutes = ["/employee", "/ecommerce"];
    const dashboardUrl = restrictedRoutes.find((route) => location.pathname.startsWith(route)) || "/dashboard";

    const isRestrictedPage = restrictedRoutes.some((route) => location.pathname.startsWith(route));

    useEffect(() => {
        setIsToolsOpen(false);
    }, [location.pathname]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem("darkMode", !darkMode);
        document.documentElement.classList.toggle("dark", !darkMode);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleRevert = async () => {
        try {
            const response = await apiRequest("/Auth/revertIncarnate", "GET");
            if (response.error) {
                alert(`❌ ${response.error}`);
            } else {
                alert(`✅ ${t("Reverted back to admin")}`);
                localStorage.removeItem("isIncarnated");
                localStorage.removeItem("incarnatedUserId");
                localStorage.removeItem("incarnatedUserEmail");
                let originalToken = localStorage.getItem("originalToken");
                localStorage.removeItem("originalToken");
                localStorage.setItem("token", originalToken);
                window.location.reload();
            }
        } catch (err) {
            alert(`❌ ${t("Failed to revert incarnate")}`);
        }
    };

    return (
        <header className="w-full fixed top-0 left-0 z-50 bg-gray-800 text-white p-4 shadow-md">
            <div className="flex justify-between items-center">
                <div className="logo">
                    <a href={`${dashboardUrl}`} className="text-xl font-bold">
                        EkonomiAPI
                    </a>
                </div>

                <nav className="flex space-x-4 items-center">
                    {/* Hide ToolsBox button on restricted pages */}
                    {!isRestrictedPage && (
                        <button
                            onClick={() => setIsToolsOpen(!isToolsOpen)}
                            className="text-yellow-300 p-2 rounded hover:bg-gray-700 transition"
                        >
                            <i className="fa-solid fa-tools"></i>
                        </button>
                    )}

                    <button onClick={() => changeLanguage("sv")} className="hover:underline">
                        SV
                    </button>
                    <button onClick={() => changeLanguage("en")} className="hover:underline">
                        EN
                    </button>

                    <button
                        onClick={() => {
                            localStorage.removeItem("token");
                            localStorage.removeItem("user");
                            localStorage.removeItem("referralCode");
                            localStorage.removeItem("apiInfo");
                            window.location.href = "/";
                        }}
                        className="px-4 py-2 text-white rounded"
                    >
                        <i className="fa-solid fa-sign-out-alt"></i>
                    </button>

                    {isIncarnated && (
                        <button onClick={handleRevert} className="px-4 py-2 bg-red-500 text-white rounded">
                            {t("Exit Incarnation")}
                        </button>
                    )}
                </nav>
            </div>

            {!isRestrictedPage && (
                <div className={`transition-all duration-300 ${isToolsOpen ? "block" : "hidden"}`}>
                    <ToolsBox />
                </div>
            )}
        </header>
    );
};

export default Header;
