import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Referral = () => {
    const { referralCode } = useParams();  // Get the referral code from the URL
    const [referralData, setReferralData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();  // i18n hook for translation

    const defaultReferralCode = 'ekobonus';  // Hardcoded fallback referral code

    useEffect(() => {
        const fetchReferralData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/referral/${referralCode}`);
                const data = await response.json();

                if (response.ok && data.referrerName) {
                    setReferralData(data);
                } else {
                    setReferralData({
                        referrerName: null,
                        bonusInfo: t('defaultBonus'),
                        referralCode: defaultReferralCode
                    });
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReferralData();
    }, [referralCode, t]);

    if (loading) {
        return <div className="text-white text-center mt-10">{t('loading')}</div>;
    }

    if (error) {
        return <div className="text-red-400 text-center mt-10">{t('error')}: {error}</div>;
    }

    const { referrerName, bonusInfo } = referralData || {};
    const finalReferralCode = referralData?.referralCode || referralCode;

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-900 text-white p-6">
            <div className="w-full max-w-4xl p-6 bg-gray-800 shadow-lg rounded-lg">
                <h1 className="text-4xl font-bold mb-4 text-center">{t('referralTitle')}</h1>

                {/* Referral Details */}
                {referrerName ? (
                    <p className="mb-4 text-lg text-center text-gray-300">
                        {t('referralBy', { referrerName })}
                    </p>
                ) : (
                    <p className="mb-4 text-lg text-center text-gray-300">
                        {t('referralBonus', { bonusInfo })}
                    </p>
                )}

                {/* Sales Pitch */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <h2 className="text-2xl font-bold mb-2">{t('referralPitchHeader')}</h2>
                        <ul className="list-disc list-inside mb-4 text-lg text-gray-300">
                            <li><strong>{t('referralList1')}:</strong> {t('referralList1Description')}</li>
                            <li><strong>{t('referralList2')}:</strong> {t('referralList2Description')}</li>
                            <li><strong>{t('referralList3')}:</strong> {t('referralList3Description')}</li>
                            <li><strong>{t('referralList4')}:</strong> {t('referralList4Description')}</li>
                        </ul>

                        <h2 className="text-2xl font-bold mb-2">{t('referralSubHeader')}</h2>
                        <p className="text-lg mb-4 text-gray-300">{t('referralPara')}</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                        <img src="/images/timeismoney.webp" alt="EkonomiAPI" className="w-full h-auto rounded-lg" />
                    </div>
                </div>

                {/* Register Button */}
                <Link
                    to={`/register/${finalReferralCode}`}
                    className="block p-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded text-center mt-6 w-48 mx-auto transition duration-300"
                >
                    {t('referralButton')}
                </Link>
            </div>
        </div>
    );
};

export default Referral;
