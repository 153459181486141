import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../../utils/Api';

const AdminActions = () => {
    const { t } = useTranslation();
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [category, setCategory] = useState('');
    const [message, setMessage] = useState(null);

    const handleAddExpense = async () => {
        try {
            const response = await apiRequest('/Admin/addExpense', 'POST', { description, amount, category });
            setMessage(response.success || response.error);
        } catch (err) {
            setMessage(t('Failed to add expense'));
        }
    };

    return (
        <div className="p-4 bg-gray-800 rounded-lg">
            <h3 className="text-lg font-bold">{t('Admin Actions')}</h3>
            <h2 className="text-md text-gray-300 mb-3">{t('Add Expense')}</h2>

            {/* Form container for better mobile layout */}
            <div className="space-y-3">
                <input
                    type="text"
                    placeholder={t('Description')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 border border-gray-700 rounded text-black"
                />
                <input
                    type="number"
                    placeholder={t('Amount')}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full p-2 border border-gray-700 rounded text-black"
                />
                <input
                    type="text"
                    placeholder={t('Category')}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full p-2 border border-gray-700 rounded text-black"
                />
                <button
                    className="w-full bg-green-500 p-2 rounded text-white hover:bg-green-600 transition"
                    onClick={handleAddExpense}
                >
                    {t('Add Expense')}
                </button>
            </div>

            {message && <p className="text-sm text-gray-300 mt-2">{message}</p>}
        </div>
    );
};

export default AdminActions;
