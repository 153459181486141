import React from 'react';

const CurrencyRatesBox = ({ data }) => {
    return (
        <div className="bg-gray-800 p-4 shadow-lg rounded text-white">
            <h2 className="text-xl font-bold mb-2">Currency Rates (SEK)</h2>
            <ul className="divide-y divide-gray-600">
                {Object.entries(data).map(([currency, rate]) => (
                    <li key={currency} className="py-1">
                        <span className="font-semibold">{currency}</span>: {rate}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CurrencyRatesBox;
