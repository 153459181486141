import React from 'react';
import { useTranslation } from 'react-i18next';

const OwnershipDetails = ({ data }) => {
    const { t } = useTranslation();

    if (!data) return <p>{t('Loading ownership data...')}</p>;

    return (
        <div>
            <h2 className="text-xl font-bold">{t('Your Ownership')}</h2>
            <p>{t('Shares Owned')}: {data.shares} ({data.ownershipPercentage}%)</p>
            <p>{t('Total Shares')}: {data.totalShares}</p>
            <p>{t('Current Share Price')}: {data.sharePrice} SEK</p>
        </div>
    );
};

export default OwnershipDetails;
