import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../utils/Api";
import { useTranslation } from "react-i18next";
import BackToDashboard from "../../components/BackToDashboard";
import CustomFileUpload from "../../components/CustomFileUpload";

const AddBudgetEvent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        amount: "",
        currency: "PHP",
        frequency: "weekly",
        eventType: "charge",
        startDate: "",
        referencePhoto: null
    });
    const [message, setMessage] = useState("");

    const currencies = ["SEK", "PHP", "EUR", "USD"];
    const frequencies = ["once", "daily", "weekly", "monthly", "quarterly", "yearly"];
    const eventTypes = ["income", "charge", "savings"];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileSelect = (file) => {
        setFormData({ ...formData, referencePhoto: file });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const budgetEvent = formData;

        try {
            console.log("Budget Event Data:", budgetEvent);
            const response = await apiRequest("/budget/addEvent", "POST", budgetEvent);
            if (response.status === "success") {
                setMessage(`✅ ${t("Budget event added successfully")}`);
                // navigate("/budget");
            } else {
                setMessage(`❌ ${response.message || t("Failed to add budget event")}`);
            }
        } catch (error) {
            console.error("API Request Failed:", error);
            setMessage(`❌ ${t("Error submitting the budget event")} - ${error.message} - ${JSON.stringify(budgetEvent)}`);
        }
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg text-white">
            <BackToDashboard customText={t("Back to Budget Overview")} customLink="/budget" />
            <h2 className="text-xl font-bold">{t("Add Budget Event")}</h2>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <input type="text" name="title" placeholder={t("Event Title")} onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2" required />
                <textarea name="description" placeholder={t("Description")} onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2"></textarea>
                <input type="number" name="amount" placeholder={t("Total Amount")} onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2" required />
                <input type="date" name="startDate" placeholder={t("Start Date")} onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2" required />

                <select name="frequency" onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2">
                    {frequencies.map(freq => <option key={freq} value={freq}>{t(freq)}</option>)}
                </select>
                <select name="currency" onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2">
                    {currencies.map(curr => <option key={curr} value={curr}>{curr}</option>)}
                </select>
                <select name="eventType" onChange={handleChange} className="w-full p-2 border border-gray-600 bg-gray-700 rounded text-white mt-2">
                    {eventTypes.map(type => <option key={type} value={type}>{t(type)}</option>)}
                </select>

                <CustomFileUpload onFileSelect={handleFileSelect} />

                <button type="submit" className="mt-4 px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg font-semibold">{t("Submit")}</button>
            </form>
            {message && <div className="mt-4 text-white">{message}</div>}
        </div>
    );
};

export default AddBudgetEvent;
