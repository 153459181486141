import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../utils/Api";
import BackToDashboard from "../components/BackToDashboard";

const PersonalDebts = () => {
    const { debtId } = useParams();
    const navigate = useNavigate();
    const [debtDetails, setDebtDetails] = useState(null);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState("");

    useEffect(() => {
        const fetchDebtDetails = async () => {
            try {
                const data = await apiRequest(`/personalDebts/debt`, "POST", { debtId });
                setDebtDetails(data.debt);
                setPaymentHistory(data.payments || []);

                if (data.debt) {
                    let progress = (data.debt.daysPaid / data.debt.daysToPay) * 100;
                    setProgressPercentage(progress);
                }
            } catch (err) {
                console.error("Failed to fetch debt details.");
            }
        };

        fetchDebtDetails();
    }, [debtId]);

    if (!debtDetails) {
        return <div className="text-white text-center mt-10">Loading...</div>;
    }

    const handlePayment = async () => {
        if (!paymentAmount || isNaN(paymentAmount) || paymentAmount <= 0) return;

        try {
            const response = await apiRequest(`/personalDebts/registerPayment`, "POST", {
                debtId,
                amount: parseFloat(paymentAmount),
            });

            if (response.status === "success") {
                window.location.reload();
            } else {
                alert(response.message || "Failed to register payment.");
            }
        } catch (error) {
            alert("Failed to process payment.");
        }
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <BackToDashboard customText="Back to Debts" customLink="/my/debts" />
            <h1 className="text-3xl font-bold mb-4">Debt Overview</h1>

            {/* Debt Details */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6 flex flex-wrap items-center">
                <div className="flex-grow">
                    <h2 className="text-lg font-semibold">Debt Information</h2>
                    <p>Description: {debtDetails.description}</p>
                    <p>Total Debt: {parseFloat(debtDetails.amount).toFixed(0)} {debtDetails.currency}</p>
                    <p>Total Days to Pay: {debtDetails.daysToPay} days</p>
                    <p>Paid: {debtDetails.daysPaid} / {debtDetails.daysToPay} days</p>
                    <p>Remaining: {debtDetails.daysToPay - debtDetails.daysPaid} days</p>
                    <p>Daily Cost: {parseFloat(debtDetails.amountPerDay).toFixed(2)} {debtDetails.currency}</p>

                    {/* Progress Bar */}
                    <div className="mt-4 p-2">
                        <div className="w-full bg-gray-700 rounded-full h-4">
                            <div
                                className="bg-green-500 h-4 rounded-full"
                                style={{width: `${progressPercentage}%`}}
                            ></div>
                        </div>
                        <p className="text-sm mt-2 text-gray-400">
                            {progressPercentage.toFixed(1)}% of payments completed
                        </p>
                    </div>
                </div>
            </div>

            {/* Payment History */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6">
                <h2 className="text-lg font-semibold mb-4">Payment History</h2>
                <div className="flex justify-between px-3 py-2 border-b border-gray-700 text-gray-400 font-medium">
                    <span className="w-1/3">Date</span>
                    <span className="w-1/3 text-center">Amount</span>
                    <span className="w-1/3 text-right">Days Covered</span>
                </div>
                {paymentHistory.map((entry) => (
                    <div key={entry.id} className="flex justify-between items-center px-3 py-2 border-b border-gray-700">
                        <p className="w-1/3 text-gray-400 text-sm">{entry.paidDate}</p>
                        <p className="w-1/3 text-center font-bold text-lg">{parseFloat(entry.amountPaid).toFixed(0)} {debtDetails.currency}</p>
                        <p className="w-1/3 text-right text-sm text-green-400">
                            {entry.daysPaid} days
                        </p>
                    </div>
                ))}
            </div>

            {/* Register New Payment */}
            <div className="bg-gray-800 p-4 rounded-lg text-center">
                <h2 className="text-lg font-semibold mb-3">Make a Payment</h2>
                <input
                    type="number"
                    placeholder="Enter amount"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                    className="p-2 border border-gray-700 rounded text-black w-3/4"
                />
                <button
                    onClick={handlePayment}
                    className="mt-2 px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg font-semibold"
                >
                    Pay Now
                </button>
            </div>
        </div>
    );
};

export default PersonalDebts;
