import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const UsageBox = ({ data }) => {
    const usageData = Object.keys(data).map((day) => ({
        name: day,
        timeSaved: data[day],
    }));

    return (
        <div className="bg-gray-800 p-4 shadow-lg rounded text-white">
            <h2 className="text-xl font-bold mb-2">Usage History</h2>
            <ResponsiveContainer width="100%" height={200}>
                <BarChart data={usageData}>
                    <XAxis dataKey="name" tick={{ fill: 'white' }} />
                    <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft', fill: 'white' }} />
                    <Tooltip wrapperStyle={{ backgroundColor: '#333', color: 'white' }} />
                    <Bar dataKey="timeSaved" fill="#60A5FA" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default UsageBox;
