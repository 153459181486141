// src/components/Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="p-4 bg-gray-800 text-white text-center">
            <p>&copy; 2023-{new Date().getFullYear()} EkonomiAPI. {t('All rights reserved')}.</p>
        </footer>
    );
};

export default Footer;
