import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api'; // API request helper

const TaskModal = ({ task, onClose, boardId, taskListId, isNewTask }) => {
    // State for task fields
    const [title, setTitle] = useState(task?.title || '');
    const [description, setDescription] = useState(task?.description || '');
    const [priority, setPriority] = useState(task?.priority || 'Normal');
    const [category, setCategory] = useState(task?.category || '');

    // Load categories from API or predefined
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch categories (could be hardcoded or fetched from API)
        const fetchCategories = async () => {
            const response = await apiRequest('/planner/categories', 'GET');
            setCategories(response || []);
        };
        fetchCategories();
    }, []);

    // Handler to save the task (either create or update)
    const handleSave = async () => {
        const taskData = {
            board_id: boardId,
            task_list_id: taskListId,
            title,
            description,
            priority,
            category,
        };

        if (isNewTask) {
            await apiRequest(`/planner/createTask`, 'POST', taskData); // Create new task
        } else {
            await apiRequest(`/planner/updateTask`, 'POST', { ...taskData, task_id: task.id }); // Update existing task
        }

        onClose();
    };

    return (
        <div className="modal">
            <div className="modal-content p-6 bg-white rounded shadow-md">
                <h2 className="text-2xl mb-4">{isNewTask ? 'Create New Task' : 'Edit Task'}</h2>

                {/* Title Input */}
                <label className="block mb-2 text-sm font-medium text-gray-700">Title</label>
                <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="block w-full p-2 border rounded mb-4"
                />

                {/* Description Input */}
                <label className="block mb-2 text-sm font-medium text-gray-700">Description</label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full p-2 border rounded mb-4"
                    rows={4}
                />

                {/* Priority Dropdown */}
                <label className="block mb-2 text-sm font-medium text-gray-700">Priority</label>
                <select
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                    className="block w-full p-2 border rounded mb-4"
                >
                    <option value="Low" className="text-green-500">Low</option>
                    <option value="Normal" className="text-blue-500">Normal</option>
                    <option value="High" className="text-yellow-500">High</option>
                    <option value="Critical" className="text-red-500">Critical</option>
                </select>

                {/* Category Dropdown */}
                <label className="block mb-2 text-sm font-medium text-gray-700">Category</label>
                <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="block w-full p-2 border rounded mb-4"
                >
                    <option value="">Select a category</option>
                    {categories.map((cat) => (
                        <option key={cat.id} value={cat.name}>{cat.name}</option>
                    ))}
                </select>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        {isNewTask ? 'Create Task' : 'Save Changes'}
                    </button>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TaskModal;
