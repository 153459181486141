import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ToolsHintBox = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        // Check if the hint has been dismissed
        const hasDismissed = localStorage.getItem('hideToolsHint');
        if (!hasDismissed) {
            setIsVisible(true);
        }
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem('hideToolsHint', 'true'); // Store in localStorage
    };

    if (!isVisible) return null; // Don't render if dismissed

    return (
        <div className="bg-gray-800 text-white p-3 rounded-lg relative">
            <button
                className="absolute top-1 right-2 text-red-400 hover:text-red-600"
                onClick={handleDismiss}
            >
                ✖
            </button>
            <p className="mb-2">👋 {t('New here? Click the tools button in the header to open navigation!')}</p>
            <button
                className="text-yellow-300 p-2 rounded bg-gray-800 hover:bg-gray-600 transition"
                onClick={() => {
                    document.querySelector('.fa-tools')?.click();
                }}
            >
                <i className="fa-solid fa-tools"></i> {t('Open Navigation')}
            </button>

            {/* Payment Level Explanation */}
            <div className="mt-3 text-sm text-gray-300">
                <p>{t('Tools have different access levels, indicated by the color bar at the bottom of each tool:')}</p>
                <ul className="mt-1 space-y-1">
                    <li className="flex items-center">
                        <span className="inline-block w-4 h-2 bg-gray-600 mr-2"></span> {t('Free tools')}
                    </li>
                    <li className="flex items-center">
                        <span className="inline-block w-4 h-2 bg-yellow-500 mr-2"></span> {t('Level 1 tools')}
                    </li>
                    <li className="flex items-center">
                        <span className="inline-block w-4 h-2 bg-red-500 mr-2"></span> {t('Level 2 tools')}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ToolsHintBox;
