import React from 'react';
import { NavLink } from 'react-router-dom';

// Constants for Input and Output formats
const INPUT_FORMATS = ['csv', 'xls', 'xlsx', 'pdf', 'txt'];
const OUTPUT_FORMATS = ['csv', 'sie', 'bgmax', 'pdf', 'xls', 'xlsx'];

const ToolFooter = ({ apiDocumentation, isApiDisabled }) => {
    if (isApiDisabled) {
        return null; // Do not render footer if API info is disabled
    }

    return (
        <footer className="py-6 mt-8 bg-gray-800 text-white shadow-lg rounded-lg">
            <div className="container mx-auto px-4">
                <p className="text-sm text-gray-300">
                    Please note all API documentation is for advanced users only. The documentation is available only in English.
                    To disable the API documentation message, visit the{' '}
                    <NavLink to="/control/user" className="text-blue-400 hover:text-blue-300 underline">
                        Control Panel
                    </NavLink>.<br />
                    This tool is also available via our API. To gain access, visit{' '}
                    <NavLink to="/info/api-keys" className="text-blue-400 hover:text-blue-300 underline">
                        API Keys Info
                    </NavLink>{' '}
                    to retrieve your API key and read about authentication.
                </p>

                {/* Display API documentation if available */}
                {apiDocumentation && (
                    <div>
                        <h2 className="text-lg font-bold mt-6 border-b border-gray-600 pb-2">API Documentation</h2>

                        {/* Display API description */}
                        <p className="mt-2 text-gray-300">{apiDocumentation.description}</p>

                        {/* Display API calls */}
                        <h3 className="text-md font-semibold mt-4 text-gray-200">API Calls:</h3>
                        <ul className="text-gray-300">
                            {Object.keys(apiDocumentation.calls).map((call) => (
                                <li key={call} className="mt-1">
                                    <strong className="text-blue-400">{process.env.REACT_APP_API_URL}{call}</strong> - {apiDocumentation.calls[call]}
                                </li>
                            ))}
                        </ul>

                        {/* Standard Inputs */}
                        <h3 className="text-md font-semibold mt-4 text-gray-200">POST Request Fields:</h3>
                        <div className="overflow-x-auto mt-2">
                            <table className="w-full border border-gray-600 bg-gray-700 text-gray-200 rounded-lg">
                                <thead>
                                <tr className="bg-gray-600">
                                    <th className="p-2 border border-gray-500 text-left">Field</th>
                                    <th className="p-2 border border-gray-500 text-left">Type</th>
                                    <th className="p-2 border border-gray-500 text-left">Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/* Hardcoded standard fields */}
                                <tr className="border border-gray-600">
                                    <td className="p-2 border border-gray-500">file</td>
                                    <td className="p-2 border border-gray-500">base64-encoded</td>
                                    <td className="p-2 border border-gray-500">Input file ({INPUT_FORMATS.join(', ')})</td>
                                </tr>
                                <tr className="border border-gray-600">
                                    <td className="p-2 border border-gray-500">format</td>
                                    <td className="p-2 border border-gray-500">string</td>
                                    <td className="p-2 border border-gray-500">Output format ({OUTPUT_FORMATS.join(', ')})</td>
                                </tr>

                                {/* Display other POST fields from API documentation, excluding 'file' and 'format' */}
                                {Object.keys(apiDocumentation.post)
                                    .filter((field) => field !== 'file' && field !== 'format') // Exclude standard fields
                                    .map((field) => {
                                        const [description, comment] = apiDocumentation.post[field].split(' -- '); // Split field details
                                        return (
                                            <tr key={field} className="border border-gray-600">
                                                <td className="p-2 border border-gray-500">{field}</td>
                                                <td className="p-2 border border-gray-500">{description}</td>
                                                <td className="p-2 border border-gray-500">{comment}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </footer>
    );
};

export default ToolFooter;
