import { useState } from "react";
import { apiRequest } from "../../utils/Api"; // Import API request function

const roundToNearest15 = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const roundedMinutes = Math.round(totalMinutes / 15) * 15;
    const roundedHours = Math.floor(roundedMinutes / 60);
    const roundedMin = roundedMinutes % 60;
    return `${String(roundedHours).padStart(2, "0")}:${String(roundedMin).padStart(2, "0")}`;
};

const calculateDuration = (start, end) => {
    const [startH, startM] = start.split(":").map(Number);
    const [endH, endM] = end.split(":").map(Number);
    const startTotal = startH * 60 + startM;
    const endTotal = endH * 60 + endM;
    return Math.max(endTotal - startTotal, 0);
};

const TimeLogging = () => {
    const [customer, setCustomer] = useState("");
    const [startTime, setStartTime] = useState("09:00");
    const [endTime, setEndTime] = useState("10:00");
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const roundedStart = roundToNearest15(startTime);
        const roundedEnd = roundToNearest15(endTime);
        const durationMinutes = calculateDuration(roundedStart, roundedEnd);

        if (durationMinutes <= 0) {
            alert("End time must be after start time.");
            setIsLoading(false);
            return;
        }

        const payload = {
            customer,
            start_time: roundedStart,
            end_time: roundedEnd,
            duration_hours: Math.ceil(durationMinutes / 60), // Round up to full hours
            description,
        };

        try {
            const response = await apiRequest("/employee/reportTime", "POST", payload);

            if (response.error) {
                alert(`Error: ${response.error}`);
            } else {
                alert("Time logged successfully!");
                setCustomer("");
                setStartTime("09:00");
                setEndTime("10:00");
                setDescription("");
            }
        } catch (error) {
            console.error("API Error:", error);
            alert("Error submitting time log.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="max-w-lg mx-auto p-6 bg-gray-800 text-white rounded-lg shadow-md mt-16">
            <h2 className="text-xl font-bold mb-4">Log Work Time</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                {/* Customer Selection */}
                <div>
                    <label className="block text-gray-300">Customer</label>
                    <select
                        value={customer}
                        onChange={(e) => setCustomer(e.target.value)}
                        required
                        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="" disabled>Select a customer</option>
                        <option value="Customer A">Customer A</option>
                        <option value="Customer B">Customer B</option>
                    </select>
                </div>

                {/* Start Time */}
                <div>
                    <label className="block text-gray-300">Start Time</label>
                    <input
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        required
                        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                {/* End Time */}
                <div>
                    <label className="block text-gray-300">End Time</label>
                    <input
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        required
                        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                {/* Description */}
                <div>
                    <label className="block text-gray-300">Task Description</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Describe the task performed..."
                    ></textarea>
                </div>

                {/* Submission */}
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-500 text-white rounded-md font-semibold transition duration-300"
                    disabled={isLoading}
                >
                    {isLoading ? "Submitting..." : "Submit Time Log"}
                </button>
            </form>
        </div>
    );
};

export default TimeLogging;
