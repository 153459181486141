import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../utils/Api';

const BuyShares = () => {
    const { t } = useTranslation();
    const [shares, setShares] = useState('');
    const [message, setMessage] = useState(null);

    const handleBuyShares = async () => {
        if (!shares || shares <= 0) {
            setMessage(t('Please enter a valid number of shares.'));
            return;
        }

        try {
            const response = await apiRequest('/investor/buyShares', 'POST', { shares });
            setMessage(response.success || response.error);
        } catch (err) {
            setMessage(t('Failed to purchase shares.'));
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold">{t('Buy Shares')}</h2>
            {/*<input*/}
            {/*    type="number"*/}
            {/*    value={shares}*/}
            {/*    onChange={(e) => setShares(e.target.value)}*/}
            {/*    placeholder={t('Enter number of shares')}*/}
            {/*    className="p-2 border border-gray-700 rounded w-full text-black"*/}
            {/*/>*/}
            {/*<button className="bg-green-500 p-2 mt-2 rounded text-white w-full" onClick={handleBuyShares}>*/}
            {/*    {t('Purchase')}*/}
            {/*</button>*/}
            {message && <p>{message}</p>}
            <p>{t('This feature is currently disabled.')}</p>
        </div>
    );
};

export default BuyShares;
